import { Flex, Skeleton, Stack } from "@chakra-ui/react";
import React, { memo } from "react";

const LoadPage = () => {
  return (
    <Stack>
      <Flex 
        direction="row" 
        justifyContent="center"
        mb="4"
      >
        <Skeleton 
          fadeDuration={8}
          borderRadius="18" 
          height="45"
          width="25%"
          marginRight="4"
        />
        <Skeleton 
          fadeDuration={8}
          borderRadius="18" 
          height="45"
          width="25%"
          marginRight="4"
        />
        <Skeleton 
          fadeDuration={8}
          borderRadius="18" 
          height="45"
          width="25%"
          marginRight="4"
        />
      </Flex>
      <Skeleton 
        margin="2"
        fadeDuration={8}
        borderRadius="4" 
        height="80"
      />
    </Stack>
  )
}
export default memo(LoadPage);