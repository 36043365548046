import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector, } from "react-redux";
import { MdArrowDropDown } from 'react-icons/md'

import apiAccount from "../../services/urls/account";
import apiCategorySituation from "../../services/urls/category_situation";
import apiUser from "../../services/urls/user";

import {
  Select as SelectChakra,
} from "chakra-react-select";
import SideBar from "components/@sidebar";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Select,
  InputGroup,
  FormLabel,
  FormControl,
  Input,
  useToast,
} from "@chakra-ui/react";
import DrawerRigth from "components/drawer_rigth";
import { UltimatePagination } from "components/pagination";
import Quantities from "./components/quantities";
import Demands from "./components/demands";
import FilterValues from "./components/filterValues";
import HeaderInputFiles from "./components/headerInputFiles";
import LoadPage from "./components/loadPage";

const DemandsPage = () => {
  const toast = useToast();
  const shoulRequest = useRef(true);
  const dispatch = useDispatch();
  const {
    registeredPersonSearch,
    situationsSelected,
    totalDemands,
    demandStatusSituationId,
    statusId,
    responsible,
    registeredPerson,
    demandDescription,
    categories,
    createdByUserAt,
    page,
    filterApplied,
    demandsPaginate,
  } = useSelector((state) => state.demand);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [demands, setDemands] = useState([]);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [categoriesSituation, setCategoriesSituation] = useState([]);
  const [loadingSituation, setLoadingSituation] = useState(false);
  const [situationsType, setSituationType] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [allStatus, setAllStatus] = useState([]);

  const requestStatusQuantities = useCallback(async (isClear) => {
    const payload = {
      demandStatus: statusId,
      demandStatusSituation: demandStatusSituationId,
      responsible: responsible.map((item) => item.value),
      createdByUserAt: createdByUserAt ? moment(createdByUserAt).format("YYYY-MM-DD") : undefined,
      demandDescription,
      categories: categories.map((item) => item.value),
      registeredPerson: registeredPerson?.value,
    }

    const { data } = (await apiUser.getStatusQuantities(isClear ? {} : payload)).data;
    setAllStatus(data);
  }, [
    categories,
    createdByUserAt,
    demandDescription,
    demandStatusSituationId,
    registeredPerson,
    responsible,
    statusId
  ]);

  const requestDemandsPeople = async (page) => {
    try {
      const { data } = await apiUser.GetDemandsByProperties({
        page,
        limit: 5,
        demandStatus: statusId,
        demandStatusSituation: demandStatusSituationId,
        responsible: responsible.map((item) => item.value),
        createdByUserAt: createdByUserAt ? moment(createdByUserAt).format("YYYY-MM-DD") : undefined,
        demandDescription,
        categories: categories.map((item) => item.value),
        registeredPerson: registeredPerson?.value,
      });

      if (data.data.docs.length === 0) {
        dispatch({
          type: "@SET_TOTAL_PAGES",
          payload: {
            totalPages: 0,
          },
        });
        dispatch({
          type: "@SET_DEMANDS_PAGINATE",
          payload: null,
        });
        toast({
          title: '',
          description: "Sem resultados para a pesquisa",
          status: 'info',
          duration: 8000,
          position: "top-right",
          isClosable: true,
        });
      }
      else {
        dispatch({
          type: "@SET_DEMANDS_PAGINATE",
          payload: data.data,
        });
  
        dispatch({
          type: "@SET_FILTERED_APPLIED",
          payload: true,
        });
        dispatch({
          type: "@SET_TOTAL_PAGES",
          payload: {
            totalPages: data.data?.totalPages,
          },
        });
      }
    } catch (_) {
      dispatch({
        type: "@SET_FILTERED_APPLIED",
        payload: false,
      });
    }
  };

  const requestTotalDocsDemandsPeople = useCallback(async (isClear) => {
    try {
      const params = {
        page,
        limit: 5,
        demandStatus: statusId,
        demandStatusSituation: demandStatusSituationId,
        responsible: responsible.map((item) => item.value),
        createdByUserAt: createdByUserAt ? moment(createdByUserAt).format("YYYY-MM-DD") : undefined,
        demandDescription,
        categories: categories.map((item) => item.value),
        registeredPerson: registeredPerson?.value,
      }
      const { data } = await apiUser.GetDemandsByProperties(isClear ? {
        page,
        limit: 5,
      } : params);

      dispatch({
        type: "@SET_TOTAL_DEMAND",
        payload: data.data.totalDocs,
      });
    } catch (_) {}
  }, [
    categories,
    createdByUserAt,
    demandDescription,
    demandStatusSituationId,
    dispatch,
    page,
    registeredPerson,
    responsible,
    statusId
  ]);

  const createFilePDF = useCallback((payload) => {
    try {
      const doc = new jsPDF("l", "pt");
      autoTable(doc, { html: "#my-tables", footStyles: { fontSize: 11 } });
      autoTable(doc, {
        styles: { overflow: "linebreak", minCellWidth: 100, fontSize: 11 },
        columnStyles: payload.some(element => element.hasOwnProperty('sus')) ? {
          0: { cellWidth: 140 },
          1: { cellWidth: 130 },
          2: { cellWidth: 120 },
          3: { cellWidth: 130 },
          4: { cellWidth: 120 },
          5: { cellWidth: 150 },
        } : {
          0: { cellWidth: 140 },
          1: { cellWidth: 130 },
          2: { cellWidth: 100 },
          3: { cellWidth: 120 },
          4: { cellWidth: 150 },
        },
        columns: payload.some(element => element.hasOwnProperty('sus')) ? [
          { header: "Cadastro da demanda", dataKey: "createdByUserAt" },
          { header: "Nome", dataKey: "registeredPersonName" },
          { header: "Contato", dataKey: "phone" },
          { header: "SUS", dataKey: "sus" },
          { header: "Demanda", dataKey: "demandDescription" },
          { header: "Último histórico", dataKey: "theLastDemandHistoric" },
        ] : [
          { header: "Cadastro da demanda", dataKey: "createdByUserAt" },
          { header: "Nome", dataKey: "registeredPersonName" },
          { header: "Contato", dataKey: "phone" },
          { header: "Demanda", dataKey: "demandDescription" },
          { header: "Último histórico", dataKey: "theLastDemandHistoric" },
        ],
        body: payload,
      });
      doc.save(`Relatório de demandas`);
    } catch (_) {
      toast({
        title: '',
        description: "Erro ao gerar PDF.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])

  const createPDF = useCallback(async (type) => {
    toast({
      title: '',
      description: "Seu arquivo está sendo processado, aguarde o download.",
      status: 'success',
      duration: 5000,
      position: "top-right",
      isClosable: true,
    });

    const { data } = (await apiUser.generatePDF({
      demandStatus: statusId,
      demandStatusSituation: demandStatusSituationId,
      responsible: responsible.map((item) => item.value),
      createdByUserAt: createdByUserAt ? moment(createdByUserAt).format("YYYY-MM-DD") : undefined,
      demandDescription,
      categories: categories.map((item) => item.value),
      registeredPerson: registeredPerson?.value,
    })).data;

    let payload;

    if (type === "one") {
      payload = data.map((it) => {
        return {
          createdByUserAt: moment(it.createdByUserAt).format(
            "DD/MM/YYYY [às] HH:mm:ss"
          ),
          registeredPersonName: it.registeredPerson.name || 'Não informado',
          phone: it.registeredPerson.whatsApp || 'Não informado',
          demandDescription: it.demandDescription || 'Não informado',
          theLastDemandHistoric: it?.theLastDemandHistoric?.demandHistoric || 'Não informado',
          responsible: it?.responsible?.name || 'Não informado',
        }
      })
    }
    if (type === "two") {
      payload = data.map((it) => {
        return {
          createdByUserAt: moment(it.createdByUserAt).format(
            "DD/MM/YYYY [às] HH:mm:ss"
          ),
          registeredPersonName: it.registeredPerson.name || 'Não informado',
          phone: it.registeredPerson.phone || 'Não informado',
          sus: it.registeredPerson.susNumero || 'Não informado',
          demandDescription: it.demandDescription || 'Não informado',
          theLastDemandHistoric: it?.theLastDemandHistoric?.demandHistoric || 'Não informado',
          responsible: it?.responsible?.name || 'Não informado',
        }
      })
    }

    if (type === "three") {
      payload = data.map((it) => {
        return {
          createdByUserAt: moment(it.createdByUserAt).format(
            "DD/MM/YYYY [às] HH:mm:ss"
          ),
          registeredPersonName: it.registeredPerson.name || 'Não informado',
          phone: it.registeredPerson.phone || 'Não informado',
          sus: it.registeredPerson.susNumero || 'Não informado',
          demandDescription: it.demandDescription || 'Não informado',
          theLastDemandHistoric: it?.theLastDemandHistoric?.demandHistoric || 'Não informado',
          responsible: it?.responsible?.name || 'Não informado',
        }
      })
    }
    createFilePDF(payload);
  }, [
    categories, 
    createFilePDF, 
    createdByUserAt, 
    demandDescription, 
    demandStatusSituationId, 
    registeredPerson, 
    responsible, 
    statusId, 
    toast,
  ]);

  const requestSituation = useCallback(async () => {
    try {
      const response = await apiUser.getSituations();
      setDemands(
        response.data.data.filter((item) => item.active).map((item) => {
          return {
            key: item._id,
            text: `${item.name}`,
            value: item._id,
            situations: item.situations,
          };
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar as situações",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast]);

  const requestAllUsers = useCallback(async () => {
    try {
      const response = await apiUser.getAllUsers();
      const newUsers = [
        {
          key: "0",
          text: ` `,
          value: "",
        },
      ];
      setUsers(
        newUsers.concat(
          response.data.data
            .filter((item) => item.active)
            .map((item) => {
              return {
                key: item._id,
                text: `${item.name}`,
                value: item._id,
              };
            })
        )
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar users",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast]);

  const requestAccounts = useCallback(async () => {
    try {
      const response = await apiAccount.GetAllAccounts();
      setAccounts(
        response.data.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar accounts",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast]);

  const requestCategoria = useCallback(async () => {
    try {
      const response = await apiCategorySituation.GetCategoriasSelect();
      setCategoriesSituation(
        response.data.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar as categories",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast]);

  const checkButtonDisabled = () => {
    if (
      categories?.length > 0 ||
      responsible?.length > 0 ||
      registeredPerson ||
      demandDescription ||
      statusId ||
      createdByUserAt ||
      demandStatusSituationId?.length > 0
    ) {
      return false;
    }
    return true;
  }

  const requestSituationType = async (statusId) => {
    try {
      setLoadingSituation(true);
      const data = await apiUser.GetSituationTypes({
        statusId,
        demandStatusSituation: demandStatusSituationId,
        responsible: responsible.map((item) => item.value),
        createdByUserAt: createdByUserAt ? moment(createdByUserAt).format("YYYY-MM-DD") : undefined,
        demandDescription,
        categories: categories.map((item) => item.value),
        registeredPerson: registeredPerson?.value,
      });

      setSituationType(data.data.data);
      setLoadingSituation(false);
    } catch (_) {
      setLoadingSituation(false);
    }
  };

  const loadRequests = useCallback(() => {
    setLoading(true)
    Promise.all([
      requestTotalDocsDemandsPeople(),
      requestStatusQuantities(),
      requestAllUsers(),
      requestSituation(),
      requestAccounts(),
      requestCategoria(),
    ]).then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [
    requestTotalDocsDemandsPeople,
    requestAccounts,
    requestAllUsers,
    requestCategoria,
    requestSituation,
    requestStatusQuantities,
  ]);

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      loadRequests();
    }
  }, [demandsPaginate, loadRequests]);

  useEffect(() => {
    if (registeredPersonSearch && registeredPerson) {
      applyFilter();
    }
  }, [registeredPersonSearch, registeredPerson]);

  const applyFilter = async () => {
    setLoading(true);
    await Promise.all([
      requestDemandsPeople(1),
      requestTotalDocsDemandsPeople(),
      requestStatusQuantities(),
    ]);
    dispatch({
      type: "@SET_FILTERED_APPLIED",
      payload: true,
    });
    dispatch({
      type: "@SET_PAGE",
      payload: 1,
    });
    setLoading(false);
  };

  const clear = useCallback(async () => {
    dispatch({
      type: "@SET_CLEAN_FILTER",
    });
    setLoading(true);
    await Promise.all([
      requestTotalDocsDemandsPeople(true),
      requestStatusQuantities(true),
    ]);
    setLoading(false);
  }, [
    dispatch,
    requestStatusQuantities,
    requestTotalDocsDemandsPeople]);

  const totalSum = () => {
    const resul = allStatus.map(obj => Object.values(obj)[0]).reduce((acc, current) => acc + current, 0);
    return resul;
  };

  const renderAccountsName = () => {
    const names = [];
    users.map((item) => {
      if (item.value === registeredPerson.value) {
        names.push(item);
      }
      return item;
    });

    if (names.length === 1 && names[0].key === "0") {
      return "";
    }
    if (names.length === 0) {
      return "";
    }
    return `${names
      .map((it) => {
        return `${it.text}`;
      })
      .join(", ")}.`;
  };

  const renderCatgorieName = () => {
    const names = [];
    categoriesSituation.map((item) => {
      categories.map((it) => {
        if (item.value === it.value) {
          names.push(item);
        }
        return it;
      });
      return item;
    });
    if (names.length === 0) {
      return "";
    }
    return `${names
      .map((it) => {
        return `${it.label}`;
      })
      .join(", ")}.`;
  };

  const renderResponsibleName = () => {
    const names = [];
    accounts.map((item) => {
      responsible.map((it) => {
        if (item.value === it.value) {
          names.push(item);
        }
        return it;
      });
      return item;
    });

    if (names.length === 0) {
      return "";
    }

    return `${names
      .map((it) => {
        return `${it.label}`;
      })
      .join(",")}.`;
  };

  const renderSituationName = () => {
    const names = [];
    demands.map((item) => {
      if (item.value === statusId) {
        names.push(item);
      }
      return item;
    });
    if (names.length === 0) {
      return "";
    }
    return `${names
      .map((it) => {
        return `${it.text}`;
      })
      .join(",")}`;
  };

  const renderSituationProgressName = () => {
    const names = [];
    situationsSelected?.map((item) => {
      if (item.value === demandStatusSituationId) {
        names.push(item)
      }
    });

    if (names.length === 0) {
      return "";
    }
    return `${names
      .map((it) => {
        return ` ${it.text}`;
      })
      .join(",")}`;
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex flexDirection="column">
        <SideBar />
        <Flex
          alignItems="center"
          justifyContent="center"
          marginTop="14"
        >
          <Text
            color={textColor}
            fontWeight="bold">
            LISTAGEM DE DEMANDAS
          </Text>
        </Flex>

        <HeaderInputFiles
          clear={() => clear()}
          loading={loading}
          search={registeredPersonSearch}
          setOpenDrawer={setOpenDrawer}
          systemUsers={users}
          generateFile={(value) => {
            if (value) {
              createPDF(value);
              return
            }
            if (value) {
              createPDF(value);
              return
            }
          }}
        />
        <Flex padding={{ sm: 3, md: 10 }} flexDirection="column">
          {loading ? (
            <LoadPage />
          ) : (
            <>
              <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
                {!loading && (
                  <Quantities
                    situations={allStatus}
                    isFilter={filterApplied}
                    isLoading={loadingSituation}
                    situationsType={situationsType}
                    totalDemand={totalDemands}
                    totalFilter={totalSum()}
                    requestSituationType={(value) => requestSituationType(value)}
                  />
                )}
              </Flex>
              {(filterApplied && !loading) && (
                <FilterValues
                  isFilter={filterApplied}
                  loading={loading}
                  renderName={renderAccountsName()}
                  renderCategories={renderCatgorieName()}
                  renderResponsible={renderResponsibleName()}
                  renderSituations={renderSituationProgressName()}
                  renderStatus={renderSituationName()}
                  startDate={createdByUserAt}
                  demandDescription={demandDescription}
                />
              )}
              <Demands demands={demandsPaginate?.docs || []} />
            </>
          )}
          {(!loading) && (
            <Flex justifyContent="center" marginTop="8" marginBottom="0">
              {(demandsPaginate?.totalPages > 0 && demandsPaginate?.docs.length > 0) && (
                <UltimatePagination
                  currentPage={page}
                  onChange={(p) => {
                    dispatch({
                      type: "@SET_PAGE",
                      payload: p,
                    });
                    requestDemandsPeople(p);
                  }}
                  totalPages={demandsPaginate?.totalPages || 1}
                />
              )}
            </Flex>
          )}
        </Flex>
      </Flex>

      <DrawerRigth
        disabled={checkButtonDisabled()}
        onListener={() => {
          setOpenDrawer(false);
        }}
        onPressSuccess={() => {
          setTimeout(() => {
            applyFilter();
            setOpenDrawer(false);
          }, 400);
        }}
        closeDrawer={() => setOpenDrawer(false)}
        onPressCancel={() => {
          clear();
        }}
        listener={openDrawer}
        children={
          <>
            <FormControl marginTop={4}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Data
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  required
                  value={createdByUserAt}
                  onChange={(event) => {
                    dispatch({
                      type: "@SET_CREATED_BY_USER_AT",
                      payload: event.target.value
                    });
                  }}
                  fontSize='sm'
                  maxLength={200}
                  mb='4px'
                  size='md'
                  type={"date"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Nome do cadastrado
              </FormLabel>
              <SelectChakra
                isMulti={false}
                placeholder=""
                onChange={(event) => {
                  dispatch({
                    type: "@SET_REGISTERED_PERSON",
                    payload: event
                  });
                }}
                colorScheme="green"
                value={registeredPerson}
                options={users.map((item) => {
                  return {
                    value: item.value,
                    label: item.text
                  }
                })}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                marginTop={2}
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Descrição da demanda
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={demandDescription}
                  onChange={(event) => {
                    dispatch({
                      type: "@SET_DEMAND_DESCRIPTION",
                      payload: event.target.value
                    });
                  }}
                  fontSize='sm'
                  maxLength={200}
                  mb='4px'
                  size='md'
                  type={"text"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Status da demanda
              </FormLabel>
              <Select
                size='md'
                bg="white"
                borderRadius={15}
                variant='outline'
                value={statusId}
                onChange={(event) => {
                  if (!event.target.value) return;
                  dispatch({
                    type: "@SET_STATUS_ID",
                    payload: event.target.value,
                  });
                  dispatch({
                    type: "@SET_DEMAND_STATUS_SITUATION",
                    payload: '',
                  });

                  let situationToConcat = [];
                  if (demands.find((item) => item.value === event.target.value)?.text === "Cancelada") {
                    situationToConcat.push({
                      _id: 0,
                      name: "Sem situações de demanda",
                    });
                  }
                  else {
                    situationToConcat = [];
                  }

                  dispatch({
                    type: "@SET_SITUATIONS_SELECTED",
                    payload: demands
                      ?.filter((it) => it.value === event.target.value)[0]
                      ?.situations
                      .concat(situationToConcat)
                      .filter((it) => it.name !== "Resolvida")
                      .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                      .map((item) => {
                        return {
                          key: item._id,
                          text: item.name,
                          value: item._id,
                        };
                      }),
                  });
                }}
                icon={<MdArrowDropDown />}>
                <option value={""}>Selecione</option>
                {demands.map((item) => (
                  <option value={item.value}>{item.text}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                marginTop={2}
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Situação da demanda
              </FormLabel>
              <Select
                size='md'
                bg="white"
                borderRadius={15}
                variant='outline'
                value={demandStatusSituationId}
                onChange={(event) => {
                  if (!event.target.value) {
                    dispatch({
                      type: "@SET_DEMAND_STATUS_SITUATION",
                      payload: "",
                    });
                    return;
                  }
                  dispatch({
                    type: "@SET_DEMAND_STATUS_SITUATION",
                    payload: event.target.value,
                  });
                }}
                icon={<MdArrowDropDown />}>
                <option value={""}>Selecione</option>
                {situationsSelected.map((item) => (
                  <option value={item.value}>{item.text}</option>
                ))}
              </Select>
            </FormControl>
            <Flex>
              <FormControl>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Categoria
                </FormLabel>
                <SelectChakra
                  isMulti
                  placeholder=""
                  onChange={(event) => {
                    dispatch({
                      type: "@SET_CATEGORIES",
                      payload: event,
                    });
                  }}
                  colorScheme="green"
                  value={categories}
                  options={categoriesSituation}
                />
              </FormControl>
            </Flex>
            <Flex>
              <FormControl>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Responsável
                </FormLabel>
                <SelectChakra
                  isMulti
                  placeholder=""
                  onChange={(event) => {
                    dispatch({
                      type: "@SET_RESPONSIBLE",
                      payload: event,
                    });
                  }}
                  colorScheme="green"
                  value={responsible}
                  options={accounts}
                />
              </FormControl>
            </Flex>
            <Flex height="40" />
          </>
        }
      />
    </Box>
  );
};
export default DemandsPage;
