import React from "react";
import CardContainer from "components/card/Card";
import { situationColors } from "utils/situationColors";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import moment from "moment";
import 'moment/locale/pt-br';

import { TbFiles } from 'react-icons/tb'
import TextExpanded from "components/@textExpanded";
moment.locale('pt-br');

const CardDemandView = ({ userDemand, openEdit }) => {
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <CardContainer
      bg={situationColors(
        userDemand?.demandStatus?.name
      )}
      children={
        <div style={{ margin: 8 }}>
          <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
            <Flex whiteSpace="pre-line">
              <span
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  marginRight: 4,
                }}>
                Cadastro em:
              </span>
              {moment(userDemand?.createdByUserAt).format("DD/MM/YYYY")}
            </Flex>

            <Flex>
              <Text
                fontSize='md'
                fontWeight='600'
                color={textColor}
              >Responsavél:</Text>
              <Text
                fontSize='md'
                color={textColor}
                marginLeft={2}>
                {userDemand?.responsible?.name ||
                  "Sem responsável"}
              </Text>
            </Flex>
          </Flex>
          {userDemand?.theLastDemandHistoric?.attachment.length > 0 && (
            <Flex marginBottom="4">
              <Text
                fontSize='md'
                fontWeight='600'
                marginRight={1}
                color={textColor}
              >Anexo:
              </Text>
              <TbFiles size="20" color="blue" />
            </Flex>
          )}
          <Flex marginBottom="2" maxW="5xl">
            <TextExpanded
              textLabel="Demanda:"
              text={userDemand?.demandDescription || ""}
            />
          </Flex>
          <Flex marginBottom={2} direction="column">
          {userDemand?.createdByUser && (
            <Flex>
              <Text
                fontSize='md'
                fontWeight='600'
                color={textColor}
              >
                Cadastrado por:</Text>
              <Text
                fontSize='md'
                color={textColor}
                marginLeft={2}>
                {userDemand?.createdByUser?.name} {moment(userDemand?.createdByUserAt).format('DD/MM/YYYY [às] HH:mm:ss')}
              </Text>
            </Flex>
          )}
          {userDemand?.editedByUser && (
            <Flex>
              <Text
                fontSize='md'
                fontWeight='600'
                color={textColor}
              >
                Editado por:</Text>
              <Text
                fontSize='md'
                color={textColor}
                marginLeft={2}>
                {userDemand?.editedByUser?.name} {moment(userDemand?.editedByUserAt).format('DD/MM/YYYY [às] HH:mm:ss')}
              </Text>
            </Flex>
          )}
          </Flex>
          <Flex>
            <Text
              fontSize='md'
              fontWeight='600'
              color={textColor}
            >Status:</Text>
            <Text
              fontSize='md'
              color={textColor}
              marginLeft={2}>
              {userDemand?.demandStatus.name}
            </Text>
          </Flex>
          <Flex>
            <Text
              fontSize='md'
              fontWeight='600'
              color={textColor}
            >Situação:</Text>
            <Text
              fontSize='md'
              color={textColor}
              marginLeft={2}>
              {userDemand?.demandStatusSituation.name === "Resolvida" ? "--" : (
                userDemand?.demandStatusSituation.name
                  ? userDemand?.demandStatusSituation.name
                  : "--"
              )}
            </Text>
          </Flex>
          <Flex marginBottom="4">
            <Text
              fontSize='md'
              fontWeight='600'
              color={textColor}
            >
              Categoria:</Text>
            <Text
              fontSize='md'
              color={textColor}
              marginLeft={2}>
              {userDemand?.categories.length === 0 ? "--" : userDemand?.categories
                .map((item) => item.name)
                .join(",")}
            </Text>
          </Flex>
          {userDemand?.theLastDemandHistoric?.sendDoc && (
            <Flex>
              <Text
                fontSize='md'
                fontWeight='600'
                color={textColor}
              >
                Enviou documento ao solicitante?:</Text>
              <Text
                fontSize='md'
                color={textColor}
                marginLeft={2}>
                {userDemand?.theLastDemandHistoric?.sendDoc}
              </Text>
            </Flex>
          )}
          {userDemand?.theLastDemandHistoric?.sendMidia && (
            <Flex>
              <Text
                fontSize='md'
                fontWeight='600'
                color={textColor}
              >
                Enviou mídia/arte ao solicitante?:</Text>
              <Text
                fontSize='md'
                color={textColor}
                marginLeft={2}>
                {userDemand?.theLastDemandHistoric?.sendMidia}
              </Text>
            </Flex>
          )}
          {userDemand?.theLastDemandHistoric?.hasReturn && (
            <Flex>
              <Text
                fontSize='md'
                fontWeight='600'
                color={textColor}
              >
                Deu retorno ao solicitante?:</Text>
              <Text
                fontSize='md'
                color={textColor}
                marginLeft={2}>
                {userDemand?.theLastDemandHistoric?.hasReturn}
              </Text>
            </Flex>
          )}
        </div>
      }
    />
  )
}
export default CardDemandView;