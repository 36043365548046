const INITIAL_STATE = {
  categorie: [],
  search: "",
  indication: "",
  page: 1,
  totalPages: 0,
  info2: "",
};

const usersMapReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "@SET_INIDICATION2":
      return {
        ...state,
        indication: action.payload.indication,
      };
    case "@SET_INFO2":
      return {
        ...state,
        info2: action.payload.info2,
      };
    case "@SET_SEARCH2":
      return {
        ...state,
        search: action.payload.search,
      };
    case "@SET_CATEGORIE2":
      return {
        ...state,
        categorie: action.payload.categorie,
      };
    case "@SET_PAGE2":
      return {
        ...state,
        page: action.payload.page,
      };
    case "@SET_TOTAL_PAGES2":
      return {
        ...state,
        totalPages: action.payload.totalPages,
      };
    case "@SET_CLEAN_FILTER2":
      return {
        ...state,
        page: 1,
        search: "",
        categorie: [],
        indication: "",
        totalPages: 0,
        info2: "",
      };
    default:
      return state;
  }
};
export default usersMapReducer;
