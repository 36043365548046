/* eslint-disable prefer-const */
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Cardcomponent from "components/@card";
import apiSituationCategory from "../../services/urls/category_situation";
import { AiOutlineEdit, AiFillDelete, AiFillCheckCircle } from "react-icons/ai";
import SideBar from "components/@sidebar";
import {
  Box,
  Icon,
  Flex,
  IconButton,
  Skeleton,
  Stack,
  Button,
  useToast,
  useDisclosure,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialog,
  Input,
} from "@chakra-ui/react";
import { UltimatePagination } from "components/pagination";

const CategoryPageSituations = () => {
  const toast = useToast();
  let shoulRequest = useRef(true);
  const { _id: accountId, name: accountName } = useSelector(
    (state) =>
      state.user
  );
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [pagePagination, setPagePagination] = useState(1);

  const [categories, setCategories] = useState([]);

  const requestCategories = async (page) => {
    try {
      setLoading(true);
      const response = await apiSituationCategory.GetCategories(page, perPage);

      const value = page + 1;
      setPagePagination(value)

      setCategories(
        response?.data?.data?.docs?.map(
          (item) => {
            return {
              key: item._id,
              text: item.name,
              value: item._id,
              active: item.active,
              _id: item._id,
            };
          }
        )
      );
      setTotalPages(response?.data?.data?.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Erro ao listar as categorias, tente novamente.");
    }
  };

  const requestNewCategory = async () => {
    try {
      if (!categoryName) {
        toast.warn("Informe o nome da categoria.");
        return;
      }
      if (categoryName.length < 3) {
        toast.warn("O nome deve conter no minímo 3 caracteres.");
        return;
      }
      setLoadingSave(true);
      await apiSituationCategory.saveSituationCategorie(
        categoryName,
        accountId,
        accountName,
        moment().format("DD/MM/YYYY"),
        moment().format("HH:mm:ss"),
        "Adicionou",
        undefined,
        undefined,
        `Cadastrou uma nova categoria de situações de demandas, categoria ${categoryName}`,
        moment().format()
      );
      requestCategories(1);
      setLoadingSave(false);
      setCategoryName("");
      toast({
        title: '',
        description: "Categoria adicionada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      setLoadingSave(false);
      toast({
        title: '',
        description: "Erro ao cadastrar categoria, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestInative = async (id, name) => {
    try {
      await apiSituationCategory.inativeSituationCategorie(
        id,
        accountId,
        accountName,
        moment().format("DD/MM/YYYY"),
        moment().format("HH:mm:ss"),
        "Desativou",
        undefined,
        undefined,
        `Desativou a categoria ${name} de situações demandas`,
        moment().format()
      );
      toast({
        title: '',
        description: "Categoria desativada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });

      setCategories(
        categories.map((item) => {
          if (item._id === id) {
            item.active = false;
            return item;
          }
          return item;
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao desativar categoria, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestAtive = async (id, name) => {
    try {
      await apiSituationCategory.activeSituationCategorie(
        id,
        accountId,
        accountName,
        moment().format("DD/MM/YYYY"),
        moment().format("HH:mm:ss"),
        "Ativou",
        undefined,
        undefined,
        `Ativou a categoria ${categoryName} de situações demandas`,
        moment().format()
      );
      setCategories(
        categories.map((item) => {
          if (item._id === id) {
            item.active = true;
            return item;
          }
          return item;
        })
      );
      toast({
        title: '',
        description: "Categoria reativada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao ativar categoria, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestEdit = async () => {
    try {
      setLoadingSave(true);
      await apiSituationCategory.updateSituationCategorie(
        categoryId,
        categoryName,
        accountId,
        accountName,
        moment().format("DD/MM/YYYY"),
        moment().format("HH:mm:ss"),
        "Editou",
        undefined,
        undefined,
        "Editou uma categoria de situações demandas",
        moment().format()
      );
      toast({
        title: '',
        description: "Categoria atualizada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setCategories(
        categories.map((item) => {
          if (item._id === categoryId) {
            item.text = categoryName;
            return item;
          }
          return item;
        })
      );
      setCategoryId("");
      setCategoryName("");
      setLoadingSave(false);
      requestCategories(1);
      onClose();
    } catch (error) {
      setLoadingSave(false);
      toast({
        title: '',
        description: "Erro ao atualizar categoria, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const prepareData = (data) => {
    try {
      setCategoryId(data._id);
      setCategoryName(data.text);
      onOpen();
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao preparar dados para edição",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      requestCategories(1);
    }
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex padding={10} flexDirection="column">
        {loading ? (
          <Stack>
            <Skeleton borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
          </Stack>
        ) : (
          <>
            {categories.map((item) => (
              <Box marginTop={2}>
                <Cardcomponent
                  isUser={null}
                  endContent={
                    <Flex>
                      <IconButton
                        w='36px'
                        h='36px'
                        onClick={() => prepareData(item)}
                        color="orange.500"
                        bg={"gray.200"}
                        icon={
                          <Icon
                            as={AiOutlineEdit}
                          />
                        }
                        marginRight={1}
                      />
                      <IconButton
                        w='36px'
                        h='36px'
                        color={item.active === true ? "red.300" : "green.400"}
                        bg={"gray.200"}
                        onClick={() => {
                          if (item.active) {
                            requestInative(item._id, item.text);
                          } else {
                            requestAtive(item._id, item.text);
                          }
                        }}
                        icon={
                          item.active === true ? <Icon as={AiFillDelete} /> : <Icon as={AiFillCheckCircle} />
                        }
                      />
                    </Flex>
                  }
                  name={`${item.text}`}
                />
              </Box>
            ))}
          </>
        )}

        {!loading && (
          <Flex justifyContent="center" marginTop="6">
            {totalPages > 0 && (
              <UltimatePagination
                currentPage={page}
                onChange={(p) => {
                  requestCategories(p);
                  setPage(p);
                }}
                totalPages={totalPages}
              />
            )}
          </Flex>
        )}
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Editar categoria de demanda
            </AlertDialogHeader>

            <AlertDialogBody>
              <Input
                maxLength={80}
                type="text"
                value={categoryName}
                onChange={(event) => setCategoryName(event.target.value)}
                placeholder="Nome da categoria"
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button disabled={loadingSave} ref={cancelRef} onClick={() => {
                setCategoryName("")
                onClose();
              }}>
                Fechar
              </Button>
              <Button
                isLoading={loadingSave}
                variant='brand'
                onClick={requestEdit} ml={3}>
                Salvar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
export default CategoryPageSituations;
