import axios from "axios";

import enviroment from "../../enviroment/index";
import store from "../../store";

const createAxiosInstances = () => {
  const config = {
    baseURL: enviroment.BASE_URL,
    params: {
      cc: enviroment.BASIC_SECURITY,
    },
  };
  return axios.create(config);
};

const axiosDefault = createAxiosInstances();

const requesSuccessHandler = (value) => {
  const toJson = localStorage.getItem("@token");
  const data = toJson == null ? null : JSON.parse(toJson);
  if (data?.token) {
    value.headers = {
      proprietary: store.getState().user.proprietaryid,
      Authorization: `Bearer ${data.token}`,
    };
  }
  return value;
};

const requestErrorHandler = async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      try {
        const token = await new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        });
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return await axios(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      const tokenJson = localStorage.getItem("@token");

      const tokenParse = tokenJson == null ? null : JSON.parse(tokenJson);
      axiosDefault
        .get(`refreshtoken/${tokenParse.token}`)
        .then(({ data }) => {
          const newToken = {
            token: data?.token,
          };

          localStorage.setItem("@token", JSON.stringify(newToken));

          axios.defaults.headers.common.Authorization = `Bearer ${newToken?.token}`;
          originalRequest.headers.Authorization = `Bearer ${newToken?.token}`;
          processQueue(null, newToken?.token);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }
  return Promise.reject(error);
};

axiosDefault.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          const token = await new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          });
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return await axios(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        const tokenJson = localStorage.getItem("@token");

        const tokenParse = tokenJson == null ? null : JSON.parse(tokenJson);
        axiosDefault
          .get(`refreshtoken/${tokenParse.token}`)
          .then(({ data }) => {
            const newToken = {
              token: data?.token,
            };

            localStorage.setItem("@token", JSON.stringify(newToken));

            axios.defaults.headers.common.Authorization = `Bearer ${newToken?.token}`;
            originalRequest.headers.Authorization = `Bearer ${newToken?.token}`;
            processQueue(null, newToken?.token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  })

axiosDefault.interceptors.request.use(
  requesSuccessHandler,
  requestErrorHandler
);
let isRefreshing = false;

let failedQueue = [];

const processQueue = (error, token = null) => {
  // @ts-ignore
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export default axiosDefault;
