/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from "mapbox-gl";
import React, {
  useRef,
  useEffect,
  useState,
  memo,
  useCallback,
} from "react";
import {
  Select as SelectChakra,
} from "chakra-react-select";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SideBar from "components/@sidebar";
import axiosCategorie from "../../services/urls/category";
import api from "../../services/urls/user";
import DrawerRigth from "components/drawer_rigth";

import {
  Box,
  Flex,
  IconButton,
  Input,
  Text,
  Stack,
  Skeleton,
  FormControl,
  FormLabel,
  InputGroup,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiFillFilter } from "react-icons/ai";
import { useContext } from 'react';
import { AuthContext } from '../../context';

// eslint-disable-next-line import/no-webpack-loader-syntax
// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken =
  "pk.eyJ1IjoiZG9taW5lZGlnaXRhbCIsImEiOiJjbDY3YmNnNXgzaWt0M2JwZDFvcm5kbG05In0.Jmdha4LorptD9pNmPdxedQ";

const Map = () => {
  const { menuOpen } = useContext(AuthContext);
  let shoulRequest = useRef(true);
  const dispatch = useDispatch();
  const { info2, search, categorie, indication } = useSelector(
    (state) => state.usersMapReducer
  );
  const [count, setCount] = useState({
    totalWithFilter: 0,
    notHasFilter: 0,
  });
  const history = useNavigate();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [loading, setLoading] = useState(false);
  const mapContainerRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const requestUsersFilter = async () => {
    const response = await api.GetUsersMapFilter(
      categorie.map((item) =>  { 
        return item.value;
       }),
      search || info2,
      indication
    );
    return response;
  };

  const requestCategories = async () => {
    try {
      const response = await axiosCategorie.GetCategoriesActive();
      setCategories(
        response?.data?.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      );
    } catch (error) {
      // toast.error("Erro ao buscar categorias.");
    }
  };

  const prepareMap = async () => {
    setLoading(true);
    const { data } = await requestUsersFilter();
    const newData = data.data;

    const map = new mapboxgl.Map({
      // @ts-ignore
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v9",
      center: [-55.691652, -22.524905],
      zoom: 11.5,
    });

    setCount({
      notHasFilter: newData.notHasFilter,
      totalWithFilter: newData.totalWithFilter,
    });

    const geoData = {
      type: "FeatureCollection",
      features: newData.data.map(
        (item) => {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [Number(item.long), Number(item.lat)],
            },
            properties: {
              title: item.name,
              description: "description",
              _id: item._id,
            },
          };
        }
      ),
    };

    map.on("load", (el) => {
      map.addSource("testMapData", {
        type: "geojson",
        // @ts-ignore
        data: geoData,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "testMapData",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#5e81ff",
          "circle-radius": 30,
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "testMapData",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["Arial Unicode MS Bold"],
          "text-size": 12,
          "text-allow-overlap": true,
        },
      });
      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "testMapData",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#2929cd",
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      map.on("click", "unclustered-point", (e) => {
        history(
          "/visualizar-cadastrado",
          {
            state: {
              userId: e?.features[0]?.properties?._id,
            }
          }
        );
        // @ts-ignore
        // visit(e?.features[0]?.properties?._id);
      });

      map.on("mouseover", "unclustered-point", (e) => {
        const popup = new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(
            `<strong style={{ fontSize: 18 }}>${
            // @ts-ignore
            e?.features[0]?.properties?.title || ""
            }</strong>`
          )
          .addTo(map);
        map.on("mouseout", "unclustered-point", (e) => {
          popup.remove();
        });
      });
    });
    setLoading(false);
  }

  const prepareMap2 = async () => {
    setLoading(true);
    const { data } = await requestUsersFilter();
    const newData = data.data;
    const map = new mapboxgl.Map({
      // @ts-ignore
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v9",
      center: [-55.691652, -22.524905],
      zoom: 11.5,
    });

    setCount({
      notHasFilter: newData.notHasFilter,
      totalWithFilter: newData.totalWithFilter,
    });

    const geoData = {
      type: "FeatureCollection",
      features: newData.data.map(
        (item) => {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [Number(item.long), Number(item.lat)],
            },
            properties: {
              title: item.name,
              description: "description",
              _id: item._id,
            },
          };
        }
      ),
    };

    map.on("load", () => {
      map.addSource("testMapData", {
        type: "geojson",
        // @ts-ignore
        data: geoData,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "testMapData",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#5e81ff",
          "circle-radius": 30,
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "testMapData",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["Arial Unicode MS Bold"],
          "text-size": 12,
          "text-allow-overlap": true,
        },
      });

      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "testMapData",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#2929cd",
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });
      map.on("click", "unclustered-point", (e) => {
        history(
          "/visualizar-cadastrado",
          {
            state: {
              // @ts-ignore
              userId: e?.features[0]?.properties?._id,
            }
          },
        );
      });
      map.on("mouseover", "unclustered-point", (e) => {
        const popup = new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(
            `<strong style={{ fontSize: 18 }}>${
            // @ts-ignore
            e?.features[0]?.properties?.title || ""
            }</strong>`
          )
          .addTo(map);
        map.on("mouseout", "unclustered-point", (e) => {
          popup.remove();
        });
      });
    });
    setLoading(false);
  };

  const prepareMapSearch = async () => {
    setLoading(true);
    const { data } = await requestUsersFilter();
    const newData = data.data;
    const map = new mapboxgl.Map({
      // @ts-ignore
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v9",
      center: [-55.691652, -22.524905],
      zoom: 11.5,
    });

    setCount({
      notHasFilter: newData.notHasFilter,
      totalWithFilter: newData.totalWithFilter,
    });

    const geoData = {
      type: "FeatureCollection",
      features: newData.data.map(
        (item) => {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [Number(item.long), Number(item.lat)],
            },
            properties: {
              title: item.name,
              description: "description",
              _id: item._id,
            },
          };
        }
      ),
    };

    map.on("load", (ele) => {
      map.addSource("testMapData", {
        type: "geojson",
        // @ts-ignore
        data: geoData,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "testMapData",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#5e81ff",
          "circle-radius": 30,
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "testMapData",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["Arial Unicode MS Bold"],
          "text-size": 12,
          "text-allow-overlap": true,
        },
      });

      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "testMapData",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#2929cd",
          "circle-radius": 8,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      map.on("click", "unclustered-point", (e) => {
        history(
          "/visualizar-cadastrado",
          {
            state: {
              userId: e?.features[0]?.properties?._id,
            }
          },
        );
      });
      map.on("mouseover", "unclustered-point", (e) => {
        const popup = new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(
            `<strong style={{ fontSize: 18 }}>${e?.features[0]?.properties?.title || ""
            }</strong>`
          )
          .addTo(map);
        map.on("mouseout", "unclustered-point", (e) => {
          popup.remove();
        });
      });
    });
    setLoading(false);
  };

  const clearStates = useCallback(() => {
    dispatch({
      type: "@SET_SEARCH2",
      payload: {
        search: "",
      },
    });
    dispatch({
      type: "@SET_INIDICATION2",
      payload: {
        indication: "",
      },
    });
    dispatch({
      type: "@SET_CATEGORIE2",
      payload: {
        categorie: [],
      },
    });
    setCount({
      notHasFilter: 0,
      totalWithFilter: 0,
    });
    setTimeout(() => {
      prepareMap();
    }, 400)
  }, []);

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      clearStates();
      prepareMap();
      requestCategories();
    }
  }, []);

  useEffect(() => {
    if (info2?.length > 2) {
      prepareMapSearch();
      setIsFilter(true);
    } else if (info2.length && isFilter) {
      prepareMap();
      setIsFilter(false);
    }
  }, [info2]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      {loading && (
        <div
          style={{
            position: "absolute",
            left: "56%",
            right: "44%",
            top: "50%",
          }}
        >
          <Stack>
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
          </Stack>
        </div>
      )}
        <>
          {isFilter && !loading && (
            <Flex width="100%" justifyContent="center">
              <Flex
                borderRadius={6}
                boxShadow="2xl"
                bg="white"
                padding={2}
                bottom="96"
                position="absolute"
                zIndex={2}
              >
                <Flex direction="column">
                  <Text>Total do filtro: {count.notHasFilter}</Text>
                  <Text>Total do filtro com geolocalização: {count.totalWithFilter}</Text>
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex
            position="absolute"
            bottom="80"
            zIndex="1"
            bg="transparent"
            width="100%"
            justifyContent="center"
          >
            <Flex width="70%">
              <Input
                boxShadow="2xl"
                value={info2}
                variant='auth'
                bg="white"
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                fontWeight='500'
                size='lg'
                onChange={(event) => {
                  dispatch({
                    type: "@SET_INFO2",
                    payload: {
                      info2: event.target.value,
                    },
                  });
                }}
                placeholder="Pesquisar"
              />
              <IconButton
                marginTop={1}
                marginLeft={2}
                color="instagram"
                onClick={() => setOpenDrawer(true)}
                icon={<AiFillFilter color="#3311DB" />}
              />
            </Flex>
          </Flex>
        </>
      <Flex
        height="container.lg"
        style={{ marginTop: -150 }}
        ref={mapContainerRef}
      />
      <DrawerRigth
        onListener={() => {
          setOpenDrawer(false);
        }}
        onPressSuccess={() => {
          setTimeout(() => {
            setOpenDrawer(false);
            setIsFilter(true);
            prepareMap2();
          }, 400);
        }}
        closeDrawer={() => setOpenDrawer(false)}
        onPressCancel={() => {
          setIsFilter(false);
          clearStates();
        }}
        listener={openDrawer}
        children={
          <>
            <FormControl marginTop={4}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Pesquisar
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={search}
                  onChange={(event) => {
                    dispatch({
                      type: "@SET_SEARCH2",
                      payload: {
                        search: event.target.value,
                      },
                    });
                  }}
                  fontSize='sm'
                  maxLength={200}
                  mb='4px'
                  size='md'
                  type={"text"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl marginTop={4}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Indicação
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  value={indication}
                  onChange={(event) => {
                    dispatch({
                      type: "@SET_INIDICATION2",
                      payload: {
                        indication: event.target.value,
                      },
                    });
                  }}
                  fontSize='sm'
                  maxLength={200}
                  mb='4px'
                  size='md'
                  type={"text"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Categoria
              </FormLabel>
              <SelectChakra
                isMulti
                placeholder=""
                onChange={(event) => {
                  dispatch({
                    type: "@SET_CATEGORIE2",
                    payload: {
                      categorie: event,
                    },
                  });
                }}
                colorScheme="green"
                value={categorie}
                options={categories}
              />
            </FormControl>
          </>
        }
      />
    </Box>
  );
};

export default memo(Map);
