
import axiosDefault from "../../api";

const GetCategoriasSelect = async () => {
  const data = axiosDefault.get(`/situations/categories/simples`);
  return data;
};

const GetCategoriesSimple = async (
  page,
  perPage
) => {
  const data = axiosDefault.get(`/situations/simple/categories`, {
    params: {
      perPage,
      page,
    },
  });
  return data;
};

const GetCategories = async (
  page,
  perPage
) => {
  const data = axiosDefault.get(`/situations/categories`, {
    params: {
      perPage,
      page,
    },
  });
  return data;
};
const GetSituationCategoriesActive = async () => {
  const data = axiosDefault.get(`/situations/categories-active`);
  return data;
};

const inativeSituationCategorie = async (
  id,
  accountId,
  accountName,
  day,
  hour,
  userAction,
  userId,
  userName,
  description,
  dateTime
) => {
  const data = axiosDefault.put(`/situation/category/${id}`, {
    active: false,
    accountId,
    accountName,
    day,
    hour,
    userAction,
    userId,
    userName,
    description,
    dateTime,
  });
  return data;
};
const activeSituationCategorie = async (
  id,
  accountId,
  accountName,
  day,
  hour,
  userAction,
  userId,
  userName,
  description,
  dateTime
) => {
  const data = axiosDefault.put(`/situation/category/${id}`, {
    active: true,
    accountId,
    accountName,
    day,
    hour,
    userAction,
    userId,
    userName,
    description,
    dateTime,
  });
  return data;
};
const saveSituationCategorie = async (
  name,
  accountId,
  accountName,
  day,
  hour,
  userAction,
  userId,
  userName,
  description,
  dateTime
) => {
  const data = axiosDefault.post(`/situation/category`, {
    active: true,
    name,
    accountId,
    accountName,
    day,
    hour,
    userAction,
    userId,
    userName,
    description,
    dateTime,
  });
  return data;
};
const updateSituationCategorie = async (
  id,
  name,
  accountId,
  accountName,
  day,
  hour,
  userAction,
  userId,
  userName,
  description,
  dateTime
) => {
  const data = axiosDefault.put(`/situation/category/${id}`, {
    name,
    accountId,
    accountName,
    day,
    hour,
    userAction,
    userId,
    userName,
    description,
    dateTime,
  });
  return data;
};
export default {
  GetCategories,
  inativeSituationCategorie,
  activeSituationCategorie,
  saveSituationCategorie,
  updateSituationCategorie,
  GetSituationCategoriesActive,
  GetCategoriesSimple,
  GetCategoriasSelect,
};
