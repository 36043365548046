import { Flex, Text, useColorModeValue }  from "@chakra-ui/react";
const { Ping } = require("../styles");


const PingStatus = () => {
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <Flex 
      flexDirection="row" 
      wrap="wrap" 
      marginBottom={2}
      marginTop={2}
      justifyContent={{ base: 'center', md: 'flex-start' }}
    >
      <Flex marginRight={2} alignItems="center">
        <Ping backgroundColor="#FC8181" />
        <Text
          fontSize='md'
          color={textColor}
          marginLeft={2}
        >Recebida</Text>
      </Flex>
      <Flex marginRight={2} alignItems="center">
        <Ping backgroundColor="#F6E05E" />
        <Text
          marginLeft={2}
          fontSize='md'
          color={textColor}
        >Em produção</Text>
      </Flex>
      <Flex marginRight={2} alignItems="center">
        <Ping backgroundColor="#9BE1FF" />
        <Text
          marginLeft={2}
          fontSize='md'
          color={textColor}
        >Protocolado</Text>
      </Flex>
      <Flex marginRight={2} alignItems="center">
        <Ping backgroundColor="#B693F4" />
        <Text
          marginLeft={2}
          fontSize='md'
          color={textColor}
        >Em comissões</Text>
      </Flex>
      <Flex marginRight={2} alignItems="center">
        <Ping backgroundColor="#F6AD54" />
        <Text
          marginLeft={2}
          fontSize='md'
          color={textColor}
        >Em trâmite para sanção</Text>
      </Flex>
      <Flex marginRight={2} alignItems="center">
        <Ping backgroundColor="#9AE6B4" />
        <Text
          marginLeft={2}
          fontSize='md'
          color={textColor}
        >Lei aprovada</Text>
      </Flex>
      <Flex marginRight={2} alignItems="center">
        <Ping backgroundColor="#C9D3E0" />
        <Text
          marginLeft={2}
          fontSize='md'
          color={textColor}
        >Arquivada</Text>
      </Flex>
    </Flex>
  )
}
export default PingStatus;