import { Flex } from "@chakra-ui/react";
import moment from "moment";
import { Handle } from "pages/demands_page/styles";
import React, { memo } from "react";
import { LabelDetail } from "semantic-ui-react";

const FilterValues = ({ 
  isFilter, 
  loading,
  startDate,
  renderName,
  renderCategories,
  renderResponsible,
  renderStatus,
  renderSituations,
  demandDescription,
}) => {
  return (
    <Flex flexWrap="wrap" mt="8">
      <br />
      {(isFilter && !loading) && (
        <LabelDetail
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <strong style={{ marginRight: 4 }}>Filtros:</strong>
          {demandDescription && (
            <Handle>
              <text style={{ marginRight: 4 }}>
                {`${"  "}${demandDescription}${demandDescription ? "." : ""}` || ""}
              </text>
            </Handle>
          )}
          {renderName && (
            <Handle>
              <text style={{ marginRight: 4 }}>{renderName}</text>
            </Handle>
          )}
          {renderCategories && (
            <Handle>
              <text style={{ marginRight: 4 }}>{renderCategories}</text>
            </Handle>
          )}
          {renderResponsible && (
            <Handle>
              <text style={{ marginRight: 4 }}>
                {renderResponsible}
              </text>
            </Handle>
          )}
          {renderStatus && (
            <Handle>
              <text style={{ marginRight: 4 }}>
                {renderStatus}
              </text>
            </Handle>
          )}
          {renderSituations && (
            <Handle>
              <text style={{ marginRight: 4 }}>
                {renderSituations}
              </text>
            </Handle>
          )}
          {startDate && (
            <Handle>
              <text style={{ marginRight: 4 }}>
                {moment(startDate).format("DD/MM/YYYY")}
              </text>
            </Handle>
          )}
        </LabelDetail>
      )}
    </Flex>
  )
}
export default memo(FilterValues);