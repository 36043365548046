const INITIAL_STATE = {
  withCategory: [],
  withoutCategory: [],
  registeredBy: "",
  editedBy: "",
  search: "",
  contactNumber: "",
  document: "",
  locationType: "",
  gender: "",
  documentType: "",
  contactNumberType: "",
  indication: "",
  page: 1,
  totalPages: 0,
  info2: "",
  pagePagination: 1,
  isDeath: false,
  isFilterApplied: false,
  neighborhood: "",
  needsAddresUpdate: "",
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "@SET_INIDICATION":
      return {
        ...state,
        indication: action.payload.indication,
      };
    case "@SET_INFO":
      return {
        ...state,
        info2: action.payload.info2,
      };
    case "@SET_IS_DEATH":
      return {
        ...state,
        isDeath: action.payload.isDeath,
      };
    case "@SET_SEARCH":
      return {
        ...state,
        search: action.payload.search,
      };
    case "@SET_WITH_CATEGORY":
      return {
        ...state,
        withCategory: action.payload.withCategory,
      };
    case "@SET_NEIGHBORHOOD":
      return {
        ...state,
        neighborhood: action.payload.neighborhood,
      };
    case "@SET_WITHOUT_CATEGORY":
      return {
        ...state,
        withoutCategory: action.payload.withoutCategory,
      };
    case "@SET_REGISTERED_BY":
      return {
        ...state,
        registeredBy: action.payload.registeredBy,
      };
    case "@SET_NEEDS_ADDRESUPDATE":
      return {
        ...state,
        needsAddresUpdate: action.payload.needsAddresUpdate,
      };
    case "@SET_EDITED_BY":
      return {
        ...state,
        editedBy: action.payload.editedBy,
      }
    case "@SET_CONTACT_NUMBER":
      return {
        ...state,
        contactNumber: action.payload.contactNumber,
      }
    case "@SET_CONTACT_NUMBER_TYPE":
      return {
        ...state,
        contactNumberType: action.payload.contactNumberType,
      }
    case "@SET_DOCUMENT":
      return {
        ...state,
        document: action.payload.document,
      }
    case "@SET_DOCUMENT_TYPE":
      return {
        ...state,
        documentType: action.payload.documentType,
      }
    case "@SET_LOCATION_TYPE":
      return {
        ...state,
        locationType: action.payload.locationType,
      }
    case "@SET_GENDER":
      return {
        ...state,
        gender: action.payload.gender,
      }

    case "@SET_PAGE":
      return {
        ...state,
        page: action.payload.page,
      };
    case "@SET_TOTAL_PAGES":
      return {
        ...state,
        totalPages: action.payload.totalPages,
      };
    case "@SET_PAGE_PAGINATION":
      return {
        ...state,
        pagePagination: action.payload.pagePagination,
      };
    case "@SET_FILTER_APPLIED":
      return {
        ...state,
        isFilterApplied: action.payload.isFilterApplied,
      };
    case "@SET_CLEAN_FILTER":
      return {
        ...state,
        page: 1,
        search: "",
        withCategory: [],
        withoutCategory: [],
        registeredBy: "",
        editedBy: "",
        contactNumber: "",
        document: "",
        locationType: "",
        gender: "",
        indication: "",
        totalPages: 0,
        info2: "",
        pagePagination: 1,
        isDeath: false,
        documentType: "",
        contactNumberType: "",
        isFilterApplied: false,
        neighborhood: "",
        needsAddresUpdate: "",
      };
    default:
      return state;
  }
};
export default usersReducer;
