const INITIAL_STATE = {
  startDate: "",
  endDate: "",
  name: "",
  overview: "",
  status: "",
  progress_status: "",
  progress_statusList: [],
  responsible: "",
  isFilter: false,
  search: "",
  legislatives: [],
  page: 1,
  currentPage: 0,
  pages: 0,
  isSearch: false,
};

const legislationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "@SET_LEGISLATIVE":
      return {
        ...state,
        legislatives: action.payload.legislatives,
      };
    case "@SET_START_DATE":
      return {
        ...state,
        startDate: action.payload.startDate,
      };
    case "@SET_SEARCH":
      return {
        ...state,
        search: action.payload.search,
        isSearch: action.payload.isSearch
      };
    case "@SET_END_DATE":
      return {
        ...state,
        endDate: action.payload.endDate,
      };
    case "@SET_NAME":
      return {
        ...state,
        name: action.payload.name,
      };
    case "@SET_STATUS":
      return {
        ...state,
        status: action.payload.status,
      };
    case "@SET_OVERVIEW":
      return {
        ...state,
        overview: action.payload.overview,
      };
    case "@SET_PROGRESS_STATUS":
      return {
        ...state,
        progress_status: action.payload.progress_status,
      };
    case "@SET_PROGRESS_STATUS_LIST":
      return {
        ...state,
        progress_statusList: action.payload.progress_statusList,
      };
    case "@SET_RESPONSIBLE":
      return {
        ...state,
        responsible: action.payload.responsible,
      };
    case "@SET_IS_FILTER":
      return {
        ...state,
        isFilter: action.payload.isFilter,
      };
    case "@SET_IS_SEARCH":
      return {
        ...state,
        isSearch: action.payload.isSearch,
      };
    case "@SET_PAGE":
      return {
        ...state,
        page: action.payload.page,
      };
    case "@SET_PAGES":
      return {
        ...state,
        pages: action.payload.pages,
      };
    case "@SET_CLEAN_FILTER_LEGISLATION":
      return {
        ...state,
        startDate: "",
        endDate: "",
        name: "",
        overview: "",
        status: "",
        progress_status: "",
        responsible: "",
        isFilter: false,
        page: 1,
        progress_statusList: [],
        search: "",
        legislatives: [],
        currentPage: 0,
        pages: [],
        isSearch: false,
      };
    case "@SET_LEGISLATIVE_RESPONSE":
      return {
        ...state,
        pages: action.payload.pages,
        legislatives: action.payload.legislatives,
        currentPage: action.payload.currentPage,
        isFilter: action.payload.isFilter
      };
    case "@SET_LEGISLATIVE_FILTER":
      return {
        ...state,
        page: 1,
        pages: action.payload.pages,
        legislatives: action.payload.legislatives,
        currentPage: action.payload.currentPage,
        isFilter: false,
      };
    default:
      return state;
  }
};
export default legislationReducer;
