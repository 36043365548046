import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 145px;
  @media only screen and (max-width: 600px) {
    margin-left: 50px;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  margin-left: 125px;
  @media only screen and (max-width: 600px) {
    margin-left: 60px;
  }
`;

export const Border = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eff2f5;
  border-radius: 4px;
  padding: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: -10px;
  border-radius: 6px;
  @media only screen and (max-width: 600px) {
    margin-left: 0px;
  }
`;

export const RowCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const RowText = styled.div`
  display: flex;
  height: 24px;
  flex-direction: row;
`;
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vh;
  height: 100%;
  margin-left: 10px;
`;

export const Ping = styled.div`
  height: 14px;
  margin-top: 3px;
  width: 14px;
  background-color: ${(porps) => porps.backgroundColor};
  border-radius: 100px;
`;
export const ContainerData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: 800px) {
    width: 99%;
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  width: 100%;
  @media only screen and (max-width: 800px) {
    width: 99%;
  }
`;
export const SizedBox = styled.div`
  height: 16px;
  width: 16px;
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;
export const ContainerRelation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  flex-wrap: wrap;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const ContainerImg = styled.div`
  width: inherit;
`;
export const Img = styled.img`
  width: 124px;
  float: left;
  margin: 3px;
  padding: 3px;
  border-radius: 6px;
  max-height: 100px;
`;
