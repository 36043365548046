import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useToast } from '@chakra-ui/react'

import apiCategory from "../../services/urls/category";
import SideBar from "components/@sidebar";
import { Box, Flex, Button, FormControl, FormLabel, useColorModeValue, Input } from "@chakra-ui/react";

const PeopleOfCategoryPage = () => {
  const toast = useToast();
  const { _id: accountId, name: accountName } = useSelector(
    (state) =>
      state.user
  );

  const [loadingSave, setLoadingSave] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const textColor = useColorModeValue("navy.700", "white");


  const requestNewCategory = async () => {
    try {
      if (!categoryName) {
        toast({
          title: '',
          description: "Informe o nome da categoria.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (categoryName.length < 3) {
        toast({
          title: '',
          description: "O nome deve conter no minímo 3 caracteres.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoadingSave(true);
      await apiCategory.saveCategorie({
        categoryName,
        active: true,
        accountId,
        accountName,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Adicionou",
        userId: undefined,
        userName: undefined,
        description: "Adicionou uma nova categoria.",
        dateTime: moment().format(),
      });
 
      setLoadingSave(false);
      setCategoryName("");
      toast({
        title: '',
        description: "Categoria adicionada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });

    } catch (error) {
      setLoadingSave(false);
      toast({
        title: '',
        description: "Erro ao cadastrar categoria, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex alignItems="center" justifyContent="center" h="96">
      <Flex
        justifyContent="center"
        alignItems="center"
        padding={6}
        borderRadius={8}
        boxShadow="2xl" 
        bg="white"
        w="3xl"
      >
        <Flex
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Nome da categoria de pessoa*
            </FormLabel>
            <Input
              onChange={(event) => setCategoryName(event.target.value)}
              required
              value={categoryName}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='..'
              mb='24px'
              fontWeight='500'
              size='lg'
            />

            <Button
              fontSize='sm'
              variant='brand'
              isLoading={loadingSave}
              onClick={() => requestNewCategory()}
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'>
              Cadastrar
            </Button>
          </FormControl>
        </Flex>
      </Flex>
      </Flex>
    </Box>
  );
};
export default PeopleOfCategoryPage;
