import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";

import CardContainer from "components/card/Card";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  AddIcon, EditIcon, ViewIcon
} from "@chakra-ui/icons";
import {
  Card,
} from "semantic-ui-react";

import axios from "../../services/urls/user";
import { AiFillDelete } from "react-icons/ai";
import {
  ArrowBackIcon,
} from "@chakra-ui/icons";
import SideBar from "components/@sidebar";
import {
  Box,
  Modal,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tab,
  useColorModeValue,
  Button,
  IconButton,
  ModalBody,
  Text,
  Stack,
  Skeleton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  useToast,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Textarea,
  AlertDialogCloseButton
} from "@chakra-ui/react";
import BasicData from "./components/basic_data";
import Contact from "./components/contact";
import Address from "./components/address";
import UserDocument from "./components/document";
import Other from "./components/other";
import AvatarCustom from "components/@avatar";
import { situationColors } from "utils/situationColors";
import { UltimatePagination } from "components/pagination";
import { useParams } from 'react-router-dom';
import detectarDispositivoMovel from "utils/typeAcess";
import { Span } from "./styles";

const ShowPeoplePage = () => {
  const shoulRequest = useRef(true);
  const history = useNavigate();
  const { _id, name: accountName } = useSelector(
    (state) => state.user
  );

  const { id: idUser } = useParams();
  const userId = useLocation()?.state?.userId || idUser;
  const toast = useToast();
  const [data, setData] = useState();
  const [historic, setHistoric] = useState([]);
  const [loading, setLoading] = useState(false);
  const [relations, setRelations] = useState([]);
  const [isEmpityHistorci, setIsEmpityHistoric] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingUpdateHist, setLoadingUpdateHist] = useState(false);
  const [loadingRequestHistory, setLoadingRequestHistory] =
    useState(false);
  const [historicId, setHistoricId] = useState("");
  const [isDel, setIsDel] = useState(false);
  const [itemHistoric, setItemHistoric] = useState(null);
  const textColor = useColorModeValue("navy.700", "white");
  const [historicPerson, setHistoricPerson] = useState("");
  const [loadingNewHistoric, setloadingNewHistoric] = useState(false);

  const { isOpen: isOpenNewHistoric, onOpen: onOpenNewHistoric, onClose: onCloseNewHistoric } = useDisclosure();
  const { isOpen: isOpenDel, onOpen: onOpenDel, onClose: onCloseDel } = useDisclosure();
  const { isOpen: isOpenEditHistoric, onOpen: onOpenEditHistoric, onClose: onCloseEditHistoric } = useDisclosure();
  const { isOpen: isOpenHistoricOrDemanda, onOpen: onOpenHistoricOrDemanda, onClose: onCloseHistoricOrDemanda } = useDisclosure();
  const cancelRef = useRef();

  const requestRelations = useCallback(async () => {
    try {
      const response = await axios.GetRelations(userId);
      const data = response?.data?.data.map(
        (item) => ({
          email: item.relation.email,
          _id: item.relation._id,
          name: `${item.relation.name}`,
          isDeath: item.relation.isDeath,
          relation: `${item.relationName}`,
          src: item.relation.photo,
        })
      );

      setRelations(data);
    } catch (error) {
      toast({
        title: '',
        description: error?.response?.data?.error,
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast, userId]);

  const requestDataUser = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.GetUser(userId);
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: '',
        description: "Erro ao obter dados do usuário",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast, userId]);

  const requestHistoric = useCallback(async (pageSelected) => {
    try {
      const perPage = 5;
      setLoadingRequestHistory(true);
      setHistoric([]);

      const promiseResponse = await Promise.all([
        await axios.GetHistoricPaginate(userId, pageSelected, perPage),
        await axios.GetDemandsPaginate(userId, pageSelected, perPage)
      ]);

      const unionData = promiseResponse[0].data?.data?.docs.concat(promiseResponse[1].data?.data?.docs);

      setIsEmpityHistoric(promiseResponse[0].data?.data?.docs.length === 0 && promiseResponse[1].data?.data?.docs.length === 0);
      setHistoric(unionData);
      setLoadingRequestHistory(false);
      if (promiseResponse[0]?.data?.data?.totalPages > promiseResponse[1]?.data?.data?.totalPages) {
        setTotalPages(promiseResponse[0]?.data?.data?.totalPages);
      }
      else if (promiseResponse[0]?.data?.data?.totalPages < promiseResponse[1]?.data?.data?.totalPages) {
        setTotalPages(promiseResponse[1]?.data?.data?.totalPages);
      }
      else if (promiseResponse[0]?.data?.data?.totalPages === promiseResponse[1]?.data?.data?.totalPages) {
        setTotalPages(promiseResponse[0]?.data?.data?.totalPages);
      }

    } catch (error) {
      toast({
        description: "Algo deu errado ao listar histórios e demandas",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setLoadingRequestHistory(false);
    }
  }, [toast, userId]);


  const insertHistoric = async () => {
    try {
      if (!historicPerson) {
        toast({
          description: "Descreva algo!",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setloadingNewHistoric(true);

      const payload = {
        systemUser: _id,
        registeredPerson: data?._id,
        historicPerson: historicPerson,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        date: moment().format(),
        accountId: _id,
        accountName: accountName,
        userId: data?._id,
        userName: data?.name,
        userAction: "Adicionou",
        dateTime: moment().format(),
      }
      await axios.createHistoricPerson(payload);

      toast({
        title: '',
        description: "Histórico adicionado com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setHistoricPerson("");
      requestHistoric(page);
      setloadingNewHistoric(false);
      onCloseNewHistoric();

    } catch (error) {
      setloadingNewHistoric(false);
      toast({
        title: '',
        description: "Erro ao criar histórico",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const updateHistoric = async () => {
    try {
      setLoadingUpdateHist(true);
      const payload = {
        historicPerson,
        editedByUser: _id,
        accountId: _id,
        accountName,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Editou",
        userName: data?.name,
        userId: data?._id,
        dateTime: moment().format()
      };

      await axios.updateHistoricPerson(historicId, payload);

      toast({
        title: '',
        description: "Histórico atualizado com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setLoadingUpdateHist(false);
      setHistoricPerson("");
      setHistoricId("");
      onCloseEditHistoric();
      requestHistoric(page);
    } catch (error) {
      setLoadingUpdateHist(false);
      toast({
        title: '',
        description: error?.response?.data?.error,
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const removeDemand = async (demandId) => {
    try {
      const payload = {
        accountId: _id,
        accountName: accountName,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Editou",
        userId: data?._id || "",
        userName: data?.name,
        description: `Excluiu a demanda do cadastrado "${data?.name}"`,
        dateTime: moment().format(),
      };

      await axios.deleteDemandPerson(demandId, payload)
      requestHistoric(page);

      toast({
        title: '',
        description: "Demanda deletada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      onCloseDel();
      setItemHistoric(null);
    } catch (error) {
      toast({
        title: '',
        description: "Erro deletar a Demanda do cadastrado",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }

  const removetHistoric = async (historicId) => {
    try {
      const payload = {
        accountId: _id,
        accountName: accountName,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Editou",
        userId: data?._id || "",
        userName: data?.name,
        description: `Excluiu o histórico do cadastrado "${data?.name}"`,
        dateTime: moment().format(),
      };
      await axios.deleteHistoricPerson(historicId, payload);

      requestHistoric(page);
      toast({
        title: '',
        description: "Histórico deletado com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });

      onCloseDel();
      setItemHistoric(null);
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao deletar histórico do cadastrado",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      Promise.all([
        requestDataUser(),
        requestHistoric(1),
        requestRelations(),
      ]);
    }
  }, [
    requestDataUser,
    requestHistoric,
    requestRelations,
  ]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex
        boxShadow="2xl"
        margin={4}
        direction="column"
        backgroundColor="white"
        borderRadius={8}
      >
        <Flex>
          <IconButton
            marginLeft={10}
            marginTop={4}
            onClick={() => history(-1)}
            icon={<ArrowBackIcon color="facebook.500" />}
          />
          <IconButton
            marginLeft={3}
            marginTop={4}
            onClick={() => {
              history(
                "/editar-dados-do-cadastro",
                {
                  state: {
                    id: userId,
                    isBrod: true,
                  },
                }
              );
            }}
            icon={<EditIcon color="orange" />}
          />
        </Flex>
        <Tabs
          marginLeft={10}
          marginTop={2}
          marginBottom={-2}
          isManual
          flexWrap="wrap"
          orientation={detectarDispositivoMovel() ? "vertical" : "horizontal"}
          variant="soft-rounded"
        >
          <Flex>
            <TabList>
              {loading ? null : (
                <>
                  <Tab>Dados Básicos</Tab>
                  <Tab>Contato</Tab>
                  <Tab>Endereço</Tab>
                  <Tab>Documento Pessoal</Tab>
                  <Tab>Outros</Tab>
                </>
              )}
            </TabList>
          </Flex>
          <TabPanels marginLeft={-4}>
            <TabPanel>
              {loading ? (
                <Stack>
                  <Skeleton marginTop="0" borderRadius={15} height='50px' />
                  <Skeleton marginTop="2" borderRadius={15} height='50px' />
                  <Skeleton marginTop="2" borderRadius={15} height='50px' />
                  <Skeleton marginTop="2" borderRadius={15} height='50px' />
                </Stack>
              ) : (
                <BasicData data={data} />
              )}
            </TabPanel>
            <TabPanel>
              <Contact data={data} />
            </TabPanel>
            <TabPanel>
              <Address data={data} />
            </TabPanel>
            <TabPanel>
              <UserDocument data={data} />
            </TabPanel>
            <TabPanel>
              <Other data={data} />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Flex>
          {!loading && (
            <Flex
              marginTop={1}
              marginLeft="10"
              marginBottom="4"
              direction="column"
            >
              <Text
                fontSize='md'
                fontWeight='500'
                color={textColor}>
                {" "}
                Cadastrado por {data?.user?.name} {data?.created ? ` - ${data?.created}` : ""}
              </Text>
              {data?.updatedTo && (
                <Text
                  fontSize='md'
                  fontWeight='500'
                  color={textColor}
                >
                  Editado por {data?.updatedTo?.name} - {data?.updated}
                </Text>
              )}
            </Flex>
          )}
        </Flex>
        {loading ? (
          <Stack paddingLeft={10} marginBottom="2" paddingRight={10}>
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
          </Stack>
        ) : (
          <Flex
            paddingLeft={10}
            paddingRight={10}
            flexDirection="column"
          >
            <Flex
              direction="column"
              boxShadow="2xl"
              bg="white"
              borderWidth="1px"
              padding={6}
            >
              <Text
                marginTop={4}
                marginBottom={4}
                fontSize='lg'
                fontWeight='500'
                color={textColor}>
                Relacionamentos
              </Text>
              <Flex>
                {relations.length === 0 && (
                  <Flex>
                    <Text
                      fontSize='md'
                      fontWeight='300'
                      color={textColor}
                    >
                      Sem resultados
                    </Text>
                  </Flex>
                )}
                {relations.map((item) => (
                  <AvatarCustom
                    action={() => {
                      //
                    }}
                    navigate={() => {
                      history(
                        "/visualizar-cadastrado",
                        {
                          state: {
                            userId: item._id,
                          }
                        },
                      );
                      history(0)
                    }}
                    isShowIcon
                    id={item._id}
                    name={`${item.name}`}
                    text={`${item.isDeath ? "(Falecido)" : ""}`}
                    relation={item.relation}
                    src={item.src}
                  />
                ))}
              </Flex>
              <br />
            </Flex>
            <Flex
              direction="column"
              borderRadius={4}
              mb='12'
            >
              <Card>
                <Flex
                  bg="gray.600"
                  height="14"
                  alignItems="center"
                  borderTopLeftRadius={4}
                  borderTopRightRadius={4}
                  justifyContent="space-between"
                >
                  <Text
                    fontSize='md'
                    fontWeight='500'
                    color="white"
                    marginLeft={8}
                  >
                    HISTÓRICO GERAL
                  </Text>
                  <Flex marginRight={8}>
                    <IconButton
                      height="36px"
                      onClick={() => {
                        onOpenHistoricOrDemanda();
                      }}
                      icon={<AddIcon />}
                    />
                  </Flex>
                </Flex>
                <Flex
                  direction="column"
                  overflow="scroll"
                  maxHeight="700px"
                >
                  {isEmpityHistorci && (
                    <Flex justifyContent="center" marginTop={4}>
                      <Text
                        fontSize='md'
                        fontWeight='500'
                        marginBottom="4"
                        color={textColor}
                      >
                        Sem resultados
                      </Text>
                    </Flex>
                  )}
                  {loadingRequestHistory && (
                    <Stack>
                      <Skeleton marginTop="2" borderRadius={15} height='80px' />
                      <Skeleton marginTop="2" borderRadius={15} height='80px' />
                      <Skeleton marginTop="2" borderRadius={15} height='80px' />
                      <Skeleton marginTop="2" borderRadius={15} height='80px' />
                      <Skeleton marginTop="2" marginBottom={10} borderRadius={15} height='80px' />
                    </Stack>
                  )}
                  {historic?.map((item) => (
                    <CardContainer
                      key={item._id}
                      marginTop={2}
                      backgroundColor={item.isDemand
                        ? situationColors(item?.demandStatus?.name)
                        : "gray.200"}
                      children={
                        <Flex
                          margin={3}
                          justifyContent="space-between"
                          direction={{ base: 'column', md: 'row' }}
                        >
                          <Flex flex={1}>
                            {item.isDemand ? (
                              <Flex flexDirection="column" flex={1}>
                                <Flex direction="column" flex={0.6}>
                                  <Flex>
                                    <Text
                                      fontSize='md'
                                      fontWeight='500'
                                      color={textColor}
                                    >
                                      {moment(item.createdByUserAt).format("DD/MM/YYYY [às] HH:mm:ss")}
                                    </Text>
                                  </Flex>
                                  <Flex>
                                    <Text
                                      fontSize='md'
                                      fontWeight='500'
                                      color={textColor}
                                    >
                                      Status de demanda:
                                    </Text>
                                    <Text
                                      fontSize='md'
                                      color={textColor}
                                      marginLeft={2}>
                                      {" "}
                                      {item?.demandStatus?.name}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Flex>
                                  <Text
                                    fontSize='md'
                                    fontWeight='500'
                                    color={textColor}
                                  >
                                    Situação da demanda:
                                  </Text>
                                  <Text
                                    fontSize='md'
                                    color={textColor}
                                    marginLeft={2}>
                                    {item?.demandStatusSituation?.name || "--"}
                                  </Text>
                                </Flex>
                                <Flex direction="row">
                                  <Text ontSize='md' color={textColor}>
                                    <Span
                                    >Demanda:{' '}
                                    </Span>
                                    {item?.demandDescription}
                                  </Text>
                                </Flex>
                                <Flex mt="2">
                                  <Text fontSize='md' color={textColor}>
                                    <Span>
                                      Último histórico:{' '}
                                    </Span>
                                    {item?.theLastDemandHistoric?.demandHistoric || "--"}
                                  </Text>
                                </Flex>
                                <Flex mt="2" justifyContent="space-between" flex={1}>
                                  <Flex>
                                    {item?.responsible && (
                                      <Flex>
                                        <Text
                                          fontSize='md'
                                          fontWeight='500'
                                          color={textColor}
                                        >Responsavél:</Text>
                                        <Text
                                          fontSize='md'
                                          color={textColor}
                                          marginLeft={2}>
                                          {item?.responsible?.name}
                                        </Text>
                                      </Flex>
                                    )}
                                  </Flex>
                                  <Flex direction="column">
                                    <Text
                                      fontSize='md'
                                      fontWeight="500"
                                      color={textColor}
                                    >
                                      Criado por {item?.createdByUser?.name} -{" "}
                                      {moment(item?.createdByUserAt).format("DD/MM/YYYY [às] HH:mm:ss")}
                                    </Text>
                                    <Text
                                      display={item?.editedByUser ? 'flex' : 'none'}
                                      fontSize='md'
                                      fontWeight="500"
                                      color={textColor}
                                    >
                                      Editado por {item?.editedByUser?.name} -{" "}
                                      {moment(item?.editedByUserAt).format("DD/MM/YYYY [às] HH:mm:ss")}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Flex>
                            ) : (
                              <Flex flex={1}>
                                <Flex direction="column" style={{ whiteSpace: "pre-line" }} flex={1}>
                                  <Text
                                    fontSize='md'
                                    color={textColor}
                                    fontWeight="500"
                                  >
                                    {moment(item.createdByUserAt).format("DD/MM/YYYY [às] HH:mm:ss")}
                                  </Text>
                                  <Flex
                                    style={{
                                      whiteSpace: "pre-line",
                                      marginBottom: 7,
                                    }}
                                  >
                                    <Text
                                      fontSize='md'
                                      color={textColor}
                                    >
                                      {item?.historicPerson}
                                    </Text>
                                  </Flex>
                                  <Flex direction="column" alignItems={{ base: 'flex-start', md: 'flex-end' }} flex={1}>
                                    <Text
                                      fontSize='md'
                                      fontWeight="500"
                                      color={textColor}
                                    >
                                      Criado por {item?.createdByUser?.name} -{" "}
                                      {moment(item?.createdByUserAt).format("DD/MM/YYYY [às] HH:mm:ss")}
                                    </Text>
                                    <Text
                                      display={item?.editedByUser ? 'flex' : 'none'}
                                      fontSize='md'
                                      fontWeight="500"
                                      color={textColor}
                                    >
                                      Editado por {item?.editedByUser?.name} -{" "}
                                      {moment(item?.editedByUserAt).format("DD/MM/YYYY [às] HH:mm:ss")}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Flex>
                            )}
                          </Flex>
                          <Flex
                            position="absolute"
                            right={2}
                            top={2}
                            direction={{ base: 'row-reverse', md: 'column' }}
                            alignItems={{ base: 'center', md: 'flex-end' }}
                            justifyContent="space-between"
                            marginTop={{ base: 2, md: 0 }}
                          >
                            {item.historicPerson !==
                              "Enviado mensagem de parabéns" &&
                              item?.historicPerson?.includes(
                                "A mensagem de parabéns não foi possível ser realizada."
                              ) !== true ? (
                              <Flex>
                                <IconButton
                                  marginBottom={!item.isDemand ? '2' : '0'}
                                  color="red.400"
                                  onClick={() => {
                                    setItemHistoric(item);
                                    onOpenDel();
                                  }}
                                  marginRight={1}
                                  icon={<AiFillDelete />}
                                />
                                {item?.isDemand && (
                                  <IconButton
                                    icon={<ViewIcon />}
                                    color="blue.700"
                                    onClick={() =>
                                      history(
                                        "/detalhe-da-demanda",
                                        {
                                          state: { userDemandId: item._id },
                                        }
                                      )
                                    }
                                  />
                                )}
                                {!item?.isDemand && (
                                  <IconButton
                                    icon={<EditIcon />}
                                    color="orange.600"
                                    onClick={() => {
                                      setHistoricId(item._id);
                                      setHistoricPerson(item.historicPerson);
                                      onOpenEditHistoric();
                                    }}
                                  />
                                )}
                              </Flex>
                            ) : null}
                          </Flex>
                        </Flex>
                      }
                    />
                  ))}
                </Flex>
              </Card>
            </Flex>
            {historic.length > 0 && (
              <Flex justifyContent="center" marginTop="4" marginBottom="4">
                <UltimatePagination
                  currentPage={page}
                  onChange={(p) => {
                    requestHistoric(p);
                    setPage(p);
                  }}
                  totalPages={totalPages}
                />
              </Flex>
            )}
          </Flex>
        )}
        <AlertDialog
          isOpen={isOpenDel}
          leastDestructiveRef={cancelRef}
          onClose={() => {
            setItemHistoric(null);
            onCloseDel();
          }}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              </AlertDialogHeader>
              <AlertDialogBody>
                Tem certeza que gostaria de excluir?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  marginRight={1}
                  colorScheme="red"
                  onClick={() => {
                    onCloseDel();
                    setItemHistoric(null);
                  }}
                >
                  Não
                </Button>
                <Button
                  colorScheme="green"
                  onClick={() => {
                    if (itemHistoric?.isDemand) {
                      removeDemand(itemHistoric?._id);
                    } else {
                      removetHistoric(itemHistoric?._id);
                    }
                  }}
                >
                  Sim
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
          isOpen={isOpenHistoricOrDemanda}
          leastDestructiveRef={cancelRef}
          onClose={() => {
            onCloseHistoricOrDemanda();
          }}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogCloseButton />
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                <Text>Adicionar</Text>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <Button
                  marginRight={2}
                  colorScheme='whatsapp'
                  onClick={() => {
                    onOpenNewHistoric();
                    onCloseHistoricOrDemanda();
                  }}
                >
                  Histórico
                </Button>
                <Text
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  marginRight={2}
                >Ou</Text>
                <Button
                  colorScheme='facebook'
                  onClick={() => {
                    history(
                      "/criar-nova-demanda",
                      {
                        state: {
                          name: `${data?.name}`,
                          whatsApp: data?.whatsApp,
                          city: data?.city,
                          bairro: data?.neighborhood,
                          _id: data?._id,
                          isEdit: false,
                          photo: data?.photo,
                        }
                      }
                    );
                  }}
                >
                  Demanda
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <Modal
          size="small"
          open={isDel}
          onClose={() => {
            setItemHistoric(null);
            setIsDel(false);
          }}
        >
          <Modal.Header>Tem certeza que gostaria de excluir?</Modal.Header>
          <Modal.Actions>
            <Button
              negative
              onClick={() => {
                setIsDel(false);
                setItemHistoric(null);
              }}
            >
              Não
            </Button>
            <Button
              positive
              onClick={() => {
                if (itemHistoric?.isDemand) {
                  removetHistoric(
                    itemHistoric?._id,
                    itemHistoric?._id,
                    true,
                    itemHistoric.description
                  );
                } else {
                  removetHistoric(itemHistoric?._id || "", undefined, undefined);
                }
                setIsDel(false);
              }}
            >
              Sim
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal isCentered size="4xl"
          isOpen={isOpenNewHistoric}
          onClose={() => {
            onCloseNewHistoric();
            setHistoricPerson("");
          }}>
          <ModalOverlay />
          <ModalContent>
            <Text fontWeight="medium" textTransform="uppercase" marginLeft="6" marginTop="4">Novo Histórico</Text>
            <ModalCloseButton />
            <ModalBody>
              <Text size="10" mb="2">{moment().format("DD/MM/YYYY")}</Text>
              <Textarea
                value={historicPerson}
                required
                rows={6}
                maxLength="500"
                onChange={(event) => setHistoricPerson(event.target.value)}
                placeholder='...'
                resize="none"
              />
            </ModalBody>
            <ModalFooter>
              <Button isDisabled={loadingNewHistoric} colorScheme='red' mr={3} onClick={() => {
                onCloseNewHistoric();
                setHistoricPerson("");
              }}>
                Cancelar
              </Button>
              <Button
                onClick={() => insertHistoric()}
                isLoading={loadingNewHistoric}
                colorScheme='green'
              >
                Salvar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isCentered size="4xl"
          isOpen={isOpenEditHistoric}
          onClose={() => {
            onCloseEditHistoric();
            setHistoricPerson("");
          }}>
          <ModalOverlay />
          <ModalContent>
            <Text fontWeight="medium" textTransform="uppercase" marginLeft="6" marginTop="4">Editar Histórico</Text>
            <ModalCloseButton />
            <ModalBody>
              <Text size="10" mb="2">{moment().format("DD/MM/YYYY")}</Text>
              <Textarea
                value={historicPerson}
                required
                rows={6}
                maxLength="500"
                onChange={(event) => setHistoricPerson(event.target.value)}
                placeholder='...'
                resize="none"
              />
            </ModalBody>
            <ModalFooter>
              <Button isDisabled={loadingUpdateHist} colorScheme='red' mr={3} onClick={() => {
                onCloseEditHistoric();
                setHistoricPerson("");
              }}>
                Cancelar
              </Button>
              <Button
                onClick={() => updateHistoric()}
                isLoading={loadingUpdateHist}
                colorScheme='green'
              >
                Atualizar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};
export default ShowPeoplePage;
