
const INITIAL_STATE = {
  situations_progress: [],
  progress_situation: [],
  startDate: "",
  endDate: "",
  categorie: [],
  responsibilite: [],
  user: "",
  search: "",
  demand: "",
  page: 0,
  totalPages: 0,
  situations: [],
  filterDemand: false,
  pagePagination: 1,
  isFilter: false,

  registeredPersonSearch: '',
  demandStatusSituationId: '',
  statusId: '',
  responsible: [],
  registeredPerson: '',
  demandDescription: '',
  categories: [],
  createdByUserAt: '',
  totalDemands: '',
  page: 1,
  totalPages: 0,
  filterApplied: false,
  statusSelected: '',
  situationsSelected: [],
  demandsPaginate: null,
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case "@SET_DEMANDS_PAGINATE":
      return {
        ...state,
        demandsPaginate: action.payload,
      };

    case "@SET_REGISTERED_PERSON_SEARCH":
      return {
        ...state,
        registeredPersonSearch: action.payload,
      };

    case "@SET_STATUS_SELECTED":
      return {
        ...state,
        statusSelected: action.payload,
      };


    case "@SET_SITUATIONS_SELECTED":
      return {
        ...state,
        situationsSelected: action.payload,
      };

    case "@SET_TOTAL_DEMAND":
      return {
        ...state,
        totalDemands: action.payload,
      };

    case "@SET_DEMAND_STATUS_SITUATION":
      return {
        ...state,
        demandStatusSituationId: action.payload,
      };
  
    case "@SET_STATUS_ID":
      return {
        ...state,
        statusId: action.payload,
      };

    case "@SET_RESPONSIBLE":
      return {
        ...state,
        responsible: action.payload,
      };

    case "@SET_REGISTERED_PERSON":
      return {
        ...state,
        registeredPerson: action.payload,
      };

    case "@SET_DEMAND_DESCRIPTION":
      return {
        ...state,
        demandDescription: action.payload,
      };
    
    case "@SET_CATEGORIES":
      return {
        ...state,
        categories: action.payload,
      };

    case "@SET_CREATED_BY_USER_AT":
      return {
        ...state,
        createdByUserAt: action.payload,
      };

    case "@SET_PAGE":
      return {
        ...state,
        page: action.payload,
      };

    case "@SET_PAGE_PAGINATION":
      return {
        ...state,
        pagePagination: action.payload,
      };

    case "@SET_FILTERED_APPLIED":
      return {
        ...state,
        filterApplied: action.payload,
      };

    case "@SET_CLEAN_FILTER":
      return {
        ...state,
        demandStatusSituationId: '',
        statusId: '',
        responsible: [],
        registeredPerson: '',
        demandDescription: '',
        categories: [],
        createdByUserAt: '',
        page: 1,
        totalPages: 0,
        filterApplied: false,
        statusSelected: '',
        situationsSelected: [],
        registeredPersonSearch: '',
        demandsPaginate: null,
      };
    default:
      return state;
  }
};


export default usersReducer;
