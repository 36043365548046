import axiosLogger from "../../api/logger";

const listLogs = (
  page,
  perPage,
  action,
  startDate,
  endDate,
  proprietary
) => {
  const data = axiosLogger.get(`log/${proprietary}`, {
    params: {
      currentPage: page,
      perPage: perPage || undefined,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      action: action || undefined,
    },
  });
  return data;
};
export default {
  listLogs,
};
