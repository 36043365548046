import React, { memo } from "react";
import { Box, Flex, IconButton } from "@chakra-ui/react";
import {
  Select as SelectChakra,
} from "chakra-react-select";
import { 
  AiFillCloseCircle, 
  AiFillFilePdf, 
  AiFillFilter, 
  AiOutlineSearch 
} from "react-icons/ai";
import { useDispatch } from "react-redux";

const HeaderInputFiles = ({ 
  loading, 
  search, 
  systemUsers,
  setOpenDrawer,
  clear,
  generateFile
}) => {
  const dispatch = useDispatch();

  return (
    <Flex
      flex={1}
      marginLeft={10}
      marginRight={10}
    >
      <Flex
        w="full"
        justifyContent="center"
        marginTop={8}
        alignItems="center"
        direction={{ base: "column", md: "row" }}
      >
        <Flex w="container.md" marginBottom={{ base: "2", md: "0" }}>
          <Box w='full'>
          <SelectChakra
            isMulti={false}
            pageSize={100}
            chakraStyles={{
              container: (provided) => ({
                ...provided,
                width: "100%",
                backgroundColor: "white",
                borderRadius: 8,
              })
            }}
            placeholder=""
            size="lg"
            isSearchable
            isLoading={loading}
            onChange={(event) => {
              dispatch({
                type: "@SET_REGISTERED_PERSON_SEARCH",
                payload: event
              });
              dispatch({
                type: "@SET_REGISTERED_PERSON",
                payload: event
              });
              // @SET_REGISTERED_PERSON
            }}
            colorScheme="green"
            value={search}
            options={systemUsers.map((item) => {
              return {
                value: item.value,
                label: item.text
              }
            })}
          />
          </Box>
          <IconButton
            isDisabled={!search || loading}
            marginLeft={1}
            bg="white"
            height="44px"
            width="44px"
            borderRadius={6}
            disabled={loading}
            onClick={() => {
              clear();
            }}
            icon={
              search ? <AiFillCloseCircle /> : <AiOutlineSearch />
            }
          />
        </Flex>
        <Flex>
          <IconButton
            w='46px'
            isDisabled={loading}
            bg="gray.200"
            h='46px'
            marginRight={1}
            marginLeft={2}
            onClick={() => setOpenDrawer(true)}
            icon={<AiFillFilter color="#3311DB" />}
          />
          <IconButton
            w='46px'
            bg="gray.200"
            isDisabled={loading}
            h='46px'
            marginRight={1}
            onClick={() => generateFile("one")}
            icon={<AiFillFilePdf color="#3311DB" />}
          />
          <IconButton
            w='46px'
            bg="gray.200"
            isDisabled={loading}
            h='46px'
            marginRight={1}
            onClick={() => generateFile("two")}
            icon={<AiFillFilePdf color="#3311DB" />}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
export default memo(HeaderInputFiles);