import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export default (reducers) => {
  const persistedReducers = persistReducer(
    {
      key: "web_app",
      storage,
      whitelist: ["user", "sideMenuReducer"],
    },
    reducers
  );
  return persistedReducers;
};
