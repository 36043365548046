import { DeleteIcon } from "@chakra-ui/icons";
import { Flex, Icon, IconButton, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import ReactPlayer from "react-player";

const ImagePreView = ({ fileBase64, removeFileList }) => {
  return (
    <Flex marginTop={10} direction="column">
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {fileBase64
          .filter((item) => item.type === "image")
          .map((item) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: 100,
                height: 120,
              }}
            >
              <Image
                marginRight={2}
                borderRadius={2}
                maxH={90}
                bgSize="contain"
                src={item.url}
                alt="doc"
              />
              <IconButton
                height={10}
                marginRight={2}
                onClick={() => removeFileList(item.id)}
                icon={<DeleteIcon color="red" />}
                aria-label=""
              />
            </div>
          ))}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: 20 }}>
        {fileBase64
          .filter((item) => item.type === "audio")
          .map((item) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <ReactPlayer
                url={item.url}
                width="332px"
                height="35px"
                playing={false}
                controls
              />
              <IconButton
                height={10}
                borderRadius={40}
                marginLeft={2}
                onClick={() => removeFileList(item.id)}
                icon={<DeleteIcon color="red" />}
                aria-label=""
              />
            </div>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 20,
        }}
      >
        {fileBase64
          .filter((item) => item.type === "video")
          .map((item, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Stack marginBottom={5}>
                <Icon size="large" color="blue" name="play circle" />
              </Stack>
              <Text>{item.name}</Text>
              <IconButton
                marginBottom={1}
                marginTop={2}
                borderRadius={40}
                marginLeft={2}
                onClick={() => removeFileList(item.id)}
                icon={<DeleteIcon color="red" />}
                aria-label=""
              />
            </div>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 20,
        }}
      >
        {fileBase64
          .filter((item) => item.type === "pdf")
          .map((item, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Stack marginBottom={5}>
                <Icon size="large" color="blue" name="file pdf" />
              </Stack>
              <Text>{item.name}</Text>
              <IconButton
                marginBottom={1}
                marginTop={2}
                borderRadius={40}
                marginLeft={2}
                onClick={() => removeFileList(item.id)}
                icon={<DeleteIcon color="red" />}
                aria-label=""
              />
            </div>
          ))}
      </div>
    </Flex>
  )
}
export default ImagePreView;