import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { memo } from "react";

const Other = ({ data }) => {
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <Flex
      borderWidth="1px"
      borderRadius="lg"
      w={{ sm: '100%', md: '100%' }}
      height={{ sm: '100%', md: '20rem' }}
      direction={{ base: 'column', md: 'row' }}
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'2xl'}
      padding={4}
    >
      <Box marginRight={4}>
        <Image
          borderTopLeftRadius={6}
          borderTopRightRadius={6}
          flex={0.1}
          boxSize="100%"
          objectFit="contain"
          src={
            data?.photo ||
            "https://react.semantic-ui.com/images/wireframe/image.png"
          }
        />
      </Box>
      <Flex direction="column">
        <Text
          fontSize='lg'
          fontWeight='500'
          marginTop={{ base: 4, md: 0 }}
          color={textColor}>
          {data?.name}
        </Text>
        <Flex marginTop={2}>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Foi indicado por:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}>
            {data?.indication ? data?.indication : "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Ocupação:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.occupation ? data?.occupation : "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            E-mail:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.email ? data?.email : "Não informado"}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default memo(Other);