import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";

import PERSISTREDUCER from "./ducks/persist";
import rootReducer from "./ducks/rootReducer";
import rootSaga from "./ducks/rootSaga";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  PERSISTREDUCER(rootReducer),
  applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;
