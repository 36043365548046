export const countryData = [
  {
    name: "Brasil",
    masks: ["(99) 9999-99999", "(99) 9999-9999"],
    ddi: "+55",
  },
  {
    name: "Estados Unidos",
    masks: ["999 999 9999"],
    ddi: "+1",
  },
  {
    name: "Argentina",
    masks: ["9 999 999 9999"],
    ddi: "+54",
  },
  {
    name: "Uruguai",
    masks: ["9999-9999"],
    ddi: "+598",
  },
  {
    name: "Paraguay",
    masks: ["999-999-999-999"],
    ddi: "+595",
  },
];

export const mask = (phone) => {
  if (!phone) {
    return ""
  }
  if (phone.substring(0, 3).includes("595")) {
    const newPhoneFormat = phone
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})/);
    // @ts-ignore
    return `(${newPhoneFormat[1]}) ${newPhoneFormat[2]}-${newPhoneFormat[3]}-${newPhoneFormat[4]} - 🇵🇾`;
  }
  if (phone.length === 13) {
    const newPhoneFormat = phone
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,2})(\d{0,5})(\d{0,4})/);
    // @ts-ignore
    return `(${newPhoneFormat[2]}) ${newPhoneFormat[3]}-${newPhoneFormat[4]} - 🇧🇷`;
  }
  if (phone.length === 12) {
    const newPhoneFormat = phone
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,2})(\d{0,4})(\d{0,4})/);
    // @ts-ignore
    return `(${newPhoneFormat[2]}) ${newPhoneFormat[3]}-${newPhoneFormat[4]} - 🇧🇷`;
  }
  if (phone.length < 10) {
    return "";
  }
  return phone;
};
