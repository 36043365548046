import { Skeleton, Stack } from "@chakra-ui/react";
import React from "react";

const LoadPageView = () => {
  return (
    <Stack>
      <Skeleton 
        margin="10"
        borderRadius="2" 
        height="xl"
      />
    </Stack>
  )
}

export default LoadPageView;