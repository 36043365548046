import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import apiAccount from "../../services/urls/account";
import SideBar from "../../components/@sidebar";
import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  Textarea,
  FormLabel,
  FormControl,
  Select,
  Button,
  useToast,
  IconButton,
  Input,
  Stack,
  Image
} from "@chakra-ui/react";
import { AttachmentIcon, DeleteIcon } from "@chakra-ui/icons";
import { Icon } from "semantic-ui-react";
import { getLegislativeStatus } from "services/urls/legislative_status";
import { createLegislativeProject } from "services/urls/legislative";

const CreateLegislative = () => {
  const toast = useToast();
  const history = useNavigate();
  const { _id, name } = useSelector(
    (state) =>
      state.user
  );
  let shoulRequest = useRef(true);
  const textColor = useColorModeValue("navy.700", "white");

  const [overview, setOverview] = useState("");
  const [projetcName, setProjetcName] = useState("");
  const [status, setStatus] = useState([]);
  const [progressStatusId, setProgressStatusId] = useState("");
  const [progressStatusLabel, setProgressStatusLabel] = useState("");
  const [responsibleSelected, setResponsible] = useState("");
  const [file, setFile] = useState([]);
  const [fileBase64, setFileBase64] = useState([]);
  const [firstHistoric, setFirstHistoric] = useState("");
  const [statusLabel, setStatusLabel] = useState("");
  const [protocolIn, setProtocolIn] = useState("");
  const [projectNumber, setProjectNumber] = useState("");
  const [inCommissionSince, setInCommissionSince] = useState("");
  const [sanctionedIn, setSanctionedIn] = useState("");
  const [filed_under, setFiled_under] = useState("");
  const [opposition_opinion_published, setOpposition_opinion_published] = useState("");
  const [project_approved_and_ent_to_the_executive, setProject_approved_and_ent_to_the_executive] = useState("");

  const [statusSelected, setStatusSelected] = useState("");
  const [loading, setLoading] = useState(false);

  const [situations, setSituations] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [lawNumber, setlawNumber] = useState("");
  const [publishedIn, setPublishedIn] = useState("");

  const toBase64 = (file, id) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let typeFile = "";

        if (file.type.includes("image")) {
          typeFile = "image";
        }
        if (file.type.includes("audio")) {
          typeFile = "audio";
        }
        if (file.type.includes("video")) {
          typeFile = "video";
        }
        if (file.type.includes("pdf")) {
          typeFile = "pdf";
        }
        const isFile = {
          name: file.name,
          id,
          type: typeFile,
          url: reader.result,
        };

        setFileBase64((oldArray) => [...oldArray, isFile]);
        resolve();
      };
      reader.onerror = reject;
    });

  const handleFileChange = (e) => {
    try {
      if (e.target.files) {
        const files = Array.from(e.target.files).map((item) => {
          const id = Math.floor(Math.random() * 1000000000000);
          toBase64(item, id);
          return {
            id,
            path: item,
          };
        });
        setFile((oldArray) => [...oldArray, ...files]);
      }
    } catch (_) { }
  };


  const requestLegislativeStatus = async () => {
    try {
      const response = await getLegislativeStatus();
      setStatus(
        response.data.data.map((item) => {
          return {
            key: item._id,
            text: `${item.status}`,
            value: item._id,
            situations: item.situations,
          };
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar os status.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestAccounts = async () => {
    try {
      const response = await apiAccount.GetAllAccountsActive();
      setAccounts(
        response.data.data.map((item) => {
          return {
            key: item._id,
            text: `${item.name}`,
            value: item._id,
          };
        }));
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar as usuários",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const submit = async () => {
    try {
      if (
        !statusSelected ||
        !progressStatusId ||
        !overview ||
        !firstHistoric ||
        responsibleSelected === "Selecione" ||
        !responsibleSelected) {
        toast({
          title: '',
          description: "Nome de projeto de lei, Status, Histórico, Situação e Responsável são obrigatórios",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (statusLabel === "Protocolado") {
        if (!protocolIn) {
          toast({
            title: '',
            description: "Protocolo em, é obrigatório",
            status: 'warning',
            duration: 5000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
        if (progressStatusLabel === "Revisado (aguardando leitura em sessão)") {
          if (!projectNumber) {
            toast({
              title: '',
              description: "Número do projeto é obrigatório",
              status: 'warning',
              duration: 5000,
              position: "top-right",
              isClosable: true,
            });
            return;
          }
        }
      }
      if (statusLabel === "Em comissões") {
        if (!inCommissionSince && !opposition_opinion_published) {
          toast({
            title: '',
            description: "Na comissão desde, é obrigatórios",
            status: 'warning',
            duration: 5000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
      }

      if (statusLabel === "Lei aprovada") {
        if (!sanctionedIn || !publishedIn || !lawNumber) {
          toast({
            title: '',
            description: "Lei aprovada em, Lei nº: e Lei publicada em, é obrigatório",
            status: 'warning',
            duration: 5000,
            position: "top-right",
            isClosable: true,
          });
          return;
        }
      }
      setLoading(true);

      const formData = new FormData();
      file.map((item) => {
        formData.append(`file`, item.path);
        return item;
      });
      formData.append("name", projetcName);
      formData.append("status", statusSelected);
      formData.append("progress_status_id", progressStatusId);
      formData.append("progress_status_name", progressStatusLabel);
      formData.append("responsible", responsibleSelected);
      formData.append("overview", overview);
      formData.append("active", true);
      formData.append("historic", firstHistoric);
      formData.append("account", _id);
      formData.append("created", moment().format("DD/MM/YYYY [às] HH:mm:ss"));
      formData.append("accountId", _id);
      formData.append("accountName", name);
      formData.append("day", moment().format("DD/MM/YYYY"));
      formData.append("hour", moment().format("HH:mm:ss"));
      formData.append("userAction", "Adicionou");
      formData.append("descriptionUserAction", `${name} adicionou o projeto de lei "${projetcName}"`);
      formData.append("dateTime", moment().format());
      formData.append("in_commission_since", inCommissionSince);
      formData.append("project_number", projectNumber);
      formData.append("sanctioned_in", sanctionedIn);
      formData.append("protocol_in", protocolIn);
      formData.append("lawNumber", lawNumber);
      formData.append("publishedIn", publishedIn);
      formData.append("filed_under", filed_under);
      formData.append("opposition_opinion_published", opposition_opinion_published)
      formData.append("project_approved_and_ent_to_the_executive", project_approved_and_ent_to_the_executive)

      const response = await createLegislativeProject(formData);

      toast({
        title: '',
        description: "Projeto de lei salvo com sucesso!",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      clearStates();
      history(`/vizualizar-projeto-de-lei`, {
        state: {
          legislativeId: response.data.data._id
        }
      })
    } catch (error) {

      setLoading(false);
      toast({
        title: '',
        description: "Algo deu errado, tente novamente",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const clearStates = () => {
    setOpposition_opinion_published("");
    setProgressStatusLabel("");
    setProgressStatusId("");
    setProjetcName("")
    setResponsible("");
    setOverview("");
    setStatusSelected("");
    setFile([]);
    setFileBase64([]);
    setFirstHistoric("");
    setProtocolIn("");
    setInCommissionSince("");
    setProjectNumber("");
    setSanctionedIn("");
    setStatusLabel("");
    setPublishedIn("");
    setlawNumber("");
    setFiled_under("");
    setProject_approved_and_ent_to_the_executive("");
  }

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      requestAccounts();
      requestLegislativeStatus();
    }
  }, []);

  const removeFileList = (it) => {
    setFile(file.filter((item) => item.id !== it));
    setFileBase64(fileBase64.filter((item) => item.id !== it));
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex
        boxShadow="2xl"
        margin={4}
        direction="column"
        backgroundColor="white"
        borderRadius={8}
      >
        <Flex alignItems="center" justifyContent="center" marginTop="8">
          <Text
            color={textColor}
            fontWeight="bold"
          >
            CADASTRO DE PROJETO DE LEI
          </Text>
        </Flex>
        <Flex margin={10} direction="column">
          <br />
          <Flex direction="column">
            <div style={{ width: "100%" }}>
              <FormControl isRequired marginBottom="6">
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Nome do projeto de lei
                </FormLabel>
                <Input
                  required
                  value={projetcName}
                  onChange={(event) =>
                    setProjetcName(event.target.value)
                  }
                  placeholder="..."
                />
              </FormControl>
            </div>
            <div style={{ width: "100%" }}>
              <FormControl isRequired marginBottom="6">
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Resumo do projeto de lei
                </FormLabel>
                <Textarea
                  required
                  rows={5}
                  value={overview}
                  onChange={(event) =>
                    setOverview(event.target.value)
                  }
                  placeholder="..."
                />
              </FormControl>
            </div>
            <FormControl isRequired>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Status
              </FormLabel>
              <Select
                required
                value={statusSelected}
                onChange={(event) => {
                  if (event.target.value === "Selecione") {
                    setSituations([]);
                    setStatusSelected("");
                    return
                  };

                  setProtocolIn("");
                  setInCommissionSince("");
                  setProjectNumber("");
                  setSanctionedIn("");
                  setPublishedIn("");
                  setlawNumber("");
                  setProgressStatusLabel("");
                  setFiled_under("")
                  setOpposition_opinion_published("");
                  setProject_approved_and_ent_to_the_executive("");

                  setStatusLabel(status?.filter((it) => it.key === event.target.value)[0]?.text)
                  setStatusSelected(event.target.value);
                  setSituations([]);
                  setSituations(
                    status
                      ?.filter((it) => it.value === event.target.value)[0]
                      ?.situations.map((item) => {
                        return {
                          key: item._id,
                          text: item.name,
                          value: item._id,
                        };
                      })
                  );
                }}
              >
                <option value={"Selecione"}>Selecione</option>
                {status.map((item) => (
                  <option value={item.value}>{item.text}</option>
                ))}
              </Select>
            </FormControl>
            <br />
            {statusLabel === "Em comissões" && (
              <div>
                <FormControl isRequired marginBottom="6">
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Na comissão desde:
                  </FormLabel>
                  <Input
                    type="date"
                    required
                    value={inCommissionSince}
                    onChange={(event) =>
                      setInCommissionSince(event.target.value)
                    }
                    placeholder="..."
                  />
                </FormControl>
              </div>
            )}
            {statusLabel === "Lei aprovada" && (
              <>
                <FormControl isRequired marginBottom="6">
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Lei sancionada em:
                  </FormLabel>
                  <Input
                    required
                    value={sanctionedIn}
                    onChange={(event) =>
                      setSanctionedIn(event.target.value)
                    }
                    placeholder="..."
                    type="date"
                  />
                </FormControl>
                <FormControl isRequired marginBottom="6">
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Lei nº:
                  </FormLabel>
                  <Input
                    required
                    value={lawNumber}
                    onChange={(event) =>
                      setlawNumber(event.target.value)
                    }
                    placeholder="..."
                    type="text"
                  />
                </FormControl>
                <FormControl isRequired marginBottom="6">
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Lei publicada em:
                  </FormLabel>
                  <Input
                    required
                    value={publishedIn}
                    onChange={(event) =>
                      setPublishedIn(event.target.value)
                    }
                    placeholder="..."
                    type="date"
                  />
                </FormControl>
              </>
            )}
            <FormControl isRequired>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Situação
              </FormLabel>
              <Select
                required
                onChange={(event) => {
                  const value = situations.find((item) => item.value === event.target.value);
                  setProgressStatusLabel(value?.text);
                  setProgressStatusId(event.target.value);
                }}
                value={progressStatusId}
              >
                <option value={""}>Selecione</option>
                {situations.map((item) => (
                  <option value={item.value}>{item.text}</option>
                ))}
              </Select>
            </FormControl>
            {statusLabel === "Arquivada" && (
              <FormControl isRequired marginTop="6">
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Arquivado em:
                </FormLabel>
                <Input
                  required
                  value={filed_under}
                  onChange={(event) =>
                    setFiled_under(event.target.value)
                  }
                  placeholder="..."
                  type="date"
                />
              </FormControl>
            )}
            {progressStatusLabel === "Projeto aprovado e enviado ao executivo" && (
              <FormControl isRequired marginTop="6">
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Aprovado e enviado ao executivo em:
                </FormLabel>
                <Input
                  required
                  value={project_approved_and_ent_to_the_executive}
                  onChange={(event) =>
                    setProject_approved_and_ent_to_the_executive(event.target.value)
                  }
                  placeholder="..."
                  type="date"
                />
              </FormControl>
            )}
            {progressStatusLabel === "Parecer contrário publicado" && (
              <FormControl isRequired marginTop="6">
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Parecer contrário publicado em:
                </FormLabel>
                <Input
                  required
                  value={opposition_opinion_published}
                  onChange={(event) =>
                    setOpposition_opinion_published(event.target.value)
                  }
                  placeholder="..."
                  type="date"
                />
              </FormControl>
            )}
            {progressStatusLabel === "Revisado (aguardando leitura em sessão)" && (
              <FormControl isRequired marginBottom="5" marginTop="8">
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Número do projeto:
                </FormLabel>
                <Input
                  required
                  value={projectNumber}
                  onChange={(event) => {
                    setProjectNumber(event.target.value)
                  }}
                  placeholder="..."
                  type="text"
                />
              </FormControl>
            )}
            {statusLabel === "Protocolado" && (
              <FormControl isRequired marginBottom="6" marginTop="6">
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Protocolado em:
                </FormLabel>
                <Input
                  required
                  type="date"
                  value={protocolIn}
                  onChange={(event) =>
                    setProtocolIn(event.target.value)
                  }
                  placeholder="..."
                />
              </FormControl>
            )}
            <br />
            <FormControl isRequired>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Primeiro histórico
              </FormLabel>
              <Textarea
                value={firstHistoric}
                onChange={(event) =>
                  setFirstHistoric(event.target.value)
                }
                placeholder="..."
              />
            </FormControl>
            <br />
            <FormControl isRequired>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Responsável
              </FormLabel>
              <Select
                value={responsibleSelected}
                onChange={(event) => {
                  setResponsible(event.target.value)
                }}
              >
                <option value={undefined}>Selecione</option>
                {accounts.map((item) => (
                  <option value={item.value}>{item.text}</option>
                ))}
              </Select>
            </FormControl>
            <br />
            <Flex flexDirection="column">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {fileBase64
                  .filter((item) => item.type === "image")
                  .map((item) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: 100,
                        height: 120,
                      }}
                    >
                      <Image
                        marginRight={2}
                        borderRadius={2}
                        maxH={90}
                        bgSize="contain"
                        src={item.url}
                        alt="doc"
                      />
                      <IconButton
                        height={10}
                        marginRight={2}
                        onClick={() => removeFileList(item.id)}
                        icon={<DeleteIcon color="red" />}
                        aria-label=""
                      />
                    </div>
                  ))}
              </div>
              <Flex direction="column">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {fileBase64
                    .filter((item) => item.type === "audio")
                    .map((item) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: 10,
                        }}
                      >
                        <ReactPlayer
                          url={item.url}
                          width="332px"
                          height="35px"
                          playing={false}
                          controls
                        />
                        <IconButton
                          height={10}
                          borderRadius={40}
                          marginLeft={2}
                          onClick={() => removeFileList(item.id)}
                          icon={<DeleteIcon color="red" />}
                          aria-label=""
                        />
                      </div>
                    ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {fileBase64
                    .filter((item) => item.type === "video")
                    .map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: 10,
                        }}
                      >
                        <Stack marginBottom={5}>
                          <Icon size="large" color="blue" name="play circle" />
                        </Stack>
                        <Text>{item.name}</Text>
                        <IconButton
                          marginBottom={1}
                          marginTop={2}
                          borderRadius={40}
                          marginLeft={2}
                          onClick={() => removeFileList(item.id)}
                          icon={<DeleteIcon color="red" />}
                          aria-label=""
                        />
                      </div>
                    ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  {fileBase64
                    .filter((item) => item.type === "pdf")
                    .map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: 10,
                        }}
                      >
                        <Stack marginBottom={5}>
                          <Icon size="large" color="blue" name="file pdf" />
                        </Stack>
                        <Text>{item.name}</Text>
                        <IconButton
                          marginBottom={1}
                          marginTop={1}
                          borderRadius={40}
                          marginLeft={2}
                          onClick={() => removeFileList(item.id)}
                          icon={<DeleteIcon color="red" />}
                          aria-label=""
                        />
                      </div>
                    ))}
                </div>
              </Flex>
            </Flex>
            <Flex>
              <input
                onChange={handleFileChange}
                multiple
                accept="image/png, image/jpeg, video/mp4, video/webm, video/mp3, video/ogv, audio/*, application/pdf"
                type="file"
                id="actual-btn"
                hidden
              />
              <div
                style={{
                  backgroundColor: "#2F8559",
                  color: "white",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  height: 35,
                  paddingRight: 12,
                  paddingLeft: 12,
                  justifyContent: "center",
                  borderRadius: 6,
                }}
              >
                <label
                  style={{ cursor: "pointer" }}
                  // @ts-ignore
                  htmlFor="actual-btn"
                >
                  <AttachmentIcon /> Upload de arquivo
                </label>
              </div>
            </Flex>
            <br />
            <Flex marginTop={4} justifyContent="flex-end">
              <Button
                isLoading={loading}
                colorScheme="facebook"
                onClick={() => submit()}
              >
                Salvar
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
export default CreateLegislative;
