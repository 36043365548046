import { IconButton, Text } from '@chakra-ui/react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";

var React = require('react');
var ReactUltimatePagination = require('react-ultimate-pagination');

function Page(props) {
  return (
    <>
      <IconButton
        w='42px'
        bg={props.isActive ? "gray.600" : "gray.300"}
        h='42px'
        marginRight={1}
        onClick={props.onClick}
        disabled={props.disabled}
        children={
          <Text color={props.isActive ? "white" : "gray.700"}>
            {props.value}
          </Text>
        }
      >
      </IconButton>
    </>
  );
}

function Ellipsis(props) {
  return <button onClick={props.onClick} disabled={props.disabled}>...</button>
}

function FirstPageLink(props) {
  return <IconButton
    w='46px'
    bg={"gray.200"}
    h='46px'
    marginRight={1}
    onClick={props.onClick}
    disabled={props.disabled}
    icon={<BiArrowToLeft />}
  />
}

function PreviousPageLink(props) {
  return <IconButton
    w='46px'
    bg={"gray.200"}
    h='46px'
    marginRight={1}
    onClick={props.onClick}
    disabled={props.disabled}
    icon={<AiOutlineArrowLeft />}
  />
}

function NextPageLink(props) {
  return <IconButton
    w='46px'
    bg={"gray.200"}
    h='46px'
    marginRight={1}
    onClick={props.onClick}
    disabled={props.disabled}
    icon={<AiOutlineArrowRight />}
  />
}

function LastPageLink(props) {
  return <IconButton
    w='46px'
    bg={"gray.200"}
    h='46px'
    marginRight={1}
    onClick={props.onClick}
    disabled={props.disabled}
    icon={<BiArrowToRight />}
  />
}

function Wrapper(props) {
  return <div className="pagination">{props.children}</div>
}

var itemTypeToComponent = {
  'PAGE': Page,
  'ELLIPSIS': Ellipsis,
  'FIRST_PAGE_LINK': FirstPageLink,
  'PREVIOUS_PAGE_LINK': PreviousPageLink,
  'NEXT_PAGE_LINK': NextPageLink,
  'LAST_PAGE_LINK': LastPageLink
};

export const UltimatePagination = ReactUltimatePagination.createUltimatePagination({
  itemTypeToComponent: itemTypeToComponent,
  WrapperComponent: Wrapper
});