import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 135px;
  padding: 0px 15px 0px 14px;
  @media only screen and (max-width: 600px) {
    margin-left: 0px;
  }
`;

export const Divider = styled.div`
  background-color: #eff2f5;
  height: 1px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 125px;
  @media only screen and (max-width: 600px) {
    margin-left: 60px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 10px;
  margin-left: 125px;
  padding: 10px 10px 10px 10px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0px #cecece;

  @media only screen and (max-width: 800px) {
    margin-left: 120px;
    flex-direction: column;
  }

  @media only screen and (max-width: 600px) {
    margin-left: 60px;
  }
`;
export const ContainerData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  @media only screen and (max-width: 800px) {
    width: 99%;
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  @media only screen and (max-width: 800px) {
    width: 99%;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vh;
  min-height: 192px;
  margin-left: 10px;
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  padding-left: 10px;
`;

export const Name = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

export const ContainerUser = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  @media only screen and (max-width: 800px) {
    width: 99%;
  }
`;

export const Avatar = styled.img`
  height: 90px;
  width: 90px;
  border-radius: 100px;
  border: 5px solid #cecece;
  margin-right: 4px;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Space = styled.div`
  height: 6px;
`;
export const ContainerNavigation = styled.div`
  display: flex;
  justify-content: center;
`;
export const ContainerRelation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  flex-wrap: wrap;
`;
export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  background-color: #363636;
  align-items: center;

  div {
    color: white;
    margin-left: 16px;
    margin-right: 16px;
    font-size: 14px;
    font-weight: 700;
  }
`;
export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2px 0px 6px 0px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  box-shadow: 1px 1px 1px 1px #f1f1f1;
`;

export const ContainerImg = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  height: 200px;
  width: 200px;
  margin-right: 20px;
  background-position: center center;
  background-size: cover;
  border-radius: 4px;
`;
export const Boll = styled.div`
  height: 35px;
  border-radius: 6px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  background-color: #15aa39;
  color: white;
  margin: 4px 2px 0px 0px;
  padding: 0px 2px 0px 10px;
  text {
    font-weight: bold;
    color: white;
  }
`;
export const CardPayment = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 6px;
  border-radius: 100%;
  background-color: red;
`;

export const Span = styled.span`
  font-weight: 500;
`