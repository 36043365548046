import React, { memo } from "react";
import PopupComponent from "../popup";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";

const Quantities = ({ 
  situations,
  isLoading, 
  situationsType, 
  isFilter, 
  requestSituationType,
  totalDemand,
  totalFilter,
 }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <div>
      <Flex justifyContent="center" marginBottom="6" marginTop="-2">
        {!isFilter && (
          <Text
            fontSize="md"
            color={textColor}
            fontWeight='700'
          >Total de demandas:
            {' '}
            {totalDemand}
          </Text>
        )}
        {isFilter && (
          <Text
            fontSize="md"
            color={textColor}
            fontWeight='700'
          >Total do filtro: {totalDemand}
          </Text>
        )}
      </Flex>
      <Flex flexWrap="wrap" flexShrink={1} flexBasis={1}>
        <PopupComponent
          disabled={false}
          bgColor={"red.300"}
          statusName={"Recebida"}
          isLoading={isLoading}
          onClick={() => requestSituationType(situations[0]?._id)}
          situationsType={situationsType}
          quantity={situations[0]?.received || 0}
        />
        <PopupComponent
          disabled={false}
          bgColor={"yellow.300"}
          statusName={"Em andamento"}
          isLoading={isLoading}
          onClick={() => requestSituationType(situations[1]?._id)}
          situationsType={situationsType}
          quantity={situations[1]?.inProgress || 0}
        />
        <PopupComponent
          disabled={false}
          bgColor={"blue.300"}
          statusName={"Em aprovação"}
          isLoading={isLoading}
          onClick={() => requestSituationType(situations[2]?._id)}
          situationsType={situationsType}
          quantity={situations[2]?.onApproval || 0}
        />
        <PopupComponent
          disabled={false}
          bgColor={"purple.300"}
          statusName={"Pausada"}
          isLoading={isLoading}
          onClick={() => requestSituationType(situations[3]?._id)}
          situationsType={situationsType}
          quantity={situations[3]?.paused || 0}
        />
        <PopupComponent
          bgColor={"green.200"}
          statusName={"Resolvida"}
          isLoading={isLoading}
          onClick={() => requestSituationType(situations[4]?._id)}
          situationsType={situationsType}
          quantity={situations[4]?.resolved || 0}
        />
        <PopupComponent
          disabled={false}
          statusName={"Cancelada"}
          bgColor={"gray.300"}
          isLoading={isLoading}
          onClick={() => requestSituationType(situations[5]?._id)}
          situationsType={situationsType}
          quantity={situations[5]?.canceled}
        />
      </Flex>
    </div>
  )
}
export default memo(Quantities);