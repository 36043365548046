import {
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import React from "react";

import { MdUpload } from "react-icons/md";
import Dropzone from "views/admin/profile/components/Dropzone";

export default function Upload(props) {
  const { title, subTitle, onChangeFile } = props;

  const brandColor = useColorModeValue("brand.500", "white");
  return (
    <Flex h='165px' direction={{ base: "column", "2xl": "row" }}>
      <Dropzone
        onChange={(event) => onChangeFile(event)}
        accept="image/png, image/jpeg"
        w={{ base: "100%", "2xl": "268px" }}
        me='36px'
        maxH={{ base: "60%", lg: "100%", "2xl": "100%" }}
        minH={{ base: "60%", lg: "100%", "2xl": "100%" }}
        content={
          <Box>
            <Icon as={MdUpload} w='80px' h='100px' color={brandColor} />
            <Flex justify='center' mx='auto' mb='10px'>
              <Text fontSize='xl' fontWeight='700' color={brandColor}>
                {title}
              </Text>
            </Flex>
            <Text marginBottom={4} fontSize='sm' fontWeight='500' color='secondaryGray.500'>
              {subTitle}
            </Text>
          </Box>
        }
      />
    </Flex>
  );
}
