import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AiOutlineEdit, AiFillDelete, AiFillCheckCircle, AiOutlineUser } from "react-icons/ai";

import apiAccounts from "../../services/urls/account";
import SideBar from "components/@sidebar";
import {
  Box,
  Flex,
  Icon,
  Input,
  IconButton,
  Skeleton,
  Stack,
  Checkbox,
  Button,
  useDisclosure,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialog,
  FormControl,
  FormLabel,
  InputGroup,
  useColorModeValue,
  Text,
  useToast
} from "@chakra-ui/react";
import Card from "components/card/CardBith";
import IconBox from "components/icons/IconBox";
import { UltimatePagination } from "components/pagination";

const UsersSytemPage = () => {
  const [pagePagination, setPagePagination] = useState(1);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef();
  let shoulRequest = useRef(true);
  const {
    _id: accountId,
    name: accountName,
    proprietaryid,
  } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [userName, setUserName] = useState("");
  const [emailUser, setemailUser] = useState("");
  const [password, setPassword] = useState("");
  const [modeEdit, setModeEdit] = useState(false);
  const [userId, setUserId] = useState("");
  const [document, setDocument] = useState("");

  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [isMaster, setIsMaster] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const [accounts, setAccounts] = useState([]);

  const requestAccount = async (isPage) => {
    try {
      setLoading(true);
      const response = await apiAccounts.GetAccounts(isPage, perPage);
      const value = page + 1;
      setPagePagination(value);

      setAccounts(
        response?.data?.data?.docs.map(
          (item) => {
            return {
              key: item._id,
              text: item.name,
              value: item._id,
              active: item.active,
              _id: item._id,
              name: item.name,
              email: item.email,
              read: item.read,
              write: item.write,
              isMaster: item.isMaster,
              document: item.document,
            };
          }
        )
      );
      setTotalPages(response?.data?.data?.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: '',
        description: "Erro ao listar as categorias, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestNewUser = async () => {
    try {
      if (!userName) {
        toast({
          title: '',
          description: "Informe o nome.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (userName.length < 3) {
        toast({
          title: '',
          description: "O nome deve conter no minímo 3 caracteres.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoadingSave(true);
      await apiAccounts.saveNewUser({
        document,
        name: userName,
        email: emailUser,
        active: true,
        password,
        isMaster,
        accountId,
        accountName,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Adicionou",
        userId: undefined,
        userName: undefined,
        description: "Cadastrou um novo usuário no sistema",
        dateTime: moment().format(),
        accessToProjects: [proprietaryid],
      });
      setLoadingSave(false);
      setUserName("");
      setPassword("");
      setemailUser("");
      setIsMaster(false);
      onClose();
      toast({
        title: '',
        description: "Usuário adicionado com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      requestAccount(1);
    } catch (error) {
      setLoadingSave(false);
      toast({
        title: '',
        description: "Erro ao cadastrar novo usuário, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestUpdate = async () => {
    try {
      if (!userName) {
        toast({
          title: '',
          description: "Informe o nome.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (userName.length < 3) {
        toast({
          title: '',
          description: "O nome deve conter no minímo 3 caracteres.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoadingSave(true);
      await apiAccounts.updateAccess(
        {
          name: userName,
          email: emailUser,
          password: password || undefined,
          isMaster,
          accountId,
          accountName,
          day: moment().format("DD/MM/YYYY"),
          hour: moment().format("HH:mm:ss"),
          userAction: "Editou",
          userId,
          userName,
          description: "Editou um usuário no sistema.",
          dateTime: moment().format(),
        },
        userId
      );
      setAccounts(
        accounts.map((item) => {
          if (item._id === userId) {
            item.text = userName;
            item.name = userName;
            item.email = emailUser;
            item.isMaster = isMaster;
            return item;
          }

          return item;
        })
      );
      onClose();
      setModeEdit(false);
      setLoadingSave(false);
      setUserName("");
      setPassword("");
      setemailUser("");
      setUserId("");
      toast({
        title: '',
        description: "Informações atualizadas com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      setLoadingSave(false);
      toast({
        title: '',
        description: "Erro ao atualizar usuário, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestInative = async (
    id,
    active,
    userName,
  ) => {
    try {
      if (accountId === id) {
        toast({
          title: '',
          description: "Você não pode bloquear sua própria conta.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      await apiAccounts.updateAccess(
        {
          active: false,
          accountId,
          accountName,
          day: moment().format("DD/MM/YYYY"),
          hour: moment().format("HH:mm:ss"),
          userAction: "Desativou",
          userId: id,
          userName,
          description: `Desativou o usuário "${userName}" no sistema.`,
          dateTime: moment().format(),
          isMaster,
        },
        id
      );
      toast({
        title: '',
        description: "Usuário desativado com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setAccounts(
        accounts.map((item) => {
          if (item._id === id) {
            item.active = !active;
            return item;
          }
          return item;
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao desativar usuário, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const requestAtive = async (
    id,
    active,
    userName,
  ) => {
    try {
      await apiAccounts.updateAccess(
        {
          active: true,
          accountId,
          accountName,
          day: moment().format("DD/MM/YYYY"),
          hour: moment().format("HH:mm:ss"),
          userAction: "Ativou",
          userId: id,
          userName,
          description: `Ativou o usuário "${userName}" no sistema.`,
          dateTime: moment().format(),
        },
        id
      );
      toast({
        title: '',
        description: "Usuário reativado com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setAccounts(
        accounts.map((item) => {
          if (item._id === id) {
            item.active = !active;
            return item;
          }
          return item;
        })
      );
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao reativar usuário, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const prepareData = (data) => {
    setUserName(data.name);
    setemailUser(data.email);
    setModeEdit(true);
    setUserId(data._id);
    onOpen();
    setIsMaster(data.isMaster || false);
  };

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      requestAccount(1);
    }
  }, []);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex padding={10} flexDirection="column">
        {loading ? (
          <Stack>
            <Skeleton borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
          </Stack>
        ) : (
          <Flex direction="column">
            {accounts
              ?.filter((it) => it._id !== accountId)
              .map((item) => (
                <Box marginTop={2}>
                  <Card
                    isUser
                    startContent={
                      <IconBox
                        w='56px'
                        bg="gray.200"
                        h='56px'
                        icon={
                          <Icon
                            w='32px'
                            h='32px'
                            as={AiOutlineUser}
                          />
                        }
                      />
                    }
                    endContent={
                      <Flex>
                        <IconButton
                          w='36px'
                          h='36px'
                          onClick={() => prepareData(item)}
                          color="orange.500"
                          bg={"gray.200"}
                          icon={
                            <Icon
                              as={AiOutlineEdit}
                            />
                          }
                          marginRight={1}
                        />
                        <IconButton
                          w='36px'
                          h='36px'
                          color={item.active === true ? "red.300" : "green.400"}
                          bg={"gray.200"}
                          onClick={() => {
                            if (item.active) {
                              requestInative(
                                item._id,
                                item.active,
                                item.name
                              );
                            } else {
                              requestAtive(
                                item._id,
                                item.active,
                                item.name
                              );
                            }
                          }}
                          icon={
                            item.active === true ? <Icon as={AiFillDelete} /> : <Icon as={AiFillCheckCircle} />
                          }
                        />
                      </Flex>
                    }
                    name={`${item.text}`}
                  />
                </Box>
              ))}
          </Flex>
        )}
        {!loading && (
          <Flex justifyContent="center" marginTop="6">
            <UltimatePagination
              currentPage={page}
              onChange={(p) => {
                requestAccount(p);
                setPage(p);
              }}
              totalPages={totalPages}
            />
          </Flex>
        )}
      </Flex>
      {!loading && (
        <Flex justifyContent="flex-end" paddingRight={10}>
          <Button colorScheme="facebook" onClick={() => onOpen()}>
            Adicionar novo usuário
          </Button>
        </Flex>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setModeEdit(false);
          setUserId("");
          setUserName("");
          setemailUser("");
          setDocument("");
          onClose();
        }}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {modeEdit ? "Editar usuário" : "Novo usuário"}
            </AlertDialogHeader>
            <AlertDialogBody>
              <Flex flexDirection="column">
                <FormControl>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Nome
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      required
                      value={userName}
                      onChange={(event) => setUserName(event.target.value)}
                      fontSize='sm'
                      maxLength={200}
                      mb='24px'
                      size='lg'
                      type={"tel"}
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    E-mail
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      required
                      disabled={!!modeEdit}
                      value={emailUser}
                      onChange={(event) => setemailUser(event.target.value)}
                      fontSize='sm'
                      maxLength={200}
                      mb='24px'
                      size='lg'
                      variant='auth'
                    />
                  </InputGroup>
                </FormControl>
                {!modeEdit && (
                  <>
                    <FormControl>
                      <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'>
                        CPF
                      </FormLabel>
                      <InputGroup size='md'>
                        <Input
                          required
                          value={document}
                          onChange={(event) => setDocument(event.target.value)}
                          fontSize='sm'
                          maxLength={14}
                          mb='24px'
                          size='lg'
                          variant='auth'
                        />
                      </InputGroup>
                    </FormControl>
                  </>
                )}
                <>
                  <FormControl>
                    <FormLabel
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color={textColor}
                      display='flex'>
                      Senha
                    </FormLabel>
                    <InputGroup size='md'>
                      <Input
                        required
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        fontSize='sm'
                        maxLength={11}
                        mb='24px'
                        size='lg'
                        variant='auth'
                      />
                    </InputGroup>
                  </FormControl>
                </>
                <Text>Nível de acesso</Text>
                <Checkbox checked={isMaster} onChange={() => setIsMaster(!isMaster)}>Acesso MASTER</Checkbox>
              </Flex>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                colorScheme="red"
                ref={cancelRef}
                onClick={() => {
                  setModeEdit(false);
                  setUserId("");
                  setUserName("");
                  setemailUser("");
                  setDocument("");
                  onClose();
                }}>
                Fechar
              </Button>
              <Button
                isLoading={loadingSave}
                colorScheme='green'
                onClick={modeEdit ? requestUpdate : requestNewUser}
                ml={3}>
                Salvar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
export default UsersSytemPage;
