/* eslint-disable react-hooks/rules-of-hooks */
import moment from "moment";
import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "../../services/urls/account";
import { Box, Flex, Input, Button, FormControl, FormLabel, InputGroup, useColorModeValue, useToast, Icon, InputRightElement } from "@chakra-ui/react";
import SideBar from "components/@sidebar";

const UpdatePasswordPage = () => {
  const {
    name: reduxName,
    email,
    _id,
  } = useSelector((state) => state.user);
  const toast = useToast();
  const [newpPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const handleClick = () => setShow(!show);
  const textColorSecondary = "gray.400";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [show, setShow] = React.useState(false);

  const requestUpdate = async () => {
    if (!newpPassword) {
      toast({
        title: '',
        description: "Informe a nova senha",
        status: 'warning',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }
    if (newpPassword.length < 6) {
      toast({
        title: '',
        description: "Sua senha deve ter no minímo 6 caracteres.",
        status: 'warning',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    try {
      await axios.updatePassword(
        _id,
        newpPassword,
        _id,
        reduxName,
        moment().format("DD/MM/YYYY"),
        moment().format("HH:mm:ss"),
        "Editou",
        undefined,
        "Atualizou senha de acesso",
        moment().format()
      );
      setLoading(false);
      toast({
        title: '',
        description: "Senha atualizada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });

      setNewPassword("");
    } catch (error) {
      toast({
        title: '',
        description: error?.response?.data?.error,
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex
        flexDirection="column"
        alignItems="center"
      >
        <Flex
          height="4xs"
          backgroundColor="white"
          padding={20}
          marginTop={30}
          boxShadow="2xl"
          width="2xl"
          borderRadius={6}
        >
          <Flex
            marginLeft={10}
            marginRight={10}
            flexDirection='column' w="full">
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Nome
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  required
                  disabled
                  value={reduxName}
                  fontSize='sm'
                  maxLength={200}
                  mb='24px'
                  size='lg'
                  type={"tel"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                E-mail
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  required
                  disabled
                  value={email}
                  fontSize='sm'
                  maxLength={200}
                  mb='24px'
                  size='lg'
                  type={"tel"}
                  variant='auth'
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Nova senha
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  required
                  value={newpPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                  fontSize='sm'
                  maxLength={200}
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
              </InputGroup>
            </FormControl>

            <Button
              variant='brand'
              disabled={loading}
              isLoading={loading}
              onClick={() => requestUpdate()}
            >
              Atualizar
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default UpdatePasswordPage;
