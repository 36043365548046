import { of, groupBy, map, mergeMap, reduce, toArray } from "rxjs";

async function loggerByGroup(data) {
  let payload = []
  of(...data).pipe(
    groupBy((p) => p.dateTime.split('T')[0]),
    mergeMap(group$ =>
      group$.pipe(reduce((acc, cur) => [...acc, cur], [`${group$.key}`]))
    ),
    map(arr => ({ dateTime: arr[0], activities: arr.slice(1) })),
    toArray()
  ).subscribe(p => {
    payload = p.sort(function (a, b) {
      return a.dateTime.localeCompare(b.dateTime)
    });
  });
  return payload;
}
export default loggerByGroup;

export function groupByDateTime(records) {
  const grouped = {};
  
  records.forEach(record => {
    const date = record.dateTime.split('T')[0]
    
    // Verifica se a chave dateTime já existe no objeto grouped
    if (!grouped[date]) {
      // Se não existir, cria uma nova chave com um array contendo o registro atual
      grouped[date] = [record];
    } else {
      // Se já existir, apenas adiciona o registro atual ao array correspondente
      grouped[date].push(record);
    }
  });

  const resultList = Object.keys(grouped).map(date => ({
    date: date,
    records: grouped[date]
  }));

  return resultList;
}