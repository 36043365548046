import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import apiSituationCategory from "../../services/urls/category_situation";
import SideBar from "components/@sidebar";
import { Box, Flex, Button, FormControl, FormLabel, useColorModeValue, Input, useToast } from "@chakra-ui/react";

const SituationCategoryPage = () => {
  const toast = useToast();
  let shoulRequest = useRef(true);
  const { _id: accountId, name: accountName } = useSelector(
    (state) =>
      state.user
  );

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const [categories, setCategories] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);



  const requestNewCategory = async () => {
    try {
      if (!categoryName) {
        toast({
          title: '',
          description: "Informe o nome da categoria.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (categoryName.length < 3) {
        toast({
          title: '',
          description: "O nome deve conter no minímo 3 caracteres.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoadingSave(true);
      await apiSituationCategory.saveSituationCategorie(
        categoryName,
        accountId,
        accountName,
        moment().format("DD/MM/YYYY"),
        moment().format("HH:mm:ss"),
        "Adicionou",
        undefined,
        undefined,
        `Cadastrou uma nova categoria de situações de demandas, categoria ${categoryName}`,
        moment().format()
      );

      setOpenModal(false);
      setLoadingSave(false);
      setCategoryName("");
      toast({
        title: '',
        description: "Categoria adicionada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      setLoadingSave(false);
      toast({
        title: '',
        description: "Erro ao cadastrar categoria, tente novamente.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };



  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
    }
  }, []);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex alignItems="center" justifyContent="center" h="96">
      <Flex
        justifyContent="center"
        alignItems="center"
        padding={6}
        borderRadius={8}
        bg="white"
        boxShadow="2xl" w="3xl">
        <Flex
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Nome da categoria de demanda*
            </FormLabel>
            <Input
              onChange={(event) => setCategoryName(event.target.value)}
              errorBorderColor={emailError ? "red.500" : "none"}
              value={categoryName}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='..'
              mb='24px'
              fontWeight='500'
              size='lg'
            />

            <Button
              fontSize='sm'
              variant='brand'
              isLoading={loadingSave}
              onClick={() => requestNewCategory()}
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'>
              Cadastrar
            </Button>
          </FormControl>
        </Flex>
      </Flex>
      </Flex>
    </Box>
  );
};
export default SituationCategoryPage;
