import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DrawerRigth from "components/drawer_rigth";
import accountApi from '../../services/urls/account/index';
import { AiFillFilter } from "react-icons/ai";
import { StatusColors } from "../../utils/situationColors";
import {
  Select as SelectChakra,
} from "chakra-react-select";

import SideBar from "components/@sidebar";
import {
  Box,
  Flex,
  IconButton,
  Input,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  InputGroup,
  FormLabel,
  FormControl,
  Button,
  Spinner,
  Tag,
  TagLabel,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card";

import PopupComponent from "components/@popup_count";
import { getLegislative } from "services/urls/legislative";
import { getLegislativeStatus } from "services/urls/legislative_status";
import moment from "moment";
import 'moment/locale/pt-br'
import { getLegislativeAmout } from "services/urls/legislative";
import { getLegislativeAmoutStatus } from "services/urls/legislative";
import { getLegislativeAmoutStatusDetail } from "services/urls/legislative";
import { LabelDetail } from "semantic-ui-react";
import { DeleteIcon } from "@chakra-ui/icons";
import { deleteLegislativeProject } from "../../services/urls/legislative";
import _ from "lodash";
import { UltimatePagination } from "components/pagination";
moment.locale('pt-br');

const LegislationPage = () => {
  const toast = useToast();
  let shoulRequest = useRef(true);
  const dispatch = useDispatch();
  const {
    name,
    responsible,
    status,
    progress_status,
    overview,
    startDate,
    endDate,
    isFilter,
    page,
    progress_statusList,
    search,
    legislatives,
    pages,
    isSearch,
  } = useSelector((state) => state.legislationReducer);
  const [isReload, setIsReload] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [statusApi, setStatusApi] = useState([]);
  const [amout, setAmout] = useState(0);

  const [projectId, setProjectId] = useState("");

  const [amoutStatus, setAmoutStatus] = useState({
    recebida: 0,
    emProducao: 0,
    protocolado: 0,
    emComissao: 0,
    sancao: 0,
    leiAprovada: 0,
    arquivada: 0,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loadingProgressStatus, setLoadingProgressStatus] = useState(false);
  const [situationsType, setSituationType] = useState([]);

  const history = useNavigate();
  const { _id, name: userName } = useSelector(
    (state) =>
      state.user
  );

  const [openDrawer, setOpenDrawer] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [loading, setLoading] = useState(false);

  const requestLegislativeFilter = useCallback(async (pageSelected) => {
    try {
      setIsReload(true);
      setLoading(true);

      const response = await getLegislative({
        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
        name: search ? search : name ? name : undefined,
        overview: overview || undefined,
        progress_status: progress_status?.value || undefined,
        responsible: responsible?.value || undefined,
        status: status?.value || undefined,
        currentPage: pageSelected,
      });

      if (response.data?.data?.docs.length > 0) {
        dispatch({
          type: "@SET_LEGISLATIVE_RESPONSE",
          payload: {
            pages: response.data?.data?.totalPages,
            legislatives: response.data?.data?.docs,
            isFilter: true
          }
        })
        dispatch({
          type: "@SET_PAGE",
          payload: {
            page: pageSelected
          },
        })
      }
      setLoading(false);
      setIsReload(false);
    } catch (error) {
      setIsReload(false);
      setLoading(false);
      toast({
        title: '',
        description: "Erro ao listar projetos de lei",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [
    dispatch,
    endDate,
    name,
    overview,
    progress_status,
    responsible,
    search,
    startDate,
    status,
    toast,
  ])

  const loadSearchLegislative = useCallback(
    _.debounce(async (value) => {
      if (value) {
        requestLegislativeSearch()
      }
      else {
        if (isFilter === false) {
          dispatch({
            type: "@SET_LEGISLATIVE_RESPONSE",
            payload: {
              pages: 0,
              legislatives: [],
            }
          })
          setIsReload(true);
          setLoading(true);
          await Promise.all([requestAmout(false), requestAmoutStatus(false)]);
          setIsReload(false);
          setLoading(false);
        }
      }
    }, 600),
    [search, dispatch, isFilter]
  );

  const requestAmout = useCallback(async (isClean) => {
    try {
      const response = await getLegislativeAmout(
        isClean ? undefined : {
          startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
          name: search ? search : name ? name : undefined,
          overview: overview || undefined,
          progress_status: progress_status?.value || undefined,
          responsible: responsible?.value || undefined,
          status: status?.value || undefined,
          currentPage: page,
        }
      );
      setAmout(response.data.data)
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar quantidade de leis",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [endDate, name, overview, page, progress_status, responsible, search, startDate, status, toast])

  const requestAmoutStatus = useCallback(async (isClean) => {
    try {
      const response = await getLegislativeAmoutStatus(
        isClean ? undefined : {
          startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
          name: search ? search : name ? name : undefined,
          overview: overview || undefined,
          progress_status: progress_status?.value || undefined,
          responsible: responsible?.value || undefined,
          status: status?.value || undefined,
          currentPage: page,
        }
      );
      setAmoutStatus(response.data.data)
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar quantidade de status",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [
    endDate,
    name,
    overview,
    page,
    progress_status,
    responsible,
    search,
    startDate,
    status,
    toast,
  ])

  const requestAccounts = useCallback(async () => {
    try {
      const response = await accountApi.GetAllAccounts();
      setAccounts(response.data.data.map((item) => {
        return {
          value: item._id,
          label: item.name
        }
      }));

    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar responsáveis",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])

  const requestStatus = useCallback(async () => {
    try {
      const response = await getLegislativeStatus();
      setStatusApi(response.data.data.map((item) => {
        return {
          value: item._id,
          label: item.status,
          situations: item.situations
        }
      }))
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao listar status",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])

  const requestSituationType = async (id) => {
    try {
      setLoadingProgressStatus(true);
      setSituationType([]);
      const data = await getLegislativeAmoutStatusDetail(
        id,
        {
          startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
          name: name || undefined,
          overview: overview || undefined,
          progress_status: progress_status.value || undefined,
          responsible: responsible?.value || undefined,
          status: status?.value || undefined,
        }
      );
      setSituationType(data.data.data);
      setLoadingProgressStatus(false);
    } catch (_) {
      setLoadingProgressStatus(false);
    }
  };


  const clear = async () => {
    try {
      setIsReload(true);
      dispatch({
        type: "@SET_CLEAN_FILTER_LEGISLATION",
      });
      await Promise.all([
        requestAmoutStatus(true),
        requestAmout(true),
      ]);
      setIsReload(false);
    } catch (error) {
      setIsReload(false);
    }
  };

  const requestLegislativeSearch = useCallback(async () => {
    try {
      setIsReload(true)
      setLoading(true);
      const response = await getLegislative({
        name: search,
        currentPage: page,
      });

      await Promise.all([requestAmout(false), requestAmoutStatus(false)])

      if (response.data?.data?.docs.length > 0) {
        dispatch({
          type: "@SET_LEGISLATIVE_RESPONSE",
          payload: {
            pages: response.data?.data?.totalPages,
            legislatives: response.data?.data?.docs,
          }
        })
      }
    } catch (_) {
      toast({
        title: '',
        description: "Erro ao listar projetos de lei",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
    finally {
      setIsReload(false)
      setLoading(false);
    }
  }, [search, page, requestAmout, requestAmoutStatus, dispatch, toast])

  useEffect(() => {
    loadSearchLegislative(search)
    return () => {
      loadSearchLegislative.cancel()
    }
  }, [isSearch, loadSearchLegislative, search]);

  useEffect(() => {

    if (shoulRequest.current && isSearch === false) {
      shoulRequest.current = false;
      Promise.all([
        requestAccounts(),
        requestStatus(),
        requestAmout(),
        requestAmoutStatus(),
      ])
    }
  }, [isSearch, requestAccounts, requestAmout, requestAmoutStatus, requestStatus]);

  const refetch = useCallback(() => {
    requestAmout();
    requestAmoutStatus();
    requestLegislativeFilter();
  }, [requestAmout, requestAmoutStatus, requestLegislativeFilter])


  const Filter = useCallback(async () => {
    await Promise.all([
      requestLegislativeFilter(1),
      requestAmout(),
      requestAmoutStatus(),
    ]);
  }, [requestAmout, requestAmoutStatus, requestLegislativeFilter])

  const checkButtonDisabled = () => {
    if (
      name ||
      responsible ||
      status ||
      progress_status ||
      overview ||
      startDate ||
      endDate ||
      search
    ) {
      return false;
    }
    return true;
  }

  const renderSituationProgressName = () => {
    const names = [];
    progress_statusList?.map((item) => {
      if (item.value === progress_status?.value) {
        names.push(item)
      }
    });

    if (names.length === 0) {
      return "";
    }
    return `${names
      .map((it) => {
        return ` ${it.label}`;
      })
      .join(",")}`;
  };

  const renderSituationName = () => {
    const names = [];
    statusApi.map((item) => {
      if (item.value === status?.value) {
        names.push(item);
      }
      return item;
    });
    if (names.length === 0) {
      return "";
    }
    return `${names
      .map((it) => {
        return `${it.label}`;
      })
      .join(",")}`;
  };

  const renderAccountsName = () => {
    const names = [];
    accounts.map((item) => {
      if (item.value === responsible?.value) {
        names.push(item);
      }
      return item;
    });
    if (names.length === 0) {
      return "";
    }
    return `${names
      .map((it) => {
        return `${it.label}`;
      })
      .join(", ")}.`;
  };

  const deleteLegislative = async () => {
    try {
      const mandatory = {
        accountId: _id,
        accountName: userName,
        day: moment().format("DD/MM/YYYY"),
        hour: moment().format("HH:mm:ss"),
        userAction: "Excluiu",
        dateTime: moment().format(),
        description: `Excluiu o projeto de lei "${legislatives.data.filter((item) => item._id === projectId)[0].name}"`,
      }
      await deleteLegislativeProject(projectId, mandatory);
      setProjectId("");
      onClose();
      toast({
        description: "Projeto de lei deletado com sucesso",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });

      const newLegislatives = legislatives.data.filter((item) => item._id !== projectId)

      dispatch({
        type: "@SET_LEGISLATIVE",
        payload: {
          legislatives: newLegislatives,
        },
      });
    } catch (error) {
      onClose();
      toast({
        title: '',
        description: "Erro ao deletar projeto de lei.",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    if (shoulRequest.current && !isFilter) {
      shoulRequest.current = false;
      requestAccounts();
      requestStatus();
      requestAmout();
      requestAmoutStatus();
    }
  }, [isFilter, requestAccounts, requestAmout, requestAmoutStatus, requestStatus]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar arquivo
            </AlertDialogHeader>
            <AlertDialogBody>
              Tem certeza que deseja excluir?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose();
                  setProjectId("");
                }}
              >
                Cancelar
              </Button>
              <Button colorScheme="green" onClick={() => deleteLegislative()} ml={3}>
                Deletar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <SideBar />
      <Box
        pt={{ base: "130px", md: "80px", xl: "80px" }}
        margin="6"
      >
        <DrawerRigth
          disabled={checkButtonDisabled()}
          onListener={() => {
            setOpenDrawer(false);
          }}
          onPressSuccess={() => {
            dispatch({
              type: "@SET_LEGISLATIVE_FILTER",
              payload: {
                isFilter: true,
                search: "",
                page: 0,
                pages: 0,
              }
            })
            Filter();
            setOpenDrawer(false);
          }}
          closeDrawer={() => setOpenDrawer(false)}
          onPressCancel={() => {
            dispatch({
              type: "@SET_CLEAN_FILTER_LEGISLATION",
            });
            clear();
          }}
          listener={openDrawer}
          children={
            <>
              <FormControl marginTop={2}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Início
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    required
                    value={moment(startDate).format("YYYY-MM-DD")}
                    onChange={(event) => {
                      dispatch({
                        type: "@SET_START_DATE",
                        payload: {
                          startDate: moment(event.target.value).format(""),
                        },
                      });
                    }}
                    fontSize='sm'
                    maxLength={200}
                    mb='24px'
                    size='lg'
                    type={"date"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
              <FormControl marginTop="-4">
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Fim
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    required
                    value={moment(endDate).format("YYYY-MM-DD")}
                    onChange={(event) => {
                      dispatch({
                        type: "@SET_END_DATE",
                        payload: {
                          endDate: moment(event.target.value).format(""),
                        },
                      });
                    }}
                    fontSize='sm'
                    maxLength={200}
                    mb='24px'
                    size='lg'
                    type={"date"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Nome
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={name}
                    onChange={(event) => {
                      dispatch({
                        type: "@SET_NAME",
                        payload: {
                          name: event.target.value,
                        },
                      });
                    }}
                    fontSize='sm'
                    maxLength={200}
                    mb='4px'
                    size='md'
                    type={"text"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
              <FormControl marginTop={4}>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Resumo do projeto
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    value={overview}
                    onChange={(event) => {
                      dispatch({
                        type: "@SET_OVERVIEW",
                        payload: {
                          overview: event.target.value,
                        },
                      });
                    }}
                    fontSize='sm'
                    maxLength={200}
                    mb='4px'
                    size='md'
                    type={"text"}
                    variant='auth'
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Responsável
                </FormLabel>
                <SelectChakra
                  isMulti={false}
                  isClearable
                  placeholder="Selecione"
                  onChange={(event) => {
                    if (event === null) {
                      dispatch({
                        type: "@SET_RESPONSIBLE",
                        payload: {
                          responsible: "",
                        },
                      });
                    }
                    else {
                      dispatch({
                        type: "@SET_RESPONSIBLE",
                        payload: {
                          responsible: event,
                        },
                      });
                    }
                  }}
                  colorScheme="green"
                  value={responsible}
                  options={accounts}
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Status
                </FormLabel>
                <SelectChakra
                  isMulti={false}
                  isClearable
                  placeholder="Selecione"
                  onChange={(event) => {
                    if (!event) {
                      dispatch({
                        type: "@SET_STATUS",
                        payload: {
                          status: "",
                        },
                      });
                      dispatch({
                        type: "@SET_PROGRESS_STATUS_LIST",
                        payload: {
                          progress_statusList: [],
                        },
                      });
                      dispatch({
                        type: "@SET_PROGRESS_STATUS",
                        payload: {
                          progress_status: "",
                        },
                      });
                      return;
                    };
                    dispatch({
                      type: "@SET_STATUS",
                      payload: {
                        status: event,
                      },
                    });
                    dispatch({
                      type: "@SET_PROGRESS_STATUS_LIST",
                      payload: {
                        progress_statusList: [],
                      },
                    });
                    dispatch({
                      type: "@SET_PROGRESS_STATUS",
                      payload: {
                        progress_status: "",
                      },
                    });
                    dispatch({
                      type: "@SET_PROGRESS_STATUS_LIST",
                      payload: {
                        progress_statusList: statusApi
                          ?.filter((it) => it.value === event.value)[0]
                          ?.situations.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                          .map((item) => {
                            return {
                              key: item._id,
                              label: item.name,
                              value: item._id,
                            };
                          }),
                      },
                    });
                  }}
                  colorScheme="green"
                  value={status}
                  options={statusApi}
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  ms='4px'
                  marginTop={2}
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Situação
                </FormLabel>
                <SelectChakra
                  isClearable
                  isMulti={false}
                  placeholder="Selecione"
                  onChange={(event) => {
                    if (!event) {
                      dispatch({
                        type: "@SET_PROGRESS_STATUS",
                        payload: {
                          progress_status: "",
                        },
                      });
                    }
                    else {
                      dispatch({
                        type: "@SET_PROGRESS_STATUS",
                        payload: {
                          progress_status: event,
                        },
                      });
                    }
                  }}
                  colorScheme="green"
                  value={progress_status}
                  options={progress_statusList}
                />
              </FormControl>
              <br />
              <br />
            </>
          }
        />
        <Flex alignItems="center" justifyContent="center" marginTop="8">
          <Text
            color={textColor}
            fontWeight="bold">
            LISTAGEM DE PROJETOS DE LEI
          </Text>
        </Flex>
        <Flex
          direction="column"
          padding={4}>
          <Flex alignItems="center" justifyContent="center">
            <Flex w="5xl" justifyContent="center" marginTop={6} alignItems="center">
              <Input
                value={search}
                bg="white"
                onChange={(event) => {
                  dispatch({
                    type: "@SET_SEARCH",
                    payload: {
                      search: event.target.value,
                      isSearch: !!event.target.value
                    },
                  });
                  
                }}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder="Pesquise pelo nome do projeto de lei"
                mb='24px'
                fontWeight='500'
                size='lg'
              />
            </Flex>
            <IconButton
              w='46px'
              isDisabled={loading}
              bg="gray.200"
              h='46px'
              marginRight={1}
              marginLeft={2}
              onClick={() => setOpenDrawer(true)}
              icon={<AiFillFilter color="#3311DB" />}
            />
          </Flex>
          {isReload && (
            <Flex justifyContent="center" alignItems="center" w="full">
              <Spinner />
            </Flex>
          )}
          {!isReload && (
            <Flex direction="column">
              <Flex direction="column" justifyContent="center" alignItems="center" marginBottom="8">
                {!isFilter && (
                  <Flex marginBottom="6">
                    <Text
                      fontSize="md"
                      color={textColor}
                      fontWeight='700'
                      marginRight="1"
                    >Total de projetos de lei:
                    </Text>
                    <Text
                      fontSize="md"
                      color={textColor}
                    >{amout}
                    </Text>
                  </Flex>
                )}
                {isFilter && (
                  <Flex marginBottom="6">
                    <Text
                      fontSize="md"
                      color={textColor}
                      fontWeight='700'
                      marginRight="1"
                    >Total do filtro:
                    </Text>
                    <Text
                      fontSize="md"
                      color={textColor}
                    >{amout}
                    </Text>
                  </Flex>
                )}
                <Flex flexWrap="wrap">
                  <PopupComponent
                    disabled={amoutStatus.recebida === 0}
                    bgColor={"red.300"}
                    statusName={"Recebida"}
                    isLoading={loadingProgressStatus}
                    onClick={() => requestSituationType("64af72a7970ef2ed6dd67fc5")}
                    situationsType={situationsType}
                    quantity={amoutStatus.recebida}
                  />
                  <PopupComponent
                    disabled={amoutStatus.emProducao === 0}
                    bgColor={"yellow.300"}
                    statusName={"Em produção"}
                    isLoading={loadingProgressStatus}
                    onClick={() => requestSituationType("64af733b970ef2ed6dd67fc6")}
                    situationsType={situationsType}
                    quantity={amoutStatus.emProducao}
                  />
                  <PopupComponent
                    disabled={amoutStatus.protocolado === 0}
                    bgColor={"blue.300"}
                    statusName={"Protocolado"}
                    isLoading={loadingProgressStatus}
                    onClick={() => requestSituationType("64af7427970ef2ed6dd67fcb")}
                    situationsType={situationsType}
                    quantity={amoutStatus.protocolado}
                  />
                  <PopupComponent
                    disabled={amoutStatus.emComissao === 0}
                    bgColor={"purple.300"}
                    statusName={"Em comissões"}
                    isLoading={loadingProgressStatus}
                    onClick={() => requestSituationType("64af7455970ef2ed6dd67fcc")}
                    situationsType={situationsType}
                    quantity={amoutStatus.emComissao}
                  />
                  <PopupComponent
                    disabled={amoutStatus.sancao === 0}
                    bgColor={"orange.300"}
                    statusName={"Em trâmite para sanção"}
                    isLoading={loadingProgressStatus}
                    onClick={() => requestSituationType("64af7483970ef2ed6dd67fcf")}
                    situationsType={situationsType}
                    quantity={amoutStatus.sancao}
                  />
                  <PopupComponent
                    disabled={amoutStatus.leiAprovada === 0}
                    statusName={"Lei aprovada"}
                    bgColor={"green.200"}
                    isLoading={loadingProgressStatus}
                    onClick={() => requestSituationType("64b841f7e45514d88a639d2c")}
                    situationsType={situationsType}
                    quantity={amoutStatus.leiAprovada}
                  />
                  <PopupComponent
                    disabled={amoutStatus.arquivada === 0}
                    statusName={"Arquivada"}
                    bgColor={"gray.300"}
                    isLoading={loadingProgressStatus}
                    onClick={() => requestSituationType("64b8425ae45514d88a639d2e")}
                    situationsType={situationsType}
                    quantity={amoutStatus.arquivada}
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
          {(isFilter && !loading) && (
            <LabelDetail
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <strong style={{ marginRight: 4 }}>Filtros:</strong>
              {name && (
                <Flex
                  bgColor="#dddddd"
                  borderRadius="full"
                  paddingLeft="4"
                  paddingRight="4"
                  paddingBottom="1"
                  paddingTop="1"
                  marginRight="1"
                >
                  <text style={{ marginRight: 4 }}>
                    {`${"  "}${name}${name ? "." : ""}` || ""}
                  </text>
                </Flex>
              )}
              {overview && (
                <Flex
                  bgColor="#dddddd"
                  borderRadius="full"
                  paddingLeft="4"
                  paddingRight="4"
                  paddingBottom="1"
                  paddingTop="1"
                  marginRight="1"
                >
                  <text style={{ marginRight: 4 }}>
                    {`${"  "}${overview}${overview ? "." : ""}` || ""}
                  </text>
                </Flex>
              )}
              {renderAccountsName() && (
                <Flex
                  bgColor="#dddddd"
                  borderRadius="full"
                  paddingLeft="4"
                  paddingRight="4"
                  paddingBottom="1"
                  paddingTop="1"
                  marginRight="1"
                >
                  <text style={{ marginRight: 4 }}>{renderAccountsName()}</text>
                </Flex>
              )}
              {renderSituationName() && (
                <Flex
                  bgColor="#dddddd"
                  borderRadius="full"
                  paddingLeft="4"
                  paddingRight="4"
                  paddingBottom="1"
                  paddingTop="1"
                  marginRight="1"
                >
                  <text style={{ marginRight: 4 }}>
                    {renderSituationName()}
                  </text>
                </Flex>
              )}
              {renderSituationProgressName() && (
                <Flex
                  bgColor="#dddddd"
                  borderRadius="full"
                  paddingLeft="4"
                  paddingRight="4"
                  paddingBottom="1"
                  paddingTop="1"
                  marginRight="1"
                >
                  <text style={{ marginRight: 4 }}>
                    {renderSituationProgressName()}
                  </text>
                </Flex>
              )}
              {startDate && (
                <Flex
                  bgColor="#dddddd"
                  borderRadius="full"
                  paddingLeft="4"
                  paddingRight="4"
                  paddingBottom="1"
                  paddingTop="1"
                >
                  <text style={{ marginRight: 4 }}>
                    {moment(startDate).format("DD/MM/YYYY")}
                  </text>
                </Flex>
              )}

              {endDate && (
                <Flex
                  bgColor="#dddddd"
                  borderRadius="full"
                  paddingLeft="4"
                  paddingRight="4"
                  paddingBottom="1"
                  paddingTop="1"
                  marginRight="1"
                >
                  <text style={{ marginRight: 4 }}>
                    {moment(endDate).format("DD/MM/YYYY")}
                  </text>
                </Flex>
              )}
            </LabelDetail>
          )}
          <Flex flexDirection="column" marginTop="10">
            {loading ? (
              <Stack>
                <Skeleton marginTop="2" borderRadius={15} height='80px' />
                <Skeleton marginTop="2" borderRadius={15} height='80px' />
                <Skeleton marginTop="2" borderRadius={15} height='80px' />
                <Skeleton marginTop="2" borderRadius={15} height='80px' />
              </Stack>
            ) : (
              <>
                {(legislatives?.length === 0 && isFilter) ? (
                  <Flex alignItems="center" justifyContent="center">
                    <Text
                      marginBottom={2}
                      color={textColor}
                      fontSize="md"
                      fontWeight='700'
                      marginRight={2}
                      lineHeight='100%'>
                      Sem resultados
                    </Text>
                  </Flex>
                ) : (
                  legislatives?.map((item) => (
                    <Card
                      marginBottom={4}
                      boxShadow="lg"
                      cursor="pointer"
                      children={
                        <Flex key={item._id} direction={{ base: 'column-reverse', md: 'row' }}>
                          <Flex
                            w="full"
                            direction={{ base: 'column', md: 'row' }} flex={2}
                            onClick={() => history("/vizualizar-projeto-de-lei", {
                              state: {
                                legislativeId: item._id
                              }
                            })}
                          >
                            <Flex w="full" direction={{ base: 'column', md: 'row' }}>
                              <Flex
                                w="full"
                                direction="column"
                                marginTop={{ base: 4, md: 0 }}
                              >
                                <Flex>
                                  <Text
                                    marginBottom={4}
                                    color={textColor}
                                    fontSize="md"
                                    marginRight={2}
                                    lineHeight='100%'>
                                    {moment(item?.created).format("DD/MM/YYYY")}
                                  </Text>
                                </Flex>
                                <Flex maxW="3xl">
                                  <div style={{ whiteSpace: "pre-line", color: "#1B2559", marginBottom: 12 }}>
                                    <span
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 700,
                                        marginRight: 4,
                                      }}>
                                      Nome do projeto de lei:
                                    </span>
                                    <text>
                                      {item.name}
                                    </text>
                                  </div>
                                </Flex>
                                <Flex
                                  flexDirection="row"
                                  alignItems="center"
                                  maxW="5xl"
                                  marginBottom="4"
                                >
                                  <div style={{ whiteSpace: "pre-line", color: "#1B2559", }}>
                                    <span
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 700,
                                        marginRight: 4,
                                      }}>
                                      Último histórico em {moment(item?.historic?.created).format("DD/MM/YYYY")}:
                                    </span>
                                    <text>
                                      {item?.historic?.description}
                                    </text>
                                  </div>
                                </Flex>
                                <Flex flexDirection="row" alignItems="center">
                                  <Text
                                    color={textColor}
                                    fontSize="md"
                                    fontWeight='700'
                                    marginRight={2}
                                    lineHeight='100%'>
                                    Responsável:
                                  </Text>
                                  <Text
                                    fontSize="md"
                                    lineHeight='100%'
                                  >
                                    {item?.responsible?.name || "--"}
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                          <Flex
                            w="full"
                            direction={{ base: 'row', md: 'column' }}
                            flex="0.5"
                            alignItems={{ base: "flex-start", md: "flex-end" }}
                            justifyContent={{ base: "space-between", md: "flex-end" }}
                          >
                            <Flex
                              direction="column"
                              marginBottom={{ sm: 4, md: 0 }}
                              justifyContent="flex-end"
                              alignItems={{ base: "flex-start", md: "flex-end" }}
                            >
                              <Tag
                                justifyContent="center"
                                alignItems="center"
                                size='lg'
                                width="max"
                                bgColor={StatusColors(item?.status?.status)}
                                borderRadius={{ base: '6', md: 'full' }}
                              >
                                <TagLabel>{item?.status?.status}</TagLabel>
                              </Tag>
                              <Text
                                color={textColor}
                                fontSize="md"
                                marginRight="1"
                                fontWeight="500"
                              >
                                {item?.progress_status?.name}
                              </Text>
                            </Flex>
                            <IconButton
                              width="10"
                              height="10"
                              marginLeft={10}
                              marginTop={4}
                              onClick={() => {
                                onOpen();
                                setProjectId(item._id)
                              }}
                              icon={<DeleteIcon color="red.600" />}
                            />
                          </Flex>
                        </Flex>
                      }
                    />
                  ))
                )}
              </>
            )}

            {(!loading && pages > 0) && (
              <Flex justifyContent="center" marginTop="8" marginBottom="0">
                {pages > 0 && (
                  <UltimatePagination
                    currentPage={page}
                    onChange={(value) => {
                      requestLegislativeFilter(value)
                    }}
                    totalPages={pages}
                  />
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
export default LegislationPage;
