import { all, takeLatest } from "redux-saga/effects";

import { signIn } from "./auth/sagas";

export default function* rootSaga() {
  return yield all([
    // @ts-ignore
    takeLatest("@LOGIN/SIGN_IN_REQUEST", signIn),
  ]);
}
