import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import React from "react";

const GlobalModal = ({ isOpen, onClose, children }) => {
  return (
    <Modal size="xl" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody paddingBottom={4}>
          <Box height={10} />
          {children}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='red' onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default GlobalModal