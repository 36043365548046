import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 145px;
  @media only screen and (max-width: 600px) {
    margin-left: 50px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  width: 89%;
  margin-bottom: 40px;
  margin-top: 10px;
  margin-left: 115px;
  padding: 30px 40px 20px 40px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0px #cecece;
  @media only screen and (max-width: 600px) {
    margin-left: 0px;
  }
`;
export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
`;
export const MarginTop = styled.div`
  margin-top: 6px;
`;
export const ItemList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 100px;
  width: 100%;
  margin: 0px 15px 0px 0px;
  border-radius: 6px;
  border: 0.2px solid #cecece;
  box-shadow: 1px 1px 1.1px 0.1px #cecece;
  margin-bottom: 10px;
  cursor: pointer;
  :hover {
    background-color: whitesmoke;
  }
`;
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 90px;
  align-items: center;
  width: 100%;
`;
export const ContainerImage = styled.div`
  display: flex;
  height: 180px;
  max-width: 280px;

  img {
    border-end-start-radius: 6px;
    border-top-left-radius: 6px;
  }
`;
export const ContainerNavigation = styled.div`
  display: flex;
  justify-content: center;
`;
export const PaddingText = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;

  small {
    font-size: 12px;
  }
`;
export const ContainerButton = styled.div`
  margin-bottom: 4px;
`;
export const ImageAvatar = styled.img`
  height: 140px;
  width: 200px;
  background-size: cover;
  border-radius: 4px;
`;
export const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  border-color: #dededf;
  padding: 10px;
`;
export const BoxRigth = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid #dededf;
  padding: 10px;
  background-color: #363636;
  position: fixed;
  height: 97%;
  right: 0px;
  top: 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;
export const BoxRigthHeader = styled.div`
  display: flex;
  width: 100%;
`;
export const Margin = styled.div`
  margin-top: 8px;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
`;
export const Handle = styled.div`
  border-radius: 12px;
  padding: 4px 10px;
  background-color: #dddddd;
  margin-right: 4px;
`;
