import { Box, Card, Flex, Tag, TagLabel, Text, useColorModeValue } from "@chakra-ui/react";
import TextExpanded from "components/@textExpanded";
import moment from "moment";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { situationColors } from "utils/situationColors";

const Demands = ({ demands }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const history = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
  }

  const handleRightClick = (e) => {
    if (e.button === 1) {
      e.preventDefault();
      window.open(e.target.href, '_blank');
    }
  };

  const handleNavigation = (id) => {
    history(
      "/detalhe-da-demanda",
      {
        state: {
          userDemandId: id,
        }
      },
    );
  }

  return (
    <>
      {demands.map((item) => (
        <a
          key={item._id}
          rel="noopener noreferrer"
          href={`detalhe-da-demanda/${item._id}`}
          onClick={(e) => {
            handleClick(e);
          }}
          onContextMenu={(event) => {
            handleRightClick(event)
          }}
        >
          <Box 
            cursor="pointer" 
            marginTop={4}
          >
            <Card
              borderRadius="12"
              paddingLeft="12"
              paddingTop="2"
              paddingBottom="4"
              paddingRight="8"
              onClick={() => handleNavigation(item._id)}
              children={
                <Flex flexDirection="column" flex={1}>
                  <Flex justifyContent="space-between" direction={{ sm: 'column-reverse', md: 'row' }}>
                    <Flex marginBottom="0" flexDirection="row" alignItems="center">
                      <Text
                        fontSize="md"
                        lineHeight='100%'
                      >
                        {moment(item?.createdByUserAt).format("DD/MM/YYYY")} | {item?.registeredPerson?.name}
                      </Text>
                    </Flex>
                    <Flex
                      direction="column"
                      marginBottom={{ sm: 4, md: 0 }}
                      justifyContent="flex-end"
                      alignItems={{ base: "flex-start", md: "flex-end" }}>
                      <Tag size='lg' bgColor={situationColors(item?.demandStatus?.name)} borderRadius='full'>
                        <TagLabel>{item?.demandStatus?.name}</TagLabel>
                      </Tag>
                      <Text
                        color={textColor}
                        fontSize="md"
                        marginRight="1"
                        fontWeight="500"
                      >
                        {item?.demandStatusSituation?.name || "Sem situações de demanda"}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex maxW="5xl" marginBottom="3">
                    <TextExpanded
                      key={item._id}
                      textLabel="Demanda:"
                      text={item?.demandDescription}
                      textSize={300}
                      onClickReadMore={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </Flex>
                  <Flex maxW="5xl" marginBottom="3">
                    <TextExpanded
                      key={item._id}
                      textLabel={` Último histórico em ${moment(item?.demand?.created).format("DD/MM/YYYY")}:`}
                      text={item?.theLastDemandHistoric?.demandHistoric|| "Não há histórico"}
                      textSize={350}
                    />
                  </Flex>
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    marginBottom="3"
                  >
                    <Text
                      color={textColor}
                      fontSize="md"
                      fontWeight='700'
                      marginRight={2}
                      lineHeight='100%'>
                      Responsável:
                    </Text>
                    <Text
                      fontSize="md"
                      lineHeight='100%'
                    >
                      {item.responsible ? item.responsible.name : "--"}
                    </Text>
                  </Flex>
                  {(item?.sendDoc || item?.sendMidia || item?.hasReturn) && (
                    <>
                      <Flex>
                        <Text
                          fontSize='md'
                          fontWeight='600'
                          color={textColor}
                        >Enviou algum documento ao solicitante:</Text>
                        <Text marginLeft={2}>{item?.sendDoc}</Text>
                      </Flex>
                      <Flex>
                        <Text
                          fontSize='md'
                          fontWeight='600'
                          color={textColor}
                        >Enviou alguma mídia/arte ao solicitante:</Text>
                        <Text marginLeft={2}>{item?.sendMidia}</Text>
                      </Flex>
                      <Flex marginBottom="4">
                        <Text
                          fontSize='md'
                          fontWeight='600'
                          color={textColor}
                        >Deu retorno ao solicitante:</Text>
                        <Text marginLeft={2}>{item?.hasReturn}</Text>
                      </Flex>
                    </>
                  )}
                </Flex>
              }
            />
          </Box>
        </a>
      ))}
    </>
  )
}
export default memo(Demands);