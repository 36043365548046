import React from "react";
import ReactDOM from "react-dom/client";
import "assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "video-react/dist/video-react.css";

import Store, { persistor } from "./store/index";
import { ToastContainer } from "react-toastify";
import AuthProvider from './context';
import App from "app";

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <ToastContainer />
        <Provider store={Store}>
          <PersistGate persistor={persistor}>
            <ThemeEditorProvider>
              <App />
            </ThemeEditorProvider>
          </PersistGate>
        </Provider>
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>,
);
