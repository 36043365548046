import React, { useEffect, useRef } from "react"
import { useDisclosure, Button, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Stack, DrawerFooter, Text } from "@chakra-ui/react"

function DrawerRigth({
  children,
  onPressSuccess,
  onPressCancel,
  listener,
  onListener,
  closeDrawer,
  disabled,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firstField = useRef()

  useEffect(() => {
    if (listener === true) {
      onOpen();
    }
  }, [listener]);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement='right'
        initialFocusRef={firstField}
        onClose={() => {
          onListener && onListener();
          onClose();
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>
            <Text
              color="facebook.800"
              fontSize="md"
              fontWeight='700'
              lineHeight='100%'>
              Filtro
            </Text>
          </DrawerHeader>
          <DrawerBody>
            <Stack>
              {children}
            </Stack>
          </DrawerBody>
          <DrawerFooter justifyContent="space-between" borderTopWidth='1px'>
          <Button colorScheme="red" mr={3} onClick={() => {
              closeDrawer && closeDrawer();
              onClose();
            }}>
              Fechar
            </Button>
            <Button variant='outline' mr={3} onClick={() => {
              onPressCancel && onPressCancel();
            }}>
              Limpar
            </Button>
            <Button
              isDisabled={disabled}
              onClick={() => {
                onClose();
                onPressSuccess && onPressSuccess()
              }}
              colorScheme='facebook'>Filtrar</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
export default DrawerRigth;