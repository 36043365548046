export function convertImageToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}
export function base64ToFile(base64String, fileName) {
  // Divida o base64 em partes, separando os metadados da parte binária
  const arr = base64String.split(',');
  
  // Obtenha o mime type
  const mime = arr[0].match(/:(.*?);/)[1];
  
  // Converta a parte binária em um array de bytes
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  
  // Crie e retorne um novo objeto File
  return new File([u8arr], fileName, { type: mime });
}