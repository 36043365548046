import React from "react";

import { Avatar, Flex, IconButton, Text, useColorModeValue } from "@chakra-ui/react";
import { AiFillDelete } from "react-icons/ai";

const AvatarCustom = ({
  src,
  id,
  name,
  text,
  relation,
  action,
  isShowIcon,
  navigate,
}) => {
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <Flex
      marginRight={2}
      direction="column"
      style={{ cursor: navigate ? "pointer" : undefined }}
      onClick={() => navigate && navigate()}
    >
      <Flex>
        <Flex>
          <Avatar
            size="2xl"
            objectFit="cover"
            src={
              src || "https://react.semantic-ui.com/images/wireframe/image.png"
            }
          />
        </Flex>
        {!isShowIcon && (
          <IconButton
            marginLeft="-8"
            size="sm"
            colorScheme="red"
            icon={<AiFillDelete />}
            onClick={() => action(id)}
          />
        )}
      </Flex>
      {!isShowIcon && (
        <Flex
          direction="column"
          marginLeft={4}
          maxW={130}
        >
          <Text
            fontSize='md'
            fontWeight='500'
            color={textColor}
          >
            {name}
          </Text>
          <Text
            fontSize='md'
            color={textColor}
          >
            {relation}
          </Text>
        </Flex>
      )}
      {isShowIcon && (
        <Flex
          marginTop={2}
          direction="column"
          maxW={140}
        >
          <Text
            fontSize='md'
            fontWeight='500'
            color={textColor}
          >
            {name}
          </Text>
          <Text
            fontSize='md'
            fontWeight='500'
            color={textColor}
          >
            {text}
          </Text>
          <Text
            fontSize='md'
            color={textColor}
          >
            {relation}
          </Text>

        </Flex>
      )}
    </Flex>
  );
};
export default AvatarCustom;
