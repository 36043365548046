const INITIAL_STATE  = {
  signed: false,
  name: "",
  _id: "",
  email: "",
  loading: false,
  isError: false,
  isMaster: false,
  proprietaryid: "",
};

const userReducer = (state = INITIAL_STATE, action ) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        name: action.payload.name,
        _id: action.payload._id,
        isMaster: action.payload.isMaster,
        email: action.payload.email,
        signed: true,
        loading: false,
        proprietaryid: action.payload.proprietaryid,
      };
    case "SET_LOADING_LOGIN":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_ERROR_MESSAGE":
      return {
        ...state,
        errorMessage: action.payload,
        isError: true,
      };
    case "SET_IS_ERROR":
      return {
        ...state,
        loading: false,
        isError: action.payload,
      };
    case "@SET_ACCESS":
      return {
        ...state,
        read: action.payload.read,
        write: action.payload.write,
        isMaster: action.payload.isMaster,
      };
    case "SET_CLEAN_USER":
      return {
        ...state,
        signed: false,
        name: "",
        _id: "",
        read: false,
        write: false,
        isMaster: false,
        email: "",
        loading: false,
        isError: false,
        proprietaryid: "",
      };
    default:
      return state;
  }
};
export default userReducer;
