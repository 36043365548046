export const relationsSelect = [
  {
    key: 0,
    value: 0,
    text: "Tio(a)",
    type: { type: "Tio(a)", relation: "Sobrinho(a)", key: 0 },
  },
  {
    key: 1,
    value: 1,
    text: "Pai",
    type: { type: "Pai", relation: "Filho(a)", key: 1 },
  },
  {
    key: 2,
    value: 2,
    text: "Mãe",
    type: { type: "Mãe", relation: "Filho(a)", key: 2 },
  },
  {
    key: 3,
    value: 3,
    text: "Avó/Avô",
    type: { type: "Avó/Avô", relation: "Neto(a)", key: 3 },
  },
  {
    key: 4,
    value: 4,
    text: "Neto(a)",
    type: { type: "Neto(a)", relation: "Avó/Avô", key: 4 },
  },
  {
    key: 5,
    value: 5,
    text: "Cunhado(a)",
    type: { type: "Cunhado(a)", relation: "Cunhado(a)", key: 5 },
  },
  {
    key: 6,
    value: 6,
    text: "Concunhado(a)",
    type: { type: "Concunhado(a)", relation: "Concunhado(a)", key: 6 },
  },
  {
    key: 7,
    value: 7,
    text: "Primo(a)",
    type: { type: "Primo(a)", relation: "Primo(a)", key: 7 },
  },
  {
    key: 8,
    value: 8,
    text: "Sobrinho(a)",
    type: { type: "Sobrinho(a)", relation: "Tio(a)", key: 8 },
  },

  {
    key: 9,
    value: 9,
    text: "Filho(a)",
    type: { type: "Filho(a)", relation: "Pai/Mãe", key: 9 },
  },
  {
    key: 10,
    value: 10,
    text: "Irmã",
    type: { type: "Irmã", relation: "Irmão", key: 10 },
  },
  {
    key: 11,
    value: 11,
    text: "Irmão",
    type: { type: "Irmão", relation: "Irmã", key: 11 },
  },
  {
    key: 12,
    value: 12,
    text: "Esposo(a)",
    type: { type: "Esposo(a)", relation: "Esposo(a)", key: 12 },
  },
  {
    key: 13,
    value: 13,
    text: "Namorado(a)",
    type: { type: "Namorado(a)", relation: "Namorado(a)", key: 13 },
  },
  {
    key: 14,
    value: 14,
    text: "Genro",
    type: { type: "Genro", relation: "Sogro(a)", key: 14 },
  },
  {
    key: 15,
    value: 15,
    text: "Nora",
    type: { type: "Nora", relation: "Sogro(a)", key: 15 },
  },
  {
    key: 16,
    value: 16,
    text: "Sogro(a)",
    type: { type: "Sogro(a)", relation: "Genro/Nora", key: 14 },
  },
];
