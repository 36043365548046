
import axiosDefault from "../../api";

export const deleteHistoricFile = async (params) => {
  const data = await axiosDefault.put(`/delete-historic-file`, params);
  return data;
}


export const listHistoricFiles = async (legislativeId) => {
  const data = await axiosDefault.get(`/list-legislative-historic-files/${legislativeId}`);
  return data;
}

export const listHistoricInsideProject = async (legislativeId) => {
  const data = await axiosDefault.get(`/list-legislative-historic/${legislativeId}`);
  return data;
}

export const updateHistoric = async (payload, historicId) => {
  const data = await axiosDefault.put(`update-historic/${historicId}`, payload);
  return data;
}

export const createHistoric = async (payload) => {
  return await axiosDefault.post('/create-historic', payload);
}