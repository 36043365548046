import { Box, Flex, Skeleton, Stack, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import SideBar from 'components/@sidebar';
import Card from 'components/card/Card';
import React, { useCallback, useEffect, useState } from 'react'
import { getNeighborhoodByRegisteredQuantity } from 'services/urls/address';
import { FaRegUser } from "react-icons/fa";

const NeighborhoodsPage = () => {
  const toast = useToast();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [neighborhoods, setNeighborhoods] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const loadNeighborhoodsByRegisteredPersons = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await getNeighborhoodByRegisteredQuantity();
      setNeighborhoods(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast({
        title: '',
        description: "Erro ao listar bairros",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  }, [toast])


  useEffect(() => {
    loadNeighborhoodsByRegisteredPersons()
  }, [])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Flex
        marginTop={18}
        direction="column"
        marginX={4}
      >
        {isLoading ? (
          <Stack>
          <Skeleton marginTop="2" borderRadius={15} height='75px' />
          <Skeleton marginTop="1" borderRadius={15} height='75px' />
          <Skeleton marginTop="1" borderRadius={15} height='75px' />
          <Skeleton marginTop="1" borderRadius={15} height='75px' />
          <Skeleton marginTop="1" borderRadius={15} height='75px' />
          <Skeleton marginTop="1" borderRadius={15} height='75px' />
          <Skeleton marginTop="1" borderRadius={15} height='75px' />
        </Stack>
        ) : (
          <>
            {neighborhoods.map((neighborhood) => (
              <Card
                key={neighborhood._id}
                marginBottom={4}
                boxShadow="lg"
                children={
                  <Flex justifyContent="space-between" direction="row">
                    <Text
                      color={textColor}
                      fontSize='lg'
                      textTransform="capitalize"
                      fontWeight="500">
                      {neighborhood.name}
                    </Text>
                    <Flex alignItems="center">
                      <Text
                        color={textColor}
                        fontSize='lg'
                        textTransform="capitalize"
                        marginRight={4}
                        fontWeight="500">
                        {neighborhood.userCount}
                      </Text>
                      <FaRegUser color='#ccc' />
                    </Flex>
                  </Flex>} />
            ))}
          </>
        )}

      </Flex>
    </Box>
  )
}

export default NeighborhoodsPage
