import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Image, Skeleton, Stack, Tag, Text, useColorModeValue } from "@chakra-ui/react";
import TextExpanded from "components/@textExpanded";
import Card from "components/card/Card";
import moment from "moment";
import React, { memo, useCallback } from "react";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const ListOfPeople = ({ loading, data, setUserName, setIdDelete, onOpenDel }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const history = useNavigate();


  const renderAge = useCallback((date) => {
    if (!date) return "Não informado.";
    const age = moment().diff(date, "years");
    return `${age.toString()}`;
  }, [])

  const navigateRegisterdPerson = useCallback((registeredPerson) => {
    history(
      "/visualizar-cadastrado",
      {
        state: {
          userId: registeredPerson._id,
        }
      },
    );
  }, [history])

  const handleRightClick = useCallback((e) => {
    if (e.button === 1) {
      e.preventDefault();
      window.open(e.target.href, '_blank');
    }
  }, [])

  const handleClick = useCallback((e) => {
    e.preventDefault();
  }, [])

  const navigateToEditRegisterdPerson = useCallback((registeredPerson) => {
    history(
      "/editar-dados-do-cadastro",
      {
        state: {
          id: registeredPerson._id,
        }
      },
    );
  }, [history])

  return (
    <>
      {loading ? (
        <Stack>
          <Skeleton marginTop="2" borderRadius={15} height='80px' />
          <Skeleton marginTop="2" borderRadius={15} height='80px' />
          <Skeleton marginTop="2" borderRadius={15} height='80px' />
          <Skeleton marginTop="2" borderRadius={15} height='80px' />
        </Stack>
      ) : (
        data?.map((item) => (
          <a href={`visualizar-cadastrado/${item._id}`} onClick={handleClick} onContextMenu={handleRightClick}>
            <Card
              marginBottom={4}
              boxShadow="lg"
              children={
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <Flex direction={{ base: 'column', md: 'row' }}>
                    <Box>
                      <Flex minW="60">
                        <Image
                          onClick={() => navigateRegisterdPerson(item)}
                          boxSize={{ base: '100%', md: '220px' }}
                          borderTopLeftRadius={6}
                          borderTopRightRadius={6}
                          marginRight={4}
                          objectFit="cover"
                          borderRadius={6}
                          src={
                            item.photo ||
                            "https://react.semantic-ui.com/images/wireframe/image.png"
                          } />
                      </Flex>
                    </Box>
                    <Flex
                      direction="column"
                      marginTop={{ base: 4, md: 0 }}
                    >
                      <Text
                        color={textColor}
                        fontSize='lg'
                        fontWeight="500">
                        {item.name}
                        <strong>{item.isDeath ? " (Falecido)" : ""}</strong>
                        {item.isDeath ? `, ${renderAge(item?.dateOfBirth)}` : `, ${renderAge(item?.dateOfBirth)}`} anos.
                      </Text>
                      <Text
                        color={textColor}
                        fontSize='lg'
                        fontWeight="500">
                        {item?.demandCount} {item.demandCount > 1 ? "demandas" : "demanda"}
                      </Text>
                      <Flex flexWrap="wrap" style={{ marginBottom: 8 }}>
                        {item?.category?.length > 0 &&
                          item?.category?.map((it) => (
                            <Tag
                              padding={2}
                              marginTop={1}
                              size='md'
                              marginRight={1}
                              colorScheme='orange'
                              borderRadius='md'>
                              {it.name}
                            </Tag>
                          ))}
                      </Flex>
                      <TextExpanded
                        marginTop="6"
                        key={item._id}
                        textSize={100}
                        text={item?.description || ""}
                      />
                      <Flex>
                        <div>
                          {item?.neighborhood && (
                            <Text
                              color={textColor}
                              fontSize='md'
                            >{item?.neighborhood}</Text>
                          )}
                        </div>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    flex={1}
                    alignItems="flex-end"
                    marginTop={{ base: 6, md: 0 }}
                    justifyContent={{ base: "center", md: "flex-end" }}>
                    <>
                      <IconButton
                        icon={<EditIcon />}
                        colorScheme="orange"
                        marginRight={2}
                        onClick={() => navigateToEditRegisterdPerson(item)}
                      />
                      <IconButton
                        icon={<AiFillDelete />}
                        colorScheme="red"
                        marginRight={2}
                        onClick={() => {
                          setUserName(item.name);
                          setIdDelete(item._id);
                          onOpenDel();
                        }}
                      />

                      <IconButton
                        icon={<ViewIcon />}
                        colorScheme="messenger"
                        onClick={() => navigateRegisterdPerson(item)}
                      />
                    </>
                  </Flex>
                </Flex>
              }
            />
          </a>
        ))
      )}
    </>
  )
}
export default memo(ListOfPeople);