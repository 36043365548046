import { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext({});


function AuthProvider({ children }) {
  useEffect(() => {
    if (localStorage.getItem("label") && localStorage.getItem("href")) {
      setPath({
        label: localStorage.getItem("label"),
        href: localStorage.getItem("href"),
        menu: localStorage.getItem("menu")
      });
    }
  }, []);

  const [menuOpen, setMenuOpen] = useState(true);
  const [isAuth, setISAuth] = useState(localStorage.getItem("token") ? true : false);
  const [path, setPath] = useState({
    label: '',
    href: '',
    menu: ''
  });

  return (
    <AuthContext.Provider value={{ isAuth, setISAuth, setPath, path, menuOpen, setMenuOpen }}>
      {children}
    </AuthContext.Provider>
  );
}
export default AuthProvider;
