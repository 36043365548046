

import { Flex, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Popup } from "semantic-ui-react";

const PopupComponent = ({ 
  onClick, 
  quantity, 
  isLoading, 
  situationsType, 
  statusName,
  bgColor,
  ...props
}) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <>
      <Popup
        {...props}
        style={{ zIndex: 1, position: "relative" }}
        onOpen={onClick}
        position="bottom center"
        trigger={
          <Flex
            flexGrow={1}
            marginBottom="2"
            cursor="pointer"
            padding={3}
            borderRadius={18}
            bg={bgColor}
            alignItems="center"
            justifyContent="center"
            marginLeft={2}
          >
            <Text
              color={textColor}
              fontSize="md"
              fontWeight='700'
              lineHeight='100%'
              marginRight={2}>
              {statusName}
            </Text>
            <Text
              fontSize="md"
              fontWeight='700'
              lineHeight='100%'
            >
              {quantity}
            </Text>
          </Flex>
        }
        on="click"
      >
        <Flex>
          {isLoading ? (
            <Flex
              direction="column"
              boxShadow="2xl"
              zIndex={1}
              bgColor="white"
              borderRadius={10}
              padding={4}
            >
              <Spinner />
            </Flex>
          ) : (
            <Flex
              direction="column"
              boxShadow="2xl"
              zIndex={1}
              borderRadius={10}
              bgColor={situationsType.length ? "white" : "transparent"}
              padding={4}
              marginTop="2"
            >
              {situationsType.map((item) => (
                <Text
                  color={textColor}
                  fontSize="md"
                  fontWeight='500'
                >
                  {`${item.name}: ${item.count}`}
                </Text>
              ))}
            </Flex>
          )}
        </Flex>
      </Popup>
    </>
  )
}
export default PopupComponent;