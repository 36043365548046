import {
  Box,
  Card,
  CardHeader,
  Flex,
  Text,
  IconButton,
  CardBody,
  Image,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { AiFillDelete } from "react-icons/ai";
import { FaUserCircle } from 'react-icons/fa'

import { deleteHistoricFile } from "services/urls/legislative_historic";

const CardList = ({
  projectName,
  accountName,
  date,
  path,
  id,
  onRefresh,
  data,
}) => {
  const textColor = useColorModeValue("navy.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [docId, setDocId] = useState("");

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  const deletFile = async () => {
    await deleteHistoricFile({ 
      historicId: id,
      fileId: docId,
      accountId: data.accountId,
      accountName: data.accountName,
      day: moment().format("DD/MM/YYYY"),
      hour: moment().format("HH:mm:ss"),
      userAction: "Editou",
      userId: undefined,
      userName: undefined,
      dateTime: moment().format(),
      descriptionUserAction: `${data.accountName} excluiu um arquivo do projeto de lei "${projectName}"`
    });

    onRefresh();
    setDocId("");
    onClose();
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar arquivo
            </AlertDialogHeader>
            <AlertDialogBody>
              Tem certeza que deseja deletar o arquivo?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose();
                  setDocId("");
                }}
              >
                Cancelar
              </Button>
              <Button colorScheme="green" onClick={() => deletFile()} ml={3}>
                Deletar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Box
        maxW="container.xl"
        alignItems="center"
        display="flex"
        justifyContent="center"
      >
        <Card
          flex={1}
          maxW="container.xl"
          marginRight={10}
          marginLeft={10}
          marginTop={10}
        >
          <CardHeader>
            <Flex direction="column">
              <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                <Box marginLeft={4}>
                <Flex alignItems="center">
                    <FaUserCircle size={40} />
                    <Flex
                      marginLeft={2}
                      direction="column">
                      <Text>
                        Criado por{` ${accountName}`}
                      </Text>
                    </Flex>
                  </Flex>
                  
                </Box>
              </Flex>
              <Divider marginTop={3} />
            </Flex>
            {data.description && (
              <Flex
                marginTop={4}
                marginLeft={4}
              >
                <Text
                  fontSize='md'
                  fontWeight='400'
                  color={textColor}
                >
                  <Text
                    as="strong"
                    fontWeight='600'
                    fontSize='15'
                  >
                    {moment(date).format("DD/MM/YYYY [às] HH:mm[h]")}:{' '}
                  </Text>
                  {data.description}
                </Text>
              </Flex>
            )}
          </CardHeader>
          <CardBody>
            <Flex flex="1" flexDirection="column">
              {path.map((it) => {
                if (it.fileType.includes("image")) {
                  return (
                    <Box
                      marginLeft={4}
                      marginRight={4}
                      marginBottom={4}
                      display="flex"
                      justifyContent="center"
                    >
                      <Box position="absolute" right={9}>
                        <IconButton
                          variant="solid"
                          color="red.400"
                          borderRadius={6}
                          marginRight={2}
                          marginTop={2}
                          aria-label="See menu"
                          onClick={() => {
                            setDocId(it.url_id);
                            onOpen();
                          }}
                          icon={<AiFillDelete />}
                        />
                      </Box>
                      <Image
                        borderRadius={4}
                        objectFit="cover"
                        src={it.url}
                        alt="Chakra UI"
                      />
                    </Box>
                  );
                }
                if (it.fileType.includes("pdf")) {
                  return (
                    <Box
                      marginLeft={4}
                      marginRight={4}
                      marginBottom={4}
                      display="flex"
                      justifyContent="center"
                    >
                      <Box position="absolute" right={9}>
                        <IconButton
                          variant="solid"
                          color="red.400"
                          borderRadius={6}
                          marginRight={2}
                          marginTop={2}
                          aria-label="See menu"
                          onClick={() => {
                            setDocId(it.url_id);
                            onOpen();
                          }}
                          icon={<AiFillDelete />}
                        />
                      </Box>
                      <object width="100%" height="700" data={it.url} type="application/pdf">   </object>
                    </Box>
                  )
                }
                if (it.fileType.includes("audio")) {
                  return (
                    <Box
                      marginLeft={4}
                      display="flex"
                      flexDirection="row"
                      marginRight={4}
                      marginBottom={4}
                    >
                      <ReactPlayer
                        url={it.url}
                        onBuffer={() => { }}
                        height="35px"
                        width="100%"
                        playing={false}
                        controls
                      />
                      <IconButton
                        variant="solid"
                        color="red.400"
                        onClick={() => {
                          setDocId(it.url_id);
                          onOpen();
                        }}
                        borderRadius={6}
                        marginLeft={2}
                        aria-label="See menu"
                        icon={<AiFillDelete />}
                      />
                    </Box>
                  );
                }
                if (it.fileType.includes("video")) {
                  return (
                    <Box
                      display="flex"
                      justifyContent="center"
                      marginLeft={4}
                      marginRight={4}
                      marginBottom={4}
                    >
                      <Box>
                        <Box position="absolute" marginTop={2} right={16} zIndex="banner">
                          <IconButton
                            variant="solid"
                            color="red.400"
                            borderRadius={6}
                            marginRight={2}
                            onClick={() => {
                              setDocId(it.url_id);
                              onOpen();
                            }}
                            marginTop={0}
                            aria-label="See menu"
                            icon={<AiFillDelete />}
                          />
                        </Box>
                        <ReactPlayer
                          width={screenSize.width / 2.3}
                          url={it.url}
                          onBuffer={() => { }}
                          playing={false}
                          controls
                        />
                      </Box>
                    </Box>
                  );
                }
              })}
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </>
  );
};
export default CardList;
