import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import moment from "moment";
import { memo } from "react";


const UserDocument = ({ data }) => {
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <Flex
      borderWidth="1px"
      borderRadius="lg"
      w={{ sm: '100%', md: '100%' }}
      height={{ sm: '100%', md: '20rem' }}
      direction={{ base: 'column', md: 'row' }}
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'2xl'}
      padding={4}
    >
      <Box marginRight={4}>
        <Image
          borderTopLeftRadius={6}
          borderTopRightRadius={6}
          flex={0.1}
          boxSize="100%"
          objectFit="contain"
          src={
            data?.photo ||
            "https://react.semantic-ui.com/images/wireframe/image.png"
          }
        />
      </Box>
      <Flex direction="column">
        <Text
          fontSize='lg'
          fontWeight='500'
          marginTop={{ base: 4, md: 0 }}
          color={textColor}>
          {data?.name}
        </Text>
        <Flex marginTop={2}>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Data de nascimento:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.dateOfBirth
              ? moment(data?.dateOfBirth).format("DD/MM/YYYY")
              : "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Rg:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}>
            {data?.rg
              ? data?.rg.replaceAll("_", "").replaceAll("-", "")
              : "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Órgão expedidor:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.rgOrgao ? data?.rgOrgao : "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            CPF:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.cpf ? data?.cpf : "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            CNH:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.cnh ? data?.cnh : "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Número do cartão SUS:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.susNumero
              ? data?.susNumero.replaceAll("_", "")
              : "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Título:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.titulo ? data?.titulo : "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Zona:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.zona ? data?.zona : "Não informado"}
          </Text>
        </Flex>
        <Flex>
          <Text
            fontWeight='500'
            fontSize='md'
            color={textColor}>
            Sessão:
          </Text>
          <Text
            marginLeft={2}
            fontSize='md'
            color={textColor}
          >
            {data?.section ? data?.section : "Não informado"}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default memo(UserDocument);