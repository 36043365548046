export const situationColors = (situation) => {
  if (situation === "Recebida") {
    return "#FC8180";
  }
  if (situation === "Encerrada" || situation === "Resolvida") {
    return "#9AE6B4";
  }
  if (situation === "Em andamento") {
    return "#F5E05E";
  }
  if (situation === "Em aprovação") {
    return "#9BE1FF";
  }
  if (situation === "Pausada") {
    return "#B693F4";
  }
  if (situation === "Cancelada") {
    return "#C9D3E0";
  }
  return "none";
};

export const StatusColors = (status) => {
  if (status === "Recebida") {
    return "#FC8180";
  }
  if (status === "Em trâmite para sanção") {
    return "#F6AD54";
  }
  if (status === "Em produção") {
    return "#F5E05E";
  }
  if (status === "Protocolado") {
    return "#9BE1FF";
  }
  if (status === "Em comissões") {
    return "#B693F4";
  }
  if (status === "Arquivada") {
    return "#C9D3E0";
  }
  if (status === "Lei aprovada") {
    return "#9AE6B4";
  }
  return "none";
};