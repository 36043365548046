import { call } from "redux-saga/effects";

import store from "../..";
import axiosServices from "services/api";
import { setLoadingLogin, setLoginIsErrorMessage, setUserData } from "./actions";
import { createStandaloneToast } from "@chakra-ui/react";
const { toast } = createStandaloneToast();

const instance = axiosServices;

export function* signIn({
  payload,
}) {
  try {
    const { email, password } = payload;
    store.dispatch(setLoadingLogin(true));

    const response = yield call(instance.post, "auth", {
      email,
      password,
    });

    const data = {
      proprietaryid: response.data.account.proprietary,
      _id: response?.data?.account?._id,
      name: response?.data?.account?.name,
      email: response?.data?.account?.email,
      signed: true,
      isMaster: response?.data?.account?.isMaster || false,
      loading: true,
      isError: false,
    };

    const saveToken = {
      token: response?.data?.token,
    };

    localStorage.setItem("@token", JSON.stringify(saveToken));
    store.dispatch(setUserData(data));
  } catch (err) {
    if (err?.status === 500) {
      store.dispatch(setLoginIsErrorMessage(true));
      store.dispatch(setLoadingLogin(false));
    } else {
      toast({
        description: err.response.data.error,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
      store.dispatch(setLoginIsErrorMessage(true));
      store.dispatch(setLoadingLogin(false));
    }
  }
}
