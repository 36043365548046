import moment from "moment";
import CardContainer from "components/card/Card";
import React from "react";
import { Box, Flex, IconButton, Text, useColorModeValue } from "@chakra-ui/react";
import TextExpanded from "components/@textExpanded";
import { TbFiles } from 'react-icons/tb'
import { EditIcon } from "@chakra-ui/icons";

const HistoriListView = ({ historicDemands, onOpenModalToEdit }) => {
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <>
      {historicDemands.map((item) => (
        <Box marginTop={4}>
          <CardContainer
            boxShadow="md"
            children={
              <Flex direction={{ base: 'column', md: 'row' }}>
                <Flex flex={1} direction="column">
                  <Flex whiteSpace="pre-line">
                    <span
                      style={{
                        color: textColor,
                        fontSize: 16,
                        marginRight: 4,
                      }}>
                      {moment(item?.createdByUserAt).format("DD/MM/YYYY")}
                    </span>
                    {item?.attachment?.length > 0 && (
                      <TbFiles size="18" color="blue" />
                    )}
                  </Flex>
                  <Flex>
                    <Text
                      fontSize='md'
                      fontWeight='600'
                      color={textColor}>
                      Status:
                    </Text>
                    <Text marginLeft={2}>{item?.demandStatus?.name || '--'}</Text>
                  </Flex>
                  <Flex marginBottom="2.5">
                    <Text
                      fontSize='md'
                      fontWeight='600'
                      color={textColor}
                    > Situação:</Text>
                    <Text
                      color={textColor}
                      marginLeft={2}>
                      {item?.demandStatusSituation?.name || '--'}
                    </Text>
                  </Flex>
                  <TextExpanded
                    textLabel="Último histórico:"
                    text={item?.demandHistoric || "Não há histórico"}
                    textSize={400}
                    key={item._id}
                    marginBottom="2"
                  />
                  {item?.demandStatus?.name === "Resolvida" || item?.demandStatus?.name === "Cancelada" ? null : (
                    <Flex marginBottom="2.5">
                    <Flex>
                      <Text
                        fontSize='md'
                        fontWeight='600'
                        color={textColor}
                      >Responsável:</Text>
                      <Text
                        marginLeft={2}
                      >
                        {item?.responsible?.name || "Sem responsável"}
                      </Text>
                    </Flex>
                  </Flex>
                  )}
                  
                  {(item?.sendDoc || item?.sendMidia || item?.hasReturn) && (
                    <>
                      <Flex>
                        <Text
                          fontSize='md'
                          fontWeight='600'
                          color={textColor}
                        >Enviou algum documento ao solicitante:</Text>
                        <Text marginLeft={2}>{item?.sendDoc}</Text>
                      </Flex>
                      <Flex>
                        <Text
                          fontSize='md'
                          fontWeight='600'
                          color={textColor}
                        >Enviou alguma mídia/arte ao solicitante:</Text>
                        <Text marginLeft={2}>{item?.sendMidia}</Text>
                      </Flex>
                      <Flex marginBottom="4">
                        <Text
                          fontSize='md'
                          fontWeight='600'
                          color={textColor}
                        >Deu retorno ao solicitante:</Text>
                        <Text marginLeft={2}>{item?.hasReturn}</Text>
                      </Flex>
                    </>
                  )}
                </Flex>
                <Flex direction={{ base: 'row-reverse', md: 'column' }} justifyContent="space-between">
                  <Flex justifyContent="flex-end">
                    <IconButton
                      aria-label="Editar"
                      icon={<EditIcon color="orange.500" />}
                      onClick={() => onOpenModalToEdit && onOpenModalToEdit(item)}
                    />
                  </Flex>
                  <Flex
                    direction={{ base: 'row-reverse', md: 'column' }}
                    justifyContent={{ base: "flex-end", md: "flex-end" }}
                  >
                    <Text
                      fontSize='md'
                      marginBottom="2"
                      color={textColor}
                    >
                      Criado por {item?.createdByUser?.name}{" "}
                      {moment(item?.createdByUserAt).format(
                        "DD/MM/YYYY [às] HH:mm:ss"
                      )}
                    </Text>
                    {item?.editedByUser && (
                      <Text
                        fontSize='md'
                        marginBottom="2"
                        color={textColor}
                      >
                        Editado por {item?.editedByUser?.name}{" "}
                        {moment(item?.editedByUserAt).format(
                          "DD/MM/YYYY [às] HH:mm:ss"
                        )}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            }
          />
        </Box>
      ))}
    </>
  )
}
export default HistoriListView;