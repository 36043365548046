

import axiosDefault from "../../api";

export const updateProject = async (payload, projectId) => {
  return await axiosDefault.put(`/update-legislative/${projectId}`, payload);
}

export const getLegislativeDetail = async (id) => {
  const data = await axiosDefault.get(`/show-legislative/${id}`);
  return data;
}

export const getLegislative = async (parans) => {
  const data = await axiosDefault.get("/list-legislatives", {
    params: parans
  } );
  return data;
}

export const getLegislativeAmout = async (params) => {
  const data = await axiosDefault.get("/legislative-amout", {
    params: params
  });
  return data;
}

export const getLegislativeAmoutStatus = async (params) => {
  const data = await axiosDefault.get("/legislative-status-amout", {
    params: params
  } );
  return data;
}

export const getLegislativeAmoutStatusDetail = async (id, params) => {
  const data = await axiosDefault.get(`/legislative-status-amout-detail/${id}`, {
    params: params
  } );
  return data;
}

export const updateLegislativeProject = async (payload, legislativeId) => {
  const data = await axiosDefault.put(`/update-legislative/${legislativeId}`, payload);
  return data;
}

export const createLegislativeProject = async (payload) => {
  const data = await axiosDefault.post('/create-legislative', payload);
  return data;
}

export const deleteLegislativeProject = async (legislativeId, payload) => {
  return await axiosDefault.post(`/delete-legislative/${legislativeId}`, payload);
}