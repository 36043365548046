import { combineReducers } from "redux";

import userReducer from "./auth";
import demand from "./demands";
import usersMapReducer from "./map";
import usersReducer from "./users";
import legislationReducer from "./legislations";

export default combineReducers({
  user: userReducer,
  usersReducer,
  usersMapReducer,
  demand,
  legislationReducer,
});
