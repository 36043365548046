import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useRef, useState } from "react";
import { ChevronDownIcon, ViewIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { AiOutlineUser } from "react-icons/ai";
import Card from "components/card/CardBith";
import IconBox from "components/icons/IconBox";
import SideBar from 'components/@sidebar'

import {
  Icon,
} from "semantic-ui-react";

import axiosBirth from "../../services/urls/user";
import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  Skeleton,
  Stack,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Textarea,
  Avatar,
  useToast,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axiosDefault from "../../services/urls/user";

const BirthPage = () => {
  const history = useNavigate();
  const toast = useToast();
  let shoulRequest = useRef(true);
  const { _id: accountId, name: accountName } = useSelector(
    (state) => state.user
  );
  const [birthId, setBirthId] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [empity, setEmpity] = useState(false);
  const [birthdays, setBirthDays] = useState([]);
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const today = new Date();
  today.setHours(0, 0, 0, 0);


  function convertToDate(mmdd, year) {
    const [month, day] = mmdd.split('-').map(Number);
    return new Date(year, month - 1, day);
  }



  const requestBirth = async (showIsLoading) => {
    try {
      if (showIsLoading) {
        setLoading(true);
        setEmpity(false);
      }
      const response = await axiosBirth.GetBirthDay();

      const birthdays = response.data.data.map(item => {
        const birthday = convertToDate(item.date, today.getFullYear());
        // Se o aniversário já passou este ano, considere o próximo ano.
        if (birthday < today) {
          birthday.setFullYear(birthday.getFullYear() + 1);
        }
        return {
          ...item, // Mantém os dados originais.
          sortDate: birthday // Adiciona a data normalizada para ordenação.
        };
      });

      // Ordenar os dados pela data de aniversário.
      birthdays.sort((a, b) => a.sortDate - b.sortDate);

      // Verificar se o primeiro na lista é o aniversariante do dia.
      if (birthdays[0].sortDate.getTime() !== today.getTime()) {
        // Se não for, encontre o aniversariante do dia e mova-o para o topo.
        const todayIndex = birthdays.findIndex(item => item.sortDate.getTime() === today.getTime());
        if (todayIndex > -1) {
          const [todayBirthday] = birthdays.splice(todayIndex, 1);
          birthdays.unshift(todayBirthday);
        }
      }
      setBirthDays(
        birthdays
      );

      if (response.data?.data.length === 0) {
        setEmpity(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const renderAge = (date) => {
    if (!date) return "";
    const age = moment().diff(date, "years");
    return `${age.toString()}`;
  };

  const callRequestBirth = () => requestBirth(false);

  const confirmSendMessage = async (
    userId,
    userName,
    date
  ) => {
    try {
      await axiosBirth.confirmSendMessage(
        userId,
        userName,
        accountId,
        accountName,
        date
      );
      toast({
        title: '',
        description: "Confirmação de mensagem realizada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      callRequestBirth();
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao realizar confirmação!",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const removeSendMessage = async (
    userId,
    birthId,
    userName,
  ) => {
    try {
      await axiosBirth.removeSendMessage(
        userId,
        accountId,
        birthId,
        accountName,
        userName,
        "Enviado mensagem de parabéns",
      );
      callRequestBirth();
      toast({
        title: '',
        description: "Situação de mensagem atualizada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: '',
        description: "Erro ao atualizar!",
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const insertHistoric = async () => {
    try {
      if (!description) {
        toast({
          title: '',
          description: "Descreva algo.",
          status: 'warning',
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }

      await axiosDefault.insertHistoricBirthNotSend(
        accountId,
        accountName,
        moment().format(),
        `A mensagem de parabéns não foi possível ser realizada.\nMotivo: ${description}`,
        userId,
        userName,
        birthId,
        "Parabéns",
      );

      toast({
        title: '',
        description: "Ação realizada com sucesso.",
        status: 'success',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
      onClose();
      setDescription("");
      setUserName("");
      setUserId("");
      callRequestBirth();
    } catch (error) {

      toast({
        title: '',
        description: error?.response?.data?.error,
        status: 'error',
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (shoulRequest.current) {
      shoulRequest.current = false;
      requestBirth(true);
    }
  }, []);

  function formatarData(dataString) {
    // Divide a string de data para obter mês e dia diretamente
    const partes = dataString.split('-');
    const dia = parseInt(partes[1], 10);
    const mesIndex = parseInt(partes[0], 10) - 1; // Ajusta porque os meses começam do índice 0
  
    // Array com os nomes dos meses em português
    const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];
  
    // Obtém o nome do mês usando o índice
    const mes = meses[mesIndex];
  
    // Retorna a data formatada
    return `${dia} de ${mes}`;
  }

  function obterDiaDaSemana(dataString) {
    // Adiciona um ano arbitrário para evitar problemas no iOS
    const anoAtual = new Date().getFullYear();
    const dataFormatada = `${anoAtual}-${dataString}`;

    // Configura o locale para português e obtém o dia da semana
    const diaDaSemana = moment(dataFormatada, "YYYY-MM-DD").locale('pt-br').format('dddd');

    // Retorna o dia da semana
    return diaDaSemana;
  }
  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SideBar />
      <Box padding={4}>
        {loading && (
          <Stack marginTop={14}>
            <Skeleton marginBottom={6} borderRadius={15} height='30px' width="40%" />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
            <Skeleton marginTop="2" borderRadius={15} height='80px' />
          </Stack>
        )}
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {empity && <div>Sem resultados.</div>}
        </div>
        {birthdays?.map((it, index) => (
          <>
            <Flex justifyContent="center">
              <Text
                bg="gray.200"
                paddingLeft="8"
                paddingRight="8"
                paddingTop="4"
                borderRadius="40"
                paddingBottom="4"
                color={textColor}
                fontSize='md'
                fontWeight='700'
                marginBottom="5"
                marginTop="8"
                marginLeft={2}
                lineHeight='100%'>
                  {it.activities.length} Aniversariantes | {formatarData(it.date)}{" "}
                  (
                    {obterDiaDaSemana(it.date)}
                  )
              </Text>
            </Flex>

            {it.activities.map((item) => (
              <Box marginTop={4}>
                <Card
                  bgColor={moment().format("MM-DD") === item.birth
                    ? item.birth_send_message
                      ? "green.200"
                      : "red.200"
                    : "gray.200"}
                  startContent={
                    item.photo ? <Avatar
                      size='lg'
                      name={item.name}
                      src={item.photo}
                    /> :
                      <IconBox
                        w='56px'
                        h='56px'
                        borderWidth={1}
                        borderColor="black"
                        icon={
                          <Icon
                            w='32px'
                            h='32px'
                            as={AiOutlineUser}
                            color={brandColor} />
                        }
                      />
                  }
                  endContent={
                    <Flex>
                      <IconButton
                        marginRight={1}
                        onClick={() =>
                          history(
                            "/visualizar-cadastrado",
                            {
                              state: {
                                userId: item._id,
                              }
                            },
                          )
                        }
                        icon={<ViewIcon />}
                      />
                      <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                          Ações
                        </MenuButton>
                        <MenuList>
                          {item.birth_send_message?._id && (
                            <>
                              <MenuItem
                                onClick={() => {
                                  removeSendMessage(
                                    item._id,
                                    item.birth_send_message._id,
                                    item.name
                                  );
                                }}
                              >
                                Desfazer envio de mensagem
                              </MenuItem>
                            </>
                          )}
                          {!item.birth_send_message?._id && (
                            <>
                              <MenuItem
                                onClick={() => {
                                  confirmSendMessage(
                                    item._id,
                                    `${item.name}`,
                                    item.dateOfBirth
                                  );
                                }}
                              >
                                Mensagem enviada
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setUserName(item.name);
                                  setUserId(item._id);
                                  setBirthId(item.birth_send_message?._id || "");
                                  onOpen();
                                }}
                              >
                                Não foi possível ser realizado
                              </MenuItem>
                            </>
                          )}
                        </MenuList>
                      </Menu>
                    </Flex>
                  }
                  name={`${item.name} 
                  ${item.apelido ? `(${item.apelido})` : ''} - 
                  ${renderAge(item.dateOfBirth)} anos.`}
                  value='$1,000'
                  whatsApp={item.whatsApp}
                  whatsAppContact={item.whatsAppContact}
                  whatsAppContactName={item.whatsAppContactName}
                  phone={item.phone}
                  phoneContact={item.phoneContact}
                  phoneContactName={item.phoneContactName}
                  category={item.category}
                  description={item.description}
                />
              </Box>
            ))}
          </>
        ))}
        <AlertDialog
          motionPreset='slideInBottom'
          leastDestructiveRef={cancelRef}
          onClose={() => {
            setUserName("");
            setUserId("");
            setBirthId("");
            setDescription("");
            onClose();
          }}
          size="3xl"
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader>Não foi possível ser realizado</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <Textarea
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                placeholder='Motivo...'
                size='md'
                height="150px"
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                colorScheme="red"
                onClick={() => {
                  setUserName("");
                  setUserId("");
                  setBirthId("");
                  setDescription("");
                  onClose()
                }}>
                Fechar
              </Button>
              <Button colorScheme='green' ml={3} onClick={() => insertHistoric()}>
                Salvar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </Box>
    </Box>
  );
};
export default BirthPage;