import axiosDefault from "../../api";

const GetAccountsPaginate = async (
  page,
  perPage
) => {
  const data = axiosDefault.get(`accounts-paginate`, {
    params: {
      perPage,
      page,
    },
  });
  return data;
};
const GetAllAccountsActive = async () => {
  const data = axiosDefault.get(`all-account`);
  return data;
};
const GetAllAccounts = async () => {
  const data = axiosDefault.get(`accounts`);
  return data;
};
const saveNewUser = async (payload) => {
  const data = axiosDefault.post(`new-account-basic`, payload);
  return data;
};
const updateAccess = async (
  payload,
  userId,
) => {
  const data = axiosDefault.put(`account-basic-access/${userId}`, payload);
  return data;
};

const updatePassword = async (
  userId,
  newPassword,
  accountId,
  accountName,
  day,
  hour,
  userAction,
  userName,
  description,
  dateTime
) => {
  const data = axiosDefault.put(`update-password/${userId}`, {
    newPassword,
    accountId,
    accountName,
    day,
    hour,
    userAction,
    userName,
    description,
    dateTime,
  });
  return data;
};
export default {
  GetAccounts: GetAccountsPaginate,
  saveNewUser,
  updateAccess,
  updatePassword,
  GetAllAccountsActive,
  GetAllAccounts,
};
