
export function signInRequest(email, password) {
  return {
    type: "@LOGIN/SIGN_IN_REQUEST",
    payload: { email, password },
  };
}

export function setUserData(payload) {
  return {
    payload,
    type: "SET_USER",
  };
}
export function setLoginErrorMessage(payload) {
  return {
    payload,
    type: "SET_ERROR_MESSAGE",
  };
}

export function setLoadingLogin(payload) {
  return {
    payload,
    type: "SET_LOADING_LOGIN",
  };
}

export function setLoginIsErrorMessage(payload) {
  return {
    payload,
    type: "SET_IS_ERROR",
  };
}
export function setCleanUser() {
  return {
    type: "SET_CLEAN_USER",
  };
}
